import React, {PropsWithChildren, useEffect, useState} from 'react'
import {Button, Col, Descriptions, Modal, Row, Space, Table, Tabs, Card} from 'antd'
import {managementData} from '../../../mock/warehouseManagementMock'
import {
  getAllProductRequest,
  getAllRawMaterialRequest,
  // getAllStackingAreaInManagementRequest,
  // getAllTemporaryZoneInManagementRequest,
  getZone,
  getPackageZone,
  getPickingZone,
  getSticker,
  getDispatchingZone,
  getZoneType
} from '../../../services/others/warehouse-management-services'
import Iselect from '../../../components/common/i-select'
import {getAllZoneRequest, getAllZoneRequestForRefresh} from '../../../services/others/relocate-boxex-services'
import ProductTable from './productTable'
import MaterialTableOfShelf from './materialTableOfShelf'
// import ShelfSelector from '../../../components/common/shelf-selector'
import ShelfSelectorEdit from '../../../components/common/shelf-selector-edit'
import CommonMachineCard from '../../../components/common/others/common-machine-card'
import { AddToStockPrintQrModal } from '../temporary-Zone/add-to-stock-dialog/add-to-stock-print-qr-modal'
import { ApiRequest } from '../../../services/api/api'
import { EditOutlined, StopOutlined } from '@ant-design/icons'
import Swal from 'sweetalert2'
import { PrintOutlined } from '@material-ui/icons'
import palletSticker from '../../static/pdf/pallet-stickers/pallet-sticker'
import { PackagingPlateManagementModal } from '../../order/work-order/operator/packaging-list/packaging-modal/packaging-plate-management-modal'


interface select {
  value: string,
  label: string,
  name: string
}

interface Plate {
  position: string; 
  plateCode: string; 
  box: [];
  rawMaterialBox: []
  locationName:string;
  active:number;
}

const TemporaryZoneAndStackingAreaInitState = {
  temporary: [],
  stacking: []
}

const WarehouseManagement:React.FC = () => {
  
  const [isAddToStockModalVisible, setIsAddToStockModalVisible] = useState<boolean>(false)
  const [productValue, setProductValue] = useState<any>()
  const [materialValue, setMaterialValue] = useState<any>()
  const [materialSelect, setMaterialSelect] = useState<select[]>([])
  const [productSelect, setProductSelect] = useState<select[]>([])
  const [zoneData, setZoneData] = useState<any>([])
  const [selectProduct, setSelectProduct] = useState<any>([])
  const [selectMaterial, setSelectMaterial] = useState<any>([])
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [showShelfDetails, setShowShelfDetails] = useState<boolean>(false)
  const [shelfDetailsData, setShelfDetailsData] = useState<any>([])
  const [shelfCode, setShelfCode] = useState<any>([])

  const [selectTemporaryZoneProductValue, setSelectTemporaryZoneProductValue] = useState<any>([])
  const [selectStackingAreaProductValue, setSelectStackingAreaProductValue] = useState<any>([])

  const [positionNotification, setPositionNotification] = useState<any>([])
  const [totalStock, setTotalStock] = useState<number>(0)
  // const [stackingStock, setStackingStock] = useState<number>(0)
  // const [tempStock, setTempStock] = useState<number>(0)
  const [areaStock, setAreaStock] = useState<number>(0)
  const [isPlateManagementModalVisible, setIsPlateManagementModalVisible] = useState(false)


  // const [selectTemporaryZoneMaterialValue, setSelectTemporaryZoneMaterialValue] = useState<any>([])
  // const [selectStackingAreaMaterialValue, setSelectStackingAreaMaterialValue] = useState<any>([])

  const [TemporaryZoneAndStackingArea, setTemporaryZoneAndStackingArea] = useState<any>(TemporaryZoneAndStackingAreaInitState)
  const TemporaryZoneAndStackingAreaRef: any = React.useRef()
  const [zoneType, setZoneType] = useState<any>()
  const [zoneAreas, setZoneAreas] = useState<any>([])
  const [packagingArea, setPackagingArea] = useState<any>();
  const [dispatchingArea, setDispatchingArea] = useState<any>();
  const [pickingArea, setPickingArea] = useState<any>();
  const [sticker, setSticker] = useState<any>();

  useEffect(() => {
    TemporaryZoneAndStackingAreaRef.current = TemporaryZoneAndStackingArea
  }, [TemporaryZoneAndStackingArea])

  useEffect(() => {
    document.title = "Warehouse Management";
   }, [])

  useEffect(() => {

    //供material selector 的 options显示
    getAllRawMaterialRequest()
      .then(res => {
        const selectArray:select[] = []
        // console.log(res.data.data, 'raw')
        res.data.data.forEach((material:any) => selectArray.push(
          {
            value: material.rawMaterialId,
            label: `${material.rawMaterialCode}-${material.rawMaterialName} `,
            name:material.rawMaterialName
          }
        ))
        setMaterialSelect(selectArray)
      })

    getAllProductRequest()
      .then(res => {
        const selectArray:select[] = []
        // console.log(res.data.data, 'product')
        res.data.data.forEach((product:any) => selectArray.push(
          {
            value: product.productId,
            label: product.productCode+'###'+product.productName,
            name: product.productName
          }
        ))
        setProductSelect(selectArray)
      })

    getAllZoneRequest()
      .then(res => {
        setZoneData(res.data.data)
      })

    getZoneType()
    .then(res => {
      setZoneType(res.data.data)
    })
    // getAllTemporaryZoneInManagementRequest()
    //   .then((res) => {
    //     console.log(res.data.data, 'tempo')
    //     setTemporaryZoneAndStackingArea({...TemporaryZoneAndStackingAreaRef.current, temporary: res.data.data })
    //   })

    // getAllStackingAreaInManagementRequest()
    //   .then((res) => {
    //     console.log(res.data.data, 'stacking')
    //     setTemporaryZoneAndStackingArea({...TemporaryZoneAndStackingAreaRef.current, stacking: res.data.data })
    //   })

      getZone()
      .then((res) => {
        console.log(res.data.data, 'getZone')
        setZoneAreas(res.data.data)
      })
      getPackageZone()
      .then((res) => {
        console.log(res.data.data, 'getPackageZone')
        setPackagingArea(res.data.data)
      })
      getPickingZone()
      .then((res) => {
        console.log(res.data.data, 'getPickingZone')
        setPickingArea(res.data.data)
      }) 
      getDispatchingZone()
      .then((res) => {
        console.log(res.data.data, 'getDispatchingZone')
        setDispatchingArea(res.data.data)
      })         
      getSticker()
      .then((res) => {
        console.log(res.data.data, 'getSticker')
        setSticker(res.data.data)
      })                 
  }, [])

  const fetchShelfData = () => {
    getAllZoneRequest()
      .then(res => {
        setZoneData(res.data.data);
      })
      .catch(error => {
        // Handle error if needed
        console.error('Error fetching data:', error);
      });
  };

  const materialSelectOnChangeHandler = (value:number) => {
    // console.log(value,'material')
    setMaterialValue(value)

    const getAllMaterialByValue = getAllMaterial.filter((material:any) => material.rawMaterial.rawMaterialId === value)
    // console.log(getAllMaterialByValue)
    setSelectMaterial(getAllMaterialByValue)

    getTotalStock(getAllMaterialByValue)

    change2array(getAllMaterialByValue)

    setProductValue('')
    setSelectTemporaryZoneProductValue([])
    setSelectStackingAreaProductValue([])

    // console.log(temporaryZone,test.temporary)
    const materialInTempo = TemporaryZoneAndStackingArea.temporary.map((res:any) => {
      return res.rawMaterialBoxes.filter((material:any) => material.rawMaterial.rawMaterialId === value)
    })
    console.log(materialInTempo, 'inMaterial')
    // setSelectTemporaryZoneMaterialValue(materialInTempo)
    // setTempStock(getAreaStock(materialInTempo));

    const materialInStacking = TemporaryZoneAndStackingArea.stacking.map((res:any) => {
      return res.rawMaterialBoxes.filter((material:any) => material.rawMaterial.rawMaterialId === value)
      // return res.boxes.filter((product:any) => product?.rawMaterial?.rawMaterial === value)
    })
    // setSelectStackingAreaMaterialValue(materialInStacking)
    // setTempStock(getAreaStock(materialInStacking));
  }

  const getAreaStock = (paraBoxes:any)=>{
    let retStock=0
    
    const boxes = [...paraBoxes.filter((e:any)=>{return e.length>0})]; 
    boxes.filter(e=>{   
      boxes.push(...e)
      return false;
    });
    console.log("productInTempo",boxes)
    retStock = boxes.reduce((acc,cur)=>cur.quantity?acc+cur.quantity:acc+0,0)
    console.log("retStock",retStock);
    return retStock;
  }
  const productSelectOnChangeHandler = (value:number) => {
    // console.log(value, 'product')
    const productInTempo = TemporaryZoneAndStackingArea.temporary.map((res:any) => {
      return res.boxes.filter((product:any) => product?.product?.productId === value)
    })
    setSelectTemporaryZoneProductValue(productInTempo)
    
    // setTempStock(getAreaStock(productInTempo));

    const productInStacking = TemporaryZoneAndStackingArea.stacking.map((res:any) => {
      return res.boxes.filter((product:any) => product.product.productId === value)
    })

    setSelectStackingAreaProductValue(productInStacking)
    // setStackingStock(getAreaStock(productInStacking));

    setProductValue(value)
    setMaterialValue('')

    const getAllProductByValue = getAllProduct.filter((product:any) => product.product.productId === value)
    // console.log(getAllProductByValue)
    setSelectProduct(getAllProductByValue)

    getTotalStock(getAllProductByValue)
    getAreaBoxQtyByProduct(value)

    change2array(getAllProductByValue)
  }

  const getTotalStock = (stock:any) => {
    let total = 0
    stock.map((res:any) => {
      total += res.quantity
    })
    setTotalStock(total)
  }

  //区别不同地方的位置 用于计算不同位置boxes的数量
  const change2array = (selectedProduct:any) => {
    const arrayTwo = Object.values(selectedProduct.reduce((res:any, item:any) => {
      res[item.cellPosition.id] ? res[item.cellPosition.id].push(item) : res[item.cellPosition.id] = [item]
      return res
    }, {}))
    console.log(arrayTwo)
    setPositionNotification(arrayTwo)
  }

  // console.log(selectTemporaryZoneValue.flat(Infinity)[0]?.product.productName)
  // console.log(zoneData,'ss')

  const getAllProduct:any = []
  const getAllMaterial:any = []
  zoneData.map((zone:any) => {
    // console.log(zone)
    zone.shelf.map((shelf:any) => {
      // console.log(shelf,'shelf')
      const shelfObj = shelf.shelfCode
      shelf.cell.map((cell:any) => {
        // console.log(cell.plate?.box,'box')
        // console.log(cell,'cell')
        const cellObj = {
          id: cell.cellId,
          colNo: cell.colNo,
          rowNo: cell.rowNo
        }
        if (cell.plate !== null) {
          cell.plate.box.map((box:any) => {
            // console.log(box.product,box,zone.zoneCode,'box')
            box.shelfNo = shelfObj
            box.cellPosition = cellObj
            box.palletNo = cell.plate.plateCode
            // box.notes = 
            // console.log(box,'box')
            getAllProduct.push(box)
          })
          cell.plate.rawMaterialBox.map((mBox:any) => {
            mBox.shelfNo = shelfObj
            mBox.cellPosition = cellObj
            mBox.palletNo = cell.plate.plateCode
            getAllMaterial.push(mBox)
          })
        }
      })
    })
  })

  const getProductsDetailByShelf = (code:string) => {
    const selectedProduct = selectProduct.filter((product:any) => product.shelfNo === code)
    setModalTitle('Product')
    setSelectedDataFromShelf(combinePosition(selectedProduct))
    setIsModalVisible(true)
  }

  const [modalTitle, setModalTitle] = useState<string>('')
  const [selectedDataFromShelf, setSelectedDataFromShelf] = useState<any>([])

  const getMaterialsDetailByShelf = (id:string) => {
    const selectedMaterial = selectMaterial.filter((product:any) => product.shelfNo === id)
    setModalTitle('Material')
    setSelectedDataFromShelf(combinePosition(selectedMaterial))
    setIsModalVisible(true)
  }

  const getPlatesDetailByShelf = (code: string) => {
    // debugger
    setShelfCode(code)
    const zoneCode = code.slice(0, 1)
    const array: any = []
    zoneData.filter((zone: any) => zone.zoneCode === zoneCode)[0].shelf
      .filter((shelf: any) => shelf.shelfCode === code)[0].cell
      .forEach((cell: any) => {
        if (cell.plate) {
          const position = `${code}-${cell.rowNo}-${cell.colNo}`
          cell.plate.position = position
          array.push(cell.plate)
        }
      })
    setShelfDetailsData(array)
    setShowShelfDetails(true)
  }

  const combinePosition = (data:any) => {
    data.map((res:any) => {
      res.position = `${res.shelfNo}-${res.cellPosition.rowNo}-${res.cellPosition.colNo}`
    })
    return data
  }

  const productDeleteHandler = (newProduct:any) => {
    // console.log(newProduct,'ssdasdada')
    getAllZoneRequestForRefresh().then(res => {
      // console.log(res.data.data, 'zone')
      setZoneData(res.data.data)
    })
    setProductValue(productValue)
  }
  const getProductLabel = (productId:any) => {
    const product = productSelect.find((item: any) => item.value === productId);
    return product ? product.name : null;
  }  
  const transformAreaData = (area:any,title:string) => {
    return area?.map((e:any) => {
      const newObj = { ...e, ...e.box };
  
      if (title) {
        newObj.position = title;
      }
  
      if (newObj.productId) {
        newObj.product = {
          productId: newObj.productId,
          productName: getProductLabel(newObj.productId),
        };
      }
      return newObj;
    });
  }  
  const handleAreaClick = (area: any, title: string) => {
    console.log(area)
    area = area.map((box: any) => {
      box.position = title;
      return {
        ...box,
        ...box.box,
        palletNo: (box.palletNo !== null && box.palletNo !== undefined) ? box.palletNo : box?.plate?.plateCode
    };
  });
    area = transformAreaData(area, title);
    let filteredBoxes = area;
    if (productValue){
      filteredBoxes = area.filter((box: any) => box.productId === productValue || box.box?.productId === productValue );
    }
    if (materialValue){
      filteredBoxes = area.filter((box: any) => box.materialId === materialValue || box.box?.materialId === materialValue);
    }   

    if (materialValue) {
        setModalTitle('RawMaterial');
    } else {
        setModalTitle('Product');
    }
    setSelectedDataFromShelf(filteredBoxes);
    setIsModalVisible(true);
};
  
  
  const onAreaClick = (zoneType:string, title:any) => {
    let boxesArray = transformZoneData(zoneType, title)  
    handleAreaClick(boxesArray, title)
  }
  const transformZoneData = (zoneType:string, title:any) => {
    let areaData = zoneAreas?.filter((zone:any) => zone.zoneType === zoneType)
    const boxesArray: { boxId: any; barCode: any; quantity: any; productId: any; position: any; palletNo: any; statusName: any }[] = [];

    areaData.map((data: any) => {
      if (data.boxes && Array.isArray(data.boxes)) {
        // Iterate over the 'boxes' array
        data.boxes.forEach((box:any) => {
          // Create a new object for each box
          const newBox = {
            boxId: box.boxId,
            barCode: box.barCode,
            quantity: box.quantity,
            productId: box.productId,
            position: box.position,
            palletNo: data.plate?.plateCode??data.plateCode,
            statusName: box.statusNavigation ? box.statusNavigation.boxStatusName : null,
            createdAt: box.createdAt,
            notes:box.notes
          };
          // Add the new box object to the boxesArray
          boxesArray.push(newBox);
        });
      }
    });   
    return boxesArray; 
  }
  const getAreaBoxQtyByProduct = (productId: any) => {
    let areaData = zoneAreas;
  
    let totalQty = 0;
  
    areaData.forEach((data: any) => {
      if (data.boxes && Array.isArray(data.boxes)) {
        // Iterate over the 'boxes' array
        data.boxes.forEach((box: any) => {
          // Check if the box has the specified productId
          if (box.productId === productId) {
            totalQty += box.quantity;
          }
        });
      }
    });
    setAreaStock(totalQty);
    return totalQty;
  };
  
  //console.log(selectTemporaryZoneProductValue, TemporaryZoneAndStackingArea, TemporaryZoneAndStackingArea.temporary)
 const getAreaText  = (area:any,title:string) => {
  let data = transformAreaData(area, title);
  if (productValue) {
    let filteredData = data.filter((e:any)=>e.productId === productValue)
    if (filteredData?.length > 0) {
      return getProductLabel(productValue)+" X "+filteredData.length
    }
  }
  return null;
 }
 const getZoneText  = (zoneType:any,title:string) => {
  let zoneData = transformZoneData(zoneType, title);
  return getAreaText(zoneData,title)
 } 
  const commonModalProps = (visible: boolean, setIsModalVisible: (value: boolean) => void, name?: string) => {
    return {
      packagingFlag:0,
      visible: visible,
      onOk: () => {
        console.log(name);
        setIsModalVisible(false);
        // Additional logic for showing the next modal if needed
      },
      onCancel: () => setIsModalVisible(false),
    };
  };
  const buttonStyle = {
    backgroundColor: '#007bff',  // Primary background color
    color: 'white',              // Text color
    border: 'none',              // Remove border
    borderRadius: '4px',         // Rounded corners
    // padding: '10px 20px',        // Padding
    cursor: 'pointer',           // Cursor style on hover
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',  // Enhanced box shadow for a more pronounced effect
    fontSize: '16px',            // Font size for better readability
    transition: 'background-color 0.3s ease, transform 0.3s ease', // Smooth transition for hover effects
  };
  
  return (
    <div>
      <div style={{margin: '20px 0px'}}>
        <Space size={10}>
          <div>
                        Product: &nbsp;
            <Iselect onChange={productSelectOnChangeHandler} data={productSelect} width={600} value={productValue}/>
          </div>
          <div>
                        Raw Material:&nbsp;
            <Iselect onChange={materialSelectOnChangeHandler} data={materialSelect} width={600} value={materialValue}/>
          </div>
          {/* <div>
            <span>Total Stock: </span><span style={{color:"blue"}}>{totalStock+stackingStock+tempStock}</span>
          </div> */}
          <Button
            onClick={() => setIsAddToStockModalVisible(true)}
            style={buttonStyle}
          >
            Print Barcode
          </Button>

          <Button 
            type="primary" 
            onClick={() => setIsPlateManagementModalVisible(true)}
            style={buttonStyle}  // Apply the same styles
          >
            Pallet Management
          </Button>      
        </Space>
      </div>
      <PackagingPlateManagementModal {...commonModalProps(isPlateManagementModalVisible, setIsPlateManagementModalVisible, 'plateManagement')} />

      <div style={{marginBottom: 10}}>
        <span><b>Total Stock:</b> </span><span style={{color:"blue"}}>{totalStock+areaStock}</span>
        , <b>Location: </b> 
        {
          positionNotification.map((firstLayer:any) => {
            let productPosition
            let ProductCount = 0
            // console.log(firstLayer, 'position')
            firstLayer.map((secondLayer:any, index:number) => {
              // console.log(secondLayer,index)
              ProductCount += secondLayer.quantity
              productPosition = `${secondLayer.shelfNo}-${secondLayer.cellPosition.rowNo}-${secondLayer.cellPosition.colNo}`
              return
            })
            return (
              <span>{productPosition} X {ProductCount}; </span>
            )

          })
        }
      </div>
  
      <ShelfSelectorEdit productValue={productValue} materialValue={materialValue} data={zoneData} getProductsDetailByShelf={getProductsDetailByShelf} getMaterialsDetailByShelf={getMaterialsDetailByShelf} getPlatesDetailByShelf={getPlatesDetailByShelf} fetchShelfData={fetchShelfData}/>
      <Row style={{marginTop: 20, textAlign: 'center', }}>
        <Col span={4} style={{border: '1px black solid', height: 100}} onClick={() => handleAreaClick(packagingArea, "Packaging Area")}>
          <b style={{fontSize: 20}}>Packaging Area</b>
          <hr/>
          {getAreaText(packagingArea, "Packaging Area")}
        </Col>
        <Col span={4} style={{border: '1px black solid', height: 100}} onClick={() => handleAreaClick(pickingArea, "Picking Area")}>
          <b style={{fontSize: 20}}>Picking Area</b>
          <hr/>
          {/* Note: Dispatching Area data is Picking Area ,pickingArea as handled*/}
            {getAreaText(pickingArea, "Picking Area")}
        </Col>        
        <Col span={4} style={{border: '1px black solid', height: 100}} onClick={() => handleAreaClick(dispatchingArea, "Handled Area")}>
          <b style={{fontSize: 20}}>Handled</b>
          <hr/>
          {getAreaText(dispatchingArea, "Handled Area")} 
        </Col>
        <Col span={4} style={{border: '1px black solid', height: 100}} onClick={() => handleAreaClick(sticker, "Printed Barcode")}>
          <b style={{fontSize: 20}}>Printed Barcode</b>
          <hr/>
          {getAreaText(sticker, "Printed Barcode")}
        </Col>        
      </Row>
      <Row style={{marginTop: 20, textAlign: 'center', }}>
        {zoneType?.map((zone:any, index:number) => (
        <Col span={4} style={{border: '1px black solid', height: 100}}  onClick={() => onAreaClick(zone.zoneType, zone.zoneTypeName)}>
          <b style={{fontSize: 20}}>{zone.zoneTypeName}</b>
          <hr/>
          {getZoneText(zone.zoneType, zone.zoneTypeName)}
        </Col>
        ))}
      </Row>
      <ShowModal data={selectedDataFromShelf} onOk={() => setIsModalVisible(false)} visible={isModalVisible} contentsFor={modalTitle} productData={getAllProduct} materialData={getAllMaterial} productDelete={productDeleteHandler}/>
      <ShelfPlate  shelfCode={shelfCode} visible={showShelfDetails} onOk={() => setShowShelfDetails(false)} productDelete={productDeleteHandler}/>
      

      <AddToStockPrintQrModal {...{
        visible: isAddToStockModalVisible,
        onOk: () => {
          // getAllTempZone()
          setIsAddToStockModalVisible(false)
        },
        onCancel: () => setIsAddToStockModalVisible(false),
        isForPrint:true
      }} />

    </div>

  )
}

const ShowModal = (props:{data: any, onOk:()=>void, visible:boolean, contentsFor:string, productData:any, materialData:any, productDelete:(data:any)=>void}) => {
  let contents:any
  // debugger
  // console.log(props.data)
  switch (props.contentsFor) {
    case 'Product':
      contents = <ProductTable data={props.data} productData={props.productData} productDelete={props.productDelete}/>
      break
    case 'Material':
      contents = <MaterialTableOfShelf data={props.data}/>
      break
  }
  return (
    <Modal title={props.contentsFor} visible={props.visible} onOk={props.onOk} onCancel={props.onOk} width={1100} destroyOnClose={true}>
      {contents}
    </Modal>
  )
}

const ShelfBoxDetail = (props: {boxData: any, rawMaterialBoxData: any, onOk: () => void, visible: boolean, productDelete: (data: any) => void}) => {
  const { TabPane } = Tabs
  return (
    <Modal title={'Shelf Boxes Detail'} visible={props.visible} onOk={props.onOk} onCancel={props.onOk} width={1100} destroyOnClose={true}>
      <Tabs defaultActiveKey="product">
        <TabPane tab="Product" key="product">
          <ProductTable data={props.boxData} productData={null} productDelete={props.productDelete}/>
        </TabPane>
        <TabPane tab="Material" key="material">
          <MaterialTableOfShelf data={props.rawMaterialBoxData}/>
        </TabPane>
      </Tabs>
    </Modal>
  )
}

const ShelfPlate =  (props: { shelfCode:string, visible: boolean, onOk: any, productDelete:(data:any)=>void}) => {
  const [boxData, setBoxData] = useState<any>([])
  const [rawMaterialBoxData, setRawMaterialBoxData] = useState<any>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [rows, setRows] = useState<any>([])  

  const openShelfPlateDetails = (plate: any) => {
    // debugger
    if (!plate) return;
    if (!plate.box || !plate.rawMaterialBox) return;
    plate.box.forEach((item: any) => {
      item.position = plate.locationName
      item.palletNo = plate.plateCode
    })

    plate.rawMaterialBox.forEach((item: any) => {
      item.position = plate.position
      item.palletNo = plate.plateCode      
    })

    setBoxData(plate.box)
    setRawMaterialBoxData(plate.rawMaterialBox)
    setShowModal(true)
  }
  useEffect(() => {
    fetchData();
}, [props.shelfCode]);
  const fetchData = async () => {
    const prefix = props.shelfCode;
    if (!prefix || prefix.length == 0) return
    const respo = await ApiRequest({
      url: 'Plate/GetByCell?shelfNo=' + prefix,
      method: 'get',
      isShowSpinner: false,
    });
    let palletData = respo.data.data;
    // debugger
    const positions = palletData?.map((item: Plate) => item.position);

    if (prefix && prefix.length > 0) {
      const response = await ApiRequest({
        url: 'Cell/GetCellByShelfNo?shelfNo=' + prefix,
        method: 'get',
        isShowSpinner: false,
      });
      console.log(response.data.data);
      const newShelves = response.data.data;
      newShelves.forEach((ele: any) => {
        let newPos = `${prefix}-${ele.rowNo}-${ele.colNo}`;
        let newLocationName = `${prefix}-${ele.rowName}-${ele.colName}`;
        let active =  ele.active;
        if (!positions.includes(newLocationName)) {
          palletData.push({ position: newPos,locationName:newLocationName , plateCode: '' ,active:active});
        }else {
          const foundElement = palletData?.find((item: Plate) => item.position === newLocationName);
          foundElement.locationName = newLocationName;
          foundElement.position = newPos;
          foundElement.active = active;
        }

      });
      // debugger
      const newPositions = newShelves?.map((ele: any) => `${prefix}-${ele.rowNo}-${ele.colNo}`);
      const filteredData = palletData?.filter((item: Plate) => newPositions.includes(item.position));

      filteredData.sort((a: Plate, b: Plate) => {
        if (typeof a.position === 'string' && typeof b.position === 'string') {
          const partsA = a.position.split('-');
          const partsB = b.position.split('-');
          const middleNumA = parseInt(partsA[1], 10);
          const middleNumB = parseInt(partsB[1], 10);
    
          // 首先根据中间的数字进行降序排序
          if (middleNumA !== middleNumB) {
            return middleNumB - middleNumA;
          }
    
          // 如果中间的数字相同，则根据最后的数字进行升序排序
          const lastNumA = parseInt(partsA[2], 10);
          const lastNumB = parseInt(partsB[2], 10);
          return lastNumA - lastNumB;
        }
      });

      
      const groupByPositionParts = (data: any) => {
        const groupedData: any = {};
        for (const obj of data) {
            const parts = obj.position.split('-');
            const key = parts[1];
            if (!groupedData[key]) groupedData[key] = [];
            groupedData[key].push(obj);
        }
        return groupedData;
    };
      
      // Convert the grouped data to a two-dimensional array
      const groupedData  = groupByPositionParts(filteredData);
      // groupedData[1].length
      // const twoDimArray = Object.keys(groupedData).map(key => groupedData[key]);

      setRows(groupedData);
    }

  };
  const letterToNumber = (shelfCodeX: string) => {
    const letter = shelfCodeX[0];
    var numberAAA = letter.charCodeAt(0) - 'A'.charCodeAt(0) + 1;
    if (numberAAA >= 5) {
      numberAAA = numberAAA + 1;
    }
    return numberAAA;
  }

const NumberToletter = (shelfCodeX:string) => { 
    const numberPart = shelfCodeX.substring(1); 
    return parseInt(numberPart, 10);
}   
  const handleClick = async (shelfCodeX:string) => {
    let resultInput = await Swal.fire({
        iconHtml: '<i class="fas fa-edit" style="color: #4285F4;"></i>',
        title: 'Input Shelf Details',
        html: `
            <div style="margin-bottom: 10px;">
                <label for="rows" style="color: #4285F4; font-weight: 500;">Number of Rows:</label>
                <input id="rows" type="number" value="3" style="border: 1px solid #D9D9D9; border-radius: 4px; padding: 6px 12px; font-size: 14px; width: 60px;" required>
            </div>
            <div>
                <label for="cols" style="color: #4285F4; font-weight: 500;">Number of Columns:</label>
                <input id="cols" type="number" value="2" style="border: 1px solid #D9D9D9; border-radius: 4px; padding: 6px 12px; font-size: 14px; width: 60px;" required>
            </div>
        `,
        preConfirm: () => {
            const numRows = (document.getElementById('rows') as HTMLInputElement)?.value;
            const numCols = (document.getElementById('cols') as HTMLInputElement)?.value;
            return { numRows, numCols };
        },
        showCancelButton: true,
        focusConfirm: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
            container: 'custom-swal-container',
            confirmButton: 'custom-swal-confirm-button',
            cancelButton: 'custom-swal-cancel-button'
        },
        confirmButtonColor: '#007bff', // You can customize the confirm button color
        cancelButtonColor: '#dc3545', // You can customize the cancel button color
        confirmButtonText: 'Confirm', // You can customize the confirm button text
        cancelButtonText: 'Cancel', // You can customize the cancel button text
    });

    console.log(resultInput);
    // debugger
    if (!resultInput.isConfirmed) return;

    if (shelfCodeX) {
        const resPlateBox = await ApiRequest({
            url: 'Shelf/AddOrUpdate',
            method: 'put',
            data: {
                shelfCode: shelfCodeX,
                position: NumberToletter(shelfCodeX),
                totalRows: Number(resultInput?.value?.numRows),
                totalCols: Number(resultInput?.value?.numCols),
                zoneId: letterToNumber(shelfCodeX)
            }
        }).then((res: any) => {
          // debugger
          fetchData()
        })
    }
};
const handleEditClick = async (Locatiion:string) => {
  const parts = Locatiion.split("-");
  if (parts.length<3) return;
  let resultInput = await Swal.fire({
      iconHtml: '<i class="fas fa-edit" style="color: #4285F4;"></i>',
      title: 'Input Shelf Details',
      html: `
          <div style="margin-bottom: 10px;">
              <label for="rows" style="color: #4285F4; font-weight: 500;">Name of Row:</label>
              <input id="rows" type="text" value="${parts[1]}" style="border: 1px solid #D9D9D9; border-radius: 4px; padding: 6px 12px; font-size: 14px; width: 60px;" required>
          </div>
          <div>
              <label for="cols" style="color: #4285F4; font-weight: 500;">Name of Column:</label>
              <input id="cols" type="text" value="${parts[2]}" style="border: 1px solid #D9D9D9; border-radius: 4px; padding: 6px 12px; font-size: 14px; width: 60px;" required>
          </div>
      `,
      preConfirm: () => {
          const nameRows = (document.getElementById('rows') as HTMLInputElement)?.value;
          const nameCols = (document.getElementById('cols') as HTMLInputElement)?.value;
          return { nameRows, nameCols };
      },
      showCancelButton: true,
      focusConfirm: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
          container: 'custom-swal-container',
          confirmButton: 'custom-swal-confirm-button',
          cancelButton: 'custom-swal-cancel-button'
      },
      confirmButtonColor: '#007bff', // You can customize the confirm button color
      cancelButtonColor: '#dc3545', // You can customize the cancel button color
      confirmButtonText: 'Confirm', // You can customize the confirm button text
      cancelButtonText: 'Cancel', // You can customize the cancel button text
  });

  console.log(resultInput);
  // debugger
  if (!resultInput.isConfirmed) return;

  if (Locatiion) {
      const resPlateBox = await ApiRequest({
        url: 'Shelf/UpdateCellNameByCode?code=' + Locatiion +
            "&RowName=" + resultInput?.value?.nameRows +
            "&ColName=" + resultInput?.value?.nameCols,
        method: 'put'
    }).then((res: any) => {
        // debugger
        fetchData();
    });
  }
};

  const handleToggleClick = async (Locatiion: string) => {
    const parts = Locatiion.split("-");
    if (parts.length < 3) return;

    const resPlateBox = await ApiRequest({
      url: 'Shelf/ToggleCellByCode?code=' + Locatiion,
      method: 'put'
    }).then((res: any) => {
      fetchData();
    });
  };
  //gutter={[16, 16]} 控制表格每个元素之间的距离
  return (
    <Modal title={'Shelf: '+props.shelfCode} visible={props.visible} onOk={props.onOk} onCancel={props.onOk} width={1400} destroyOnClose={true}>
      <div style={{margin: '1rem 0'}}>
        {Object.keys(rows).sort((a, b) => parseInt(b, 10) - parseInt(a, 10)).map((groupKey :any, rowIndex:number) => (
          <Row key={rowIndex} gutter={[16, 16]} style={{ borderBottom: '1px solid #e8e8e8' }}>
            {rows[groupKey].map((plate: Plate, plateIndex: number) => (
              <Col key={plateIndex} span={24/rows[groupKey].length}> 
                <Card
                  bodyStyle={{ padding: 10, display: 'flex', alignItems: 'center' }}
                  bordered={true}
                  style={{ 
                    width: '100%', 
                    marginBottom: '16px', 
                    border: '1px solid #d9d9d9',
                    backgroundColor: plate.active === 1 ? 'white' : 'grey' // Set background color based on plate.active
                  }}
                >
                  <div style={{ flex: 1, marginRight: '10px' }}>
                    <b>{plate.locationName } </b>
                  </div>                  
                  <div style={{ flex: 2, marginRight: '10px' }}>
                    {plate.plateCode && (
                      <>
                        <img
                          src={'https://jadcup.oss-cn-hongkong.aliyuncs.com/7767c281-6989-4601-a9d0-d47596a9a8f9'}
                          alt="Machine"
                          style={{ width: '35%', height: 'auto' }}
                          onClick={() => openShelfPlateDetails(plate)}
                        />
                        <b>{plate.plateCode}</b>
                        <Button
                          type="primary"
                          icon={<PrintOutlined />} // Changed to EditOutlined
                          onClick={() => palletSticker([plate.plateCode], 'print')} 
                          style={{ margin: '0px 0px 0px 10px' }} // 自定义padding
                        >
                        </Button>
                      </>
                    )}
                  </div>
                  <div style={{ flex: 2 }}>
                    <div>
                      <div>
                        <div>
                          <b>{!plate.plateCode && 'Empty'}</b>
                          {plate.box?.length > 0 && `Prod QTY: ${plate.box.length}`}
                          {plate.rawMaterialBox?.length > 0 && `Raw Material QTY: ${plate.rawMaterialBox.length}`}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div  style={{ flex: 2 }}>
                    <Button
                      type="primary"
                      icon={<EditOutlined />} // Changed to EditOutlined
                      onClick={() => handleEditClick(plate.locationName)}
                      style={{ margin: '0px 0px 0px 10px' }} // 自定义padding
                    >
                    </Button>   
                    <Button
                      type="primary"
                      icon={<StopOutlined />} // Changed to EditOutlined
                      onClick={() => handleToggleClick(plate.locationName)}
                      style={{ margin: '0px 0px 0px 10px' }} // 自定义padding
                    >
                    </Button>                       
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        ))}
      </div>
      <Button
        type="primary"
        icon={<EditOutlined />} // Changed to EditOutlined
        onClick={() => handleClick(props.shelfCode)}
        style={{ margin: '0px 0px 0px 10px' }} // 自定义padding
      >
      </Button>   
      <ShelfBoxDetail boxData={boxData} rawMaterialBoxData={rawMaterialBoxData} onOk={() => setShowModal(false)} visible={showModal} productDelete={props.productDelete}/>
    </Modal>
  );
  
}

export default WarehouseManagement
