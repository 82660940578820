import React from 'react'
import TextField from '@material-ui/core/TextField'
import { getColModelItem } from '../../../../services/lib/utils/helpers'


export default class ExtraAddressManagementColumnModel {
    static ExtraAddressManagementColumn = [
      {
        ...getColModelItem({
          title: 'Address Name',
          field: 'address',
          keywords: [
            'address'
          ]
        }, () => ([{key: 'address', label: '', otherOptions: {type: 'googleMapAddrSelect', width: '20rem', isNotCutting: true}}]))
      },
      // {
      //   title: 'Postal Code',
      //   align: 'left',
      //   field: 'postalCode'
      // },
      {
        title: 'Delivery Name',
        align: 'left',
        field: 'deliveryName',
      },
 
      {
        ...getColModelItem({
        title: 'Delivery Method',
        field: 'deliveryMethod',
        keywords: ['deliveryMethod']
        }, () => ([{key: 'deliveryMethod', label: '',
           otherOptions: {type: 'select', rowDataKeyword: 'deliveryMethodId'}}])),
         render: (rowData:any) => rowData?.deliveryMethod ? `${rowData?.deliveryMethod?.deliveryMethodName}` : null,       
      },  
      {
        title: 'Delivery Instruction',
        align: 'left',
        field: 'notes',
        editComponent: (props:any) => (
          <TextField
            value={props.value}
          fullWidth={true}
          multiline={true}
          onChange={e => props.onChange(e.target.value)}
          />)	        
      },      
    ]
}
