import React from 'react'
import ItipsForProduct from '../../../components/common/i-tips/product'
import { urlKey } from '../../../services/api/api-urls'
import { getColModelItem, nbsStr } from '../../../services/lib/utils/helpers'

const MonitorProductColumnModel = () => {
	return [
		{
			title: 'Product Code',
			align: 'left',
			field: 'productCode',
			render: (rowData: any) => <ItipsForProduct id={rowData.productId} label={rowData.productCode} />,
			// render: (rowData: any) => rowData.product ? <ItipsForProduct id={rowData.productId} label={rowData.product.productName} /> : null
		},
		{
			title: 'Product Name',
			align: 'left',
			field: 'productName',
			render: (rowData: any) => <ItipsForProduct id={rowData.productId} label={rowData.productName} />
			// render: (rowData: any) => rowData.product ? <ItipsForProduct id={rowData.productId} label={rowData.product.productName} /> : null
		},

		{
			title: 'Availiable Stock',
			align: 'left',
			field: 'productInStock',
			type: 'numeric'
		},

		{
			title: 'Pending Order',
			align: 'left',
			field: 'pendingOrder',
			type: 'numeric'
		},		
		{
			title: 'Approved Work Order',
			align: 'left',
			field: 'pendingWorkOrderQuantity',
			type: 'numeric'
		},
		{
			title: 'Approved Purchase Order',
			align: 'left',
			field: 'poQty',
			type: 'numeric'
		},		
		// {
		// 	title: 'Approved Purchase Order',
		// 	align: 'left',
		// 	field: 'pendingWarehouseQuantity',
		// 	type: 'numeric'
		// },

		{
			title: 'Msl',
			align: 'left',
			field: 'productMsl',
			type: 'numeric'
		},
		// {
		// 	title: 'In Packaging',
		// 	align: 'left',
		// 	field: 'pendingWarehouseQuantity',
		// 	type: 'numeric'
		// },  
  		
		{
			title: 'Sales Growth/Decline rate Compare to Last 6 month',
			align: 'left',
			field: 'rate',
		},  
		{
			title: 'Sales volume in the past 6 months',
			align: 'left',
			field: 'last6monthsOrderCount',
			type: 'numeric',
			cellStyle: {
				backgroundColor: '#F0F0F0',
				// color: '#FFF'
			  },			
		},  
		{
			title: 'Last year\'s sales for the same 6-month',
			align: 'left',
			field: 'lastlastlast6monthsOrderCount',  //lastlast6monthsOrderCount
			type: 'numeric',
			cellStyle: {
				backgroundColor: '#F0F0F0',
				// color: '#FFF'
			  },			
		},  
		{
			title: 'Sales for the 6 months after the corresponding period last year',
			align: 'left',
			field: 'lastlast6monthsOrderCount',  //lastlastlast6monthsOrderCount
			type: 'numeric',
			cellStyle: {
				backgroundColor: '#F0F0F0',
				// color: '#FFF'
			  },			
		},  
		{
			title: 'Low Stock',
			align: 'center',
			field: 'isLowStock',
			defaultFilter: ['1'],
			lookup: {0: 'No', 1: 'Yes'},
			// formatter: 'tickCross', 		
      // defaultFilter: ['1'],
      // lookup: { 1: 'InProcessing', 10: 'Completed' },      			
		},  		
		
  ]
}

export default MonitorProductColumnModel
