import React, {useEffect, useState} from 'react'
import {ApiRequest} from '../../../../../../services/api/api'
import {JadCupImg, emptyImg} from '../../../../../../services/others/assets'
import {Button, Checkbox, Form, InputNumber, Modal, Row} from 'antd'
import {SuborderCommonPresentation} from '../../suborder-list/suborder-modal/suborder-common-modal/suborder-common-presentation'
import { checkMachineUserName } from '../../suborder-list/suborder-modal/suborder-common-modal/suborder-common-modal'
import productStickersPdfGenerate from '../../../../../static/pdf/product-stickers/product-stickers-pdf-generate'
import SweetAlertService from '../../../../../../services/lib/utils/sweet-alert-service'
import { event } from 'jquery'

export const PackagingPrintQrModal = (props: {visible: any, onOk: any, onCancel: any, data: any, machine: any, isNotRequiredPassword: boolean}) => {
  const {visible, onOk, onCancel, data, machine} = props
  const [count, setCount] = useState<any>()
  const [isVisible, setIsVisible] = useState(false)
  
  const [barcodeOptionsList, setBarcodeOptionsList] = useState([])
  const [allBoxData, setAllBoxData] = useState<any>([])

  const [barcodeOptions, setBarcodeOptions] = useState<any>([])
  const [barcodeCheckbox, setBarcodeCheckbox] = useState<any>([])
  const [indeterminate, setIndeterminate] = useState<any>({})
  const [checkedList, setCheckedList] = useState<any>({})

  const [checkAll, setCheckAll] = useState<any>({})
  const [base64, setBase64] = useState(String)
  const [groupedData, setGroupedData] = useState<any>([])
  const [uniqueIdentifier, setUniqueIdentifier] = useState<Number>(0)
  useEffect(() => {
    if (!visible) {
      setIsVisible(false)
      return
    }
    if (props.isNotRequiredPassword && visible) {
      setIsVisible(true)
      return
    }
    if (!props.isNotRequiredPassword) {
      checkMachineUserName(visible, machine, setIsVisible, onCancel)
    }
  }, [props.visible])

  useEffect(() => {
    if (data?.suborderId) {
      setBarcodeOptions([])
      setBarcodeOptionsList([])
      setAllBoxData([])
      setBarcodeCheckbox(null)
      // setIndeterminate(true)
      // setCheckAll(false)
      setBase64(String)
      console.log(data)
      console.log(data.product?.baseProduct?.packagingType?.quantity)
      const calcCount = data.receivedQuantity / (data.product?.baseProduct?.packagingType?.quantity || 999999999)
      setCount(Math.ceil(calcCount))
      getBarcodeFromApi(Math.ceil(calcCount))
      getImg(data.product.logoType, data.product.logoUrl)
    }
  }, [data])

  const getBarcodeFromApi = (value?: any) => {
    if (data?.suborderId) {
      ApiRequest({
        url: 'Box/GetAllBox?suborderId=' + data.suborderId,
        method: 'get'
      }).then(res => {
        const responseData = res.data.data
        setAllBoxData(responseData)
        responseData.sort((a: any, b: any) => a.sequence - b.sequence)
        const barCodeArr: any = []
        // const barcodeOptionsData = responseData.map((row: any) => {
        //   barCodeArr.push(row.barCode)
        //   return {
        //     value: row.barCode,
        //     label: row.barCode
        //   }
        // })
        setBarcodeOptionsList(barCodeArr)
        // setBarcodeOptions(barcodeOptionsData)

        const groupedData = responseData.reduce((result:any, current:any) => {
          const createdAt = current.createdAt;
          if (!result[createdAt]) {
            result[createdAt] = [current.barCode];
          } else {
            result[createdAt].push(current.barCode);
          }
          return result;
        }, {});
        console.log(groupedData)
        for (const createdAt in groupedData) { 
          console.log("forEach groupedData",createdAt)
          // const createdAt = ele[0];
          indeterminate[createdAt] = true;
          checkAll[createdAt] = false;
          checkedList[createdAt] = null;
        };

        setGroupedData(groupedData);
        setIndeterminate(indeterminate);
        setCheckAll(checkAll);
        setCheckedList(checkedList);
        console.log(groupedData)
        
        if (value && !responseData.length) {
          onGenerate(value, true)
        }
      })
    }
  }


  const onGenerate = async (value?: any, notConfirm?: boolean) => {
    if (!notConfirm) {
      const result = await SweetAlertService.confirmMessage()
      if (!result) {
        return
      }
    }
    if (data.suborderId && (value || count)) {
      ApiRequest({
        url: 'Box/GenerateBarCode?suborderId=' + data.suborderId + '&count=' + (notConfirm ? value : count),
        method: 'post',
      }).then(res => {
        console.log(res)
        getBarcodeFromApi()
      })
    }
  }

  const getBase64FromUrl = (url: string) => {
    ApiRequest({
      url: 'Common/UrltoBase64?imageFile=' + url,
      method: 'get'
    }).then(res => {
      const responseData = res.data
      setBase64('data:image/png;base64,' + responseData)
    })
  }

  const getImg = (logoType:number, logoUrl:string) => {
    if (logoType == 0 || logoType === 3) {
      setBase64(JadCupImg)
    } else if (logoType == 1) {
      const imgUrl = JSON.parse(logoUrl.split('---')[0]).url
      getBase64FromUrl(imgUrl)
    } else {
      setBase64(emptyImg)
    }
  }

  const onPrint = async (barCodes:any) => {
    // const productDetail = ProductDetailInPdf(barCodes)
    productStickersPdfGenerate( ProductDetailInPdf(barCodes), 'print')
  }
  const pdfViewHandler = async (barCodes:any) => {
    // const productDetail = ProductDetailInPdf(barCodes)
    productStickersPdfGenerate( ProductDetailInPdf(barCodes), 'view')
  }  
  const onDelete = async (barCodes:any) => {
    const confirmResult = await SweetAlertService.confirmMessage()
    if (!confirmResult) {
      return
    }
    // console.log(barcodeCheckbox)
    // console.log(barcodeOptions)
    // console.log(barcodeOptionsList)
    const boxids:any[] = []
    for (const row of allBoxData) {
      if (barCodes.includes(row.barCode)) {
        boxids.push(row.boxId)
      }
    }
    await ApiRequest({
      url: 'Box/DeleteBox',
      method: 'put',
      data: boxids
    })

    getBarcodeFromApi()
  }



  const ProductDetailInPdf = (barCodes:any) => {
    const product = data.product.productName
    const logoType = data.product.logoType
    const logoUrl = data.product.logoUrl
    const productCode = data.product.productCode
    const packaging = 'QTY:' + data.product?.baseProduct?.packagingType?.sleeveQty + 'PCS X '
                    + data.product?.baseProduct?.packagingType?.sleevePkt + 'PK'
    const madeInNz = (data.product.logoType != 4) ? 'Made In New Zealand' : ''

    const productDetail = barCodes?.map((res:string) => {
      return {
        barcode: res,
        productName: product,
        logoType: logoType,
        logoUrl: logoUrl,
        logoBase64: base64,
        productCode: productCode,
        packaging: packaging,
        madeInNz: madeInNz,
        testLog: JadCupImg,
        manufactureDate:null
      }
    })
    return productDetail
  }

  const onChangeCount = (value: any) => setCount(value)

  const onChange = (checkedList:any,date:string) => {
    checkedList[date] = checkedList;
    setCheckedList(checkedList);
    indeterminate[date] = !!checkedList[date].length && checkedList[date].length < groupedData[date].length;
    setIndeterminate(indeterminate);
    checkAll[date] =checkedList[date].length === groupedData[date].length;
    setCheckAll(checkAll);
    setUniqueIdentifier(Math.random())
  };

  const  onCheckAllChange = (e:any,date:string) => {
    checkedList[date] = e.target.checked ? groupedData[date] : [];
    setCheckedList(checkedList);
    indeterminate[date] = false;
    setIndeterminate(indeterminate);
    checkAll[date] =e.target.checked
    setCheckAll(checkAll);
    setUniqueIdentifier(Math.random())
  };

  const onCancelModal = () => {
    onCancel()
    setIsVisible(false)
  }

  const onConfirmModal = () => {
    onOk()
    setIsVisible(false)
  }
  useEffect(() => {
    // setIndeterminate(false);
  }, [groupedData]);
  return (
    <Modal destroyOnClose={true} title="打印条形码/Print QR" visible={isVisible} onCancel={onCancel} width={1000} footer={false}>
      <SuborderCommonPresentation data={data} />
      <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '1rem'}}>
        <Button
          onClick={onCancel}
          style={{marginRight: '2rem'}}
        >取消/Cancel</Button>
        <Button
          onClick={onOk}
          type="primary"
        >确定/Confirm</Button>
      </div>
      <Row>
        <Form validateMessages={{required: 'required'}} id="printQrModalForm">
          <Form.Item label="条形码数量/BarCode Stickers Count">
            <InputNumber value={count} min={1} max={500} onChange={onChangeCount} />
            {/* <Button type="primary" disabled={!count} style={{marginLeft: '1rem'}} onClick={onGenerate}>生成/Generate new</Button> */} 
            <Button type="primary" disabled style={{marginLeft: '1rem'}} onClick={onGenerate}>生成/Generate new</Button>
          </Form.Item>
          <Form.Item label="已生成/Generated Barcode"></Form.Item>
          {Object.entries(groupedData).map(([dateString]) => (
            <Form.Item label={`${new Date(dateString).toLocaleString('en-GB', { timeZone: 'UTC', day: '2-digit', month: '2-digit',year: '2-digit'})}`}>
              <Checkbox indeterminate={indeterminate[dateString]} onChange={(event)=>onCheckAllChange(event,dateString)} checked={checkAll[dateString]}>
                   全选/Check all (勾选数量：{checkedList[dateString] ? checkedList[dateString].length : 0} of {groupedData[dateString]?.length || 0})
              </Checkbox>
              <div><Checkbox.Group  key={uniqueIdentifier.toString()} value={checkedList[dateString]} onChange={(checkedList)=>onChange(checkedList,dateString)}  options={groupedData[dateString]} /></div>
              
              {/* <Checkbox.Group value={items?.length} onChange={onChangeBarcodeCheckbox} options={getBarcodeOptions(items)} /> */}
              {/* <Checkbox.Group value={barcodeCheckbox}   onChange={onChangeBarcodeCheckbox} options={getBarcodeOptions(ele.items)}  /> onDelete*/}
              <div>
                <Button type="primary" disabled={!checkedList[dateString]?.length} style={{ margin: '1rem' }} onClick={(event)=>onPrint(checkedList[dateString])}>打印/Print</Button>
                <Button type="primary" danger disabled={!checkedList[dateString]?.length} style={{ margin: '1rem' }} onClick={(event)=>onDelete(checkedList[dateString])}>删除/Delete</Button>
                {checkedList[dateString]?.length && <a onClick={()=>pdfViewHandler(checkedList[dateString])}>PDF view</a>} 
              </div>
            </Form.Item>
          ))}
        </Form>
        {/* {barcodeCheckbox && <a onClick={pdfViewHandler}>PDF view</a>} */}
      </Row>
      <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '1rem'}}>
        <Button
          onClick={onCancel}
          style={{marginRight: '2rem'}}
        >取消/Cancel</Button>
        <Button
          onClick={onOk}
          type="primary"
        >确定/Confirm</Button>
      </div>
    </Modal>
  )
}
