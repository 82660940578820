// const preUrl = 'https://api.spiritx.tk'
const preUrl = 'https://apijadcup.gradspace.org'
// const preUrl = 'https://api.nzcups.co.nz'
// const preUrl = 'http://localhost'

//export const frontEndBaseUrl = preUrl + ':8001'
export const frontEndBaseUrl = 'https://jadcup.gradspace.org'

const urlWithPort = preUrl

// const urlWithPort = preUrl + ':5020'
export const baseUrl = urlWithPort + '/api/'
