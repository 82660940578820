import React, { useState,useEffect } from 'react'
import CommonTablePage from '../../../../components/common/common-table/common-table-page'
import { urlKey, urlType } from '../../../../services/api/api-urls'
import { ApiRequest } from '../../../../services/api/api'
import { toLocalDateTime } from '../../../../services/lib/utils/helpers'
import SweetAlertService from '../../../../services/lib/utils/sweet-alert-service'
import { ApproveDelColumnModel } from './approve-delete-column-model'

import { format } from 'date-fns';

const ApproveDeletePage = (): any => {
  const today = new Date();
  const oneMonthFromToday = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());

  const [triggerResetData, setTriggerResetData] = useState(false)
  const [selectedRows, setSelectedRows] = useState<any>()
  const [startDate, setStartDate] = useState(
    format(new Date(Date.now() - 31 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd')
  );
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));


  useEffect(() => {
    document.title = "Delete History";
   }, [])

   const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value);
  };

  const getAllUrl  = () => {
    const url = `Box/GetDel?beginDate=${ new Date(startDate).toISOString()}&endDate=${ new Date(endDate).toISOString()}`;
    return url;
  }   
  const getRenderData = (data: any) => {
    console.log(data)
    const renderData: any = []
    data.map((row: any) => {
      renderData.push({
        ...row,
        productName: row.product?.productName,
        createdAt1: row.createdAt,
        createdAt: row.createdAt,
        position: row.position,
        palletCode: row.palletCode,
      })
    })
    return renderData
  }

  const onSelectionChange = (rows: any) => {
    console.log(rows)
    setSelectedRows(rows)
  }

  const actionButtons: any = [
    {
      icon: 'danger',
      tooltip: 'Approve Delete',
      isFreeAction: false,
      onClick: async (event: any, rowData: any) => {

        let resultInput1 = await SweetAlertService.confirmMessage("Are you sure you want to approve the deletion?");
        if (!resultInput1){
          return;
        }
        const ids = selectedRows.map((row: any) => row.boxId)
        ApiRequest({
          url: "Box/ObsoleteBoxs",
          method: 'put',
          data: ids
        }).then(_ => {
          setTriggerResetData(!triggerResetData)
        })        
        // ApiRequest({
        //   url: 'Box/ObsoleteBoxs',
        //   method: 'put',
        //   data: ids
        // }).then(_ => {
        //   setTriggerResetData(!triggerResetData)
        // })
      }
    } ,
    {
      icon: 'ghost',
      tooltip: 'Decline Delete',
      isFreeAction: false,
      onClick: async (event: any, rowData: any) => {
        let resultInput = await SweetAlertService.confirmMessage("Are you sure to Decline the deletion? This will cancel the deletion of the product.");
        if (!resultInput){
          return;
        }
        const ids = selectedRows.map((row: any) => row.boxId)
        ApiRequest({
          url: "Box/DeclineDelete",
          method: 'put',
          data: ids
        }).then(_ => {
          setTriggerResetData(!triggerResetData)
        })        
        // ApiRequest({
        //   url: 'Box/ObsoleteBoxs',
        //   method: 'put',
        //   data: ids
        // }).then(_ => {
        //   setTriggerResetData(!triggerResetData)
        // })
      }
    }     
  ]

  return (
    <div style={{ alignItems: 'center', justifyContent: 'center' }}>
      <div >
        <label htmlFor="start-date" style={{ marginRight: '10px' }}>Start Date:</label>
        <input
          id="start-date"
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          style={{ marginRight: '20px' }}
        />

        <label htmlFor="end-date" style={{ marginRight: '10px' }}>End Date:</label>
        <input
          id="end-date"
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          style={{ marginRight: '20px' }}
        /> 
      </div>
      <CommonTablePage
        urlInfoKey={urlKey.Box}
        getAllUrl = {getAllUrl()}
        title="Delete History"
        isNotAddable={true}
        isNotEditable={true}
        isNotDeletable={true}
        // actionButtons={actionButtons}
        mappingRenderData={(data: any) => getRenderData(data)}
        column={ApproveDelColumnModel}
        triggerResetData={triggerResetData}
        onSelectionChange={onSelectionChange}
      />
    </div>
  )
}

export default ApproveDeletePage
