import React, { useState ,useEffect} from 'react'
import moment from 'moment'
import CommonTablePage, { CommonTablePagePropsInterface } from '../../../../components/common/common-table/common-table-page'
import CommonDialog from '../../../../components/common/others/common-dialog'
import { urlKey } from '../../../../services/api/api-urls'
import SweetAlertService from '../../../../services/lib/utils/sweet-alert-service'
import { PurchaseOrderColumnModel } from './purchase-order-column-model'
import PurchaseOrderDialog from './purchase-order-dialog/purchase-order-dialog'
import { ApiRequest } from '../../../../services/api/api'
import EmailModal1 from '../../../../components/common/email-dialog'
import {getCookie} from 'react-use-cookie'
import { getRandomKey, toLocalDate } from '../../../../services/lib/utils/helpers'
// import quotationPdfGenerate from '../../static/pdf/PurchaseOrder/PurchaseOrder-pdf'
import PurchaseOrderPdf from '../../../static/pdf/purchaseOrder/purchaseOrder-pdf'
import { Button } from 'antd'
import Swal from 'sweetalert2'

interface Email {
  employeeEmail: string
  customerEmail: string
}
// isApprovePage ==> pageType  1: Purchase Order  2 .Purchase Order Approve  3 . payable
export const PurchaseOrderPage = ({ pageType = 1 }: { pageType?: number }) => {
  const [triggerResetData, setTriggerResetData] = useState<any>(false)
  const [isShowSpinner, setIsShowSpinner] = useState(true)
  const [open, setOpen] = useState(false)
  const [orderData, setOrderData] = useState<any>()
  const [pdfData, setPdfData] = useState<any>()
  const [dialogTitle, setDialogTitle] = useState<string>()
  const [isNewOrder, setIsNewOrder] = useState(false)
  const [isView, setisView] = useState(true)

  
  const onDialogClose = (isModified: boolean) => {
    setIsShowSpinner(false)
    setOpen(false)
    setTriggerResetData( Math.random())
  }
  const getBlob = (blob?:any) => {
    // console.log(blob, 'blob111')
    setEmailBlob(blob)
  }
  useEffect(() => {
    if (pageType==2 ) document.title = "Purchase Order Approve";
    else if (pageType==1 ) document.title = "Purchase Order";
    else  document.title = "Payable";
  }, [])

  const purchaseOrderDialog = <PurchaseOrderDialog isNewOrder={isNewOrder} onDialogClose={onDialogClose} orderData={orderData} isView={isView}/>
  const setButton = () => {
    let buttons = [
      {
        icon: 'file-text',
        text: 'Email',
        color: '#4CAF50',
        onClick: ( rowData: any) => {
          if (rowData.poStatusId == 1) {
            SweetAlertService.errorMessage('This order should be approved.')
            return
          }
          console.log(rowData)

          const personInEmail = {
            employeeEmail: getCookie('email'),
            customerEmail: rowData.suplier.email
          }
          // console.log(personInEmail)
          setRowData(personInEmail)
          const obj = {
            employeeEmail: getCookie('email'),
            // address: rowData.customer.address2 + ' ' + rowData.customer.address1,
            ...rowData
          }

          // PurchaseOrderPdf(obj)
          setPdfData(obj)
          PurchaseOrderPdf(obj, 'getBlob', getBlob)
          setIsEmailModalVisible(true)
        }
      },      
      {
        icon: 'file-add',
        text: pageType==2?'Edit':'View',
        color: '#1976D2', // Primary Blue
        onClick: ( rowData: any) => {
          setisView(pageType==2?false:true)
          setOpen(true)
          setOrderData(rowData)
          setDialogTitle(pageType==2?'Order Edit':'Order View')
          setIsNewOrder(false)
        }
      },
      {
        icon: 'contacts',
        text: 'Approve',
        color: '#673AB7',
        onClick: async (rowData: any) => {
          if (rowData.poStatusId !== 1) {
            SweetAlertService.errorMessage('This order\'s status is not awaiting approved.')
            return
          }
          const result = await SweetAlertService.confirmMessage()
          if (result) {
            ApiRequest({
              url: 'PurchaseOrder/ApprovePurchaseOrder?id=' + rowData.poId,
              method: 'put'
            }).then(_ => {
              setTriggerResetData(Math.random())
            })
          }
        }
      },      
      {
        icon: 'environment',
        text: 'ETA',
        color: '#FFC107',
        onClick: async (rowData: any) => {
          const result = await Swal.fire({
            title: "Input ETA",
            // type: "warning",
            html: 
              '<div style="margin-top: 20px;">' +
              '<label for="eta">ETA:&nbsp;&nbsp;</label>' +
              '<input type="date" id="eta" class="form-control" autofocus>' +
              '</div>'
            ,
            showCancelButton: true, // Display the cancel button
            focusCancel: true, // Allow focusing on the cancel button            
            onOpen: () => {
              const dateString = rowData?.deliveryDate; //本地时间
              const utcdate = new Date(dateString);//UTC时间
              utcdate.setDate(utcdate.getDate() + 1);
              // const localDate = new Date(date.toLocaleString());
                ( document.getElementById("eta")  as HTMLInputElement).valueAsDate =  new Date(utcdate.toISOString());
              },
              preConfirm: () => {
                const etaInput = document.getElementById("eta") as HTMLInputElement;
                const eta = etaInput.value;
            
                if (!eta) {
                  Swal.showValidationMessage("ETA is required");
                } else {
                  return {
                    eta: (document.getElementById("eta") as HTMLInputElement).valueAsDate,
                  };
                }
              }
            });
         
          if (result.value) {
            const etaDate = result.value.eta;
            if (etaDate instanceof Date) {
              const formattedDeliveryDate = etaDate.toISOString().split('T')[0];
              const resApi = await ApiRequest({
                // urlInfoKey: urlKey.PurchaseOrder+props.orderData?.poId+formValues.deliveryDate,
                url: 'PurchaseOrder/UpdateETA?id=' + rowData?.poId + '&ETA=' + formattedDeliveryDate,
                method: 'put'
              })
              if (resApi) {
                // console.log(result)
                await SweetAlertService.successMessage('Submit successfully')
                setTriggerResetData(Math.random())
              }
            }
          }
        }
      },
      {
        icon: 'file-text',
        text: 'Notes',
        color: '#F44336',
        onClick: async (rowData: any) => {

          const result = await SweetAlertService.inputConfirm({ type: 'text', title: 'Input Note', placeholder: 'Notes', defaultValue: rowData.notes })
          if (result) {
            ApiRequest({
              url: 'PurchaseOrder/UpdateNotes?id=' + rowData.poId + '&notes=' + result,
              method: 'put'
            }).then(_ => {
              SweetAlertService.successMessage()
              setTriggerResetData(Math.random())
            })
          }
        }
      },
      {
        icon: 'contacts',
        text: 'Finish',
        color: '#757575',
        onClick: async ( rowData: any) => {
          if (rowData.poStatusId !== 10) {
            SweetAlertService.errorMessage('This order has not been unloaded.')
            return
          }
          const result = await SweetAlertService.confirmMessage()
          if (result) {
            ApiRequest({
              url: 'PurchaseOrder/CompletePurchaseOrder?id=' + rowData.poId,
              method: 'put'
            }).then(_ => {
              setTriggerResetData(Math.random())
            })
          }
        }
      }
    ];
    if (pageType==1) {
      buttons = buttons.filter((e: any) => e.text !== "Finish" && e.text !== "Approve");
    }
    
    const splitIndex = Math.ceil(buttons.length / 2);
    const firstRowButtons = buttons.slice(0, splitIndex);
    const secondRowButtons = buttons.slice(splitIndex);
  
    const newCol = {
      title: 'Button',
      field: 'Buttons',
      editable: 'never',
      render: (rowData: any) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {firstRowButtons.map((button) => (
              <Button
                key={getRandomKey()}
                type="primary"
                style={{
                  marginBottom: '10px',
                  backgroundColor: button.color,
                  borderColor:'#FFFFFF',
                  color: '#FFFFFF',
                }}
                onClick={() => button.onClick(rowData)}
              >
                {button.text}
              </Button>
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {secondRowButtons.map((button) => (
              <Button
                key={getRandomKey()}
                type="primary"
                style={{
                  marginBottom: '10px',
                  backgroundColor:  button.color,
                  borderColor: '#FFFFFF',
                  color: '#FFFFFF',
                }}
                onClick={() => button.onClick(rowData)}
              >
                {button.text}
              </Button>
            ))}
          </div>
        </div>
      ),
    };
    return newCol;
  };
  const setPayableButton = () => {
    const newCol = {
      title: 'Payment',
      field: 'paymentStatus',
      editable: 'never',
      render: (rowData: any) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            key={getRandomKey()}
            type="primary"
            style={{
              backgroundColor: '#4CAF50',
              borderColor: '#FFFFFF',
              color: '#FFFFFF',
            }}
            onClick={async () => {
              let result = await editInvoice(rowData);
              if (result.isConfirmed) {
                let paymentStatus = result.value?.paymentStatus === "Paid" ? 1 : 0;
                let paymentDate = result.value?.paymentDate;
                ApiRequest({
                  url: `PurchaseOrder/MarkPaid?id=${rowData.poId}&paymentStatus=${paymentStatus}&invoiceNumber=${result.value?.invoiceNumber}&paymentDate=${paymentDate}`,
                  method: 'put'
                }).then(_ => {
                  SweetAlertService.successMessage()
                  setTriggerResetData(!triggerResetData)
                })
              }
            }}
          >
            Paid / Unpaid
          </Button>
        </div>
      ),
    };
    return newCol;
  };
  
  const actionButtons: any = [
    {
        icon: '', 
        tooltip: 'New Order',
        isFreeAction: true,
        onClick: (event: any, rowData: any) => {
          setisView(false);
          setOpen(true)
          setOrderData({})
          setDialogTitle('New Purchase Order')
          setIsNewOrder(true)
        }
      }
    ];
  
  const getRenderData = (data: any) => {
    const renderData: any = []
    data.map((row: any) => {
      renderData.push({
        ...row,
        supplierName: row.suplier?.suplierName,
        deliveryDate1: moment.utc(row.deliveryDate).local().format('DD/MM/YYYY'),
        // toLocalDate(row.deliveryDate),
        createdEmployeeName: (row.createdEmployee?.firstName || '') + ' ' + (row.createdEmployee?.lastName || '')
      })
    })
    return renderData
  }
  const setCol = () =>{
    let ret = PurchaseOrderColumnModel(pageType);
    if (pageType==3)
      ret.push(setPayableButton())
    else
      ret.push(setButton())
    return ret;
  }
  const editInvoice = async (row: any) =>{
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in "yyyy-mm-dd" format
    let dollarSignColorIndex = 0; // Index to cycle through colors
    const dollarSignColors = ['#e5bb4b', 'blue', 'red', 'green']; // Colors to cycle through for dollar sign
  
    const result = await Swal.fire({
      // iconHtml: '<i class="fas fa-file-invoice-dollar"></i>',
      title: `<b style="color: ${dollarSignColors[dollarSignColorIndex]};">$</b> Input Invoice Information`,
      // iconHtml: '<i class="fas fa-receipt"></i>',

      html: `
      <div class="swal-input-container">
        <label for="paymentDate" style="color: blue;">Date of Payment:</label>
        <input id="paymentDate" type="date" value="${currentDate}" required>
      </div>
        <div id="paymentStatus" class="swal-input-container" style="display: flex; align-items: center;">
          <input id="paymentStatusPaid" type="radio" name="paymentStatus" value="Paid"  style="margin-right: 2px;" required>
          <label for="paymentStatusPaid" style="color: red;margin-right: 2px">Paid</label>
          <input id="paymentStatusUnpaid" type="radio" name="paymentStatus" value="Unpaid"  style="margin-right: 2px;" checked required>
          <label for="paymentStatusUnpaid" style="color: green;margin-right: 2px">Unpaid</label>
        </div>
        <div class="swal-input-container">
          <label for="invoiceNumber" style="color: blue;">Invoice Number:</label>
          <input id="invoiceNumber" type="text" required>
        </div>
      `,
      onOpen: () => {
        console.log("pass row is:", row);
        if (row) {
          (document.getElementById("paymentDate") as HTMLInputElement).valueAsDate = row.paymentDate ? new Date(row.paymentDate) : new Date();
          (document.getElementById("invoiceNumber") as HTMLInputElement).value = row.invoiceNote;
          if (row.paid)
            (document.getElementById("paymentStatusPaid") as HTMLInputElement).checked  = true;
          else
            (document.getElementById("paymentStatusUnpaid") as HTMLInputElement).checked  = true;
        }
  
        // Disable invoice number input when "Unpaid" is selected
        // paymentStatus.addEventListener("change", () => {
        //   console.log(invoiceNum)
        //   invoiceNum.disabled = !paymentStatusPaid.checked;
        //   invoiceNum.value="";
        // });
  
        // Initially disable invoice number input if "Unpaid" is selected
        // invoiceNum.disabled = !paymentStatusPaid.checked;
       
      },     
      preConfirm: () => {
        // return new Promise((resolve, reject) => {
          const paymentDate = (document.getElementById('paymentDate') as HTMLInputElement)?.value;
          const paymentStatus = (document.querySelector('input[name="paymentStatus"]:checked') as HTMLInputElement)?.value;
          const invoiceNumber = (document.getElementById('invoiceNumber') as HTMLInputElement)?.value;
          return({ paymentDate, paymentStatus, invoiceNumber });
      },              
      showCancelButton: true,
      focusConfirm: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        container: 'custom-swal-container',
        confirmButton: 'custom-swal-confirm-button',
        cancelButton: 'custom-swal-cancel-button'
      }
    });
    console.log(result)
    return result;
  }
  const commonTablePageProps: CommonTablePagePropsInterface = {
    urlInfoKey: urlKey.PurchaseOrder,
    title: pageType === 1 ? 'Purchase Order' :
          pageType === 2 ? 'Purchase Order Approve' :
          pageType === 3 ? 'Payable' : 'Unknown Type',
    column: setCol(),
    mappingRenderData: (data: any) => getRenderData(data),
    triggerResetData: triggerResetData,
    // actionButtons: pageType==2 ? actionButtons : actionButtons.filter((row: any) => row.tooltip !== 'Approve' && row.tooltip !== 'Complete' && row.tooltip !== 'Draft'),
    actionButtons : pageType != 3 ? actionButtons : [],
    isNotEditable: true,
    isNotAddable: true,
    isShowSpinnerOnInit: isShowSpinner
  }

  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false)
  const [rowData, setRowData] = useState<Email>()
  const [emailBlob, setEmailBlob] = useState()

  return (
    <div>
      <CommonTablePage {...commonTablePageProps} />
      <CommonDialog title={dialogTitle} open={open} onDialogClose={onDialogClose} dialogContent={purchaseOrderDialog} />
      <EmailModal1 visible={isEmailModalVisible} onCancel={() => setIsEmailModalVisible(false)} rowData={rowData} blob={emailBlob} pdfData={pdfData} />
    </div>
  )
}
