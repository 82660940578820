import { TextField } from "@material-ui/core"
import React from "react"
import { convertToNZTime } from "../../../../services/lib/utils/helpers"




export default class QuotationItemDeletedColumn {
	static Column = [
        {
            title: 'Customer',
            field: 'customer',
        },
        {
            title: 'Product',
            field: 'product',
        },        
        {
            title: 'Price',
            field: 'price',
        },
        // {
        //     title: 'notes',
        //     field: 'notes',
        // },	  
        {
            title: 'MOQ',
            field: 'notes2',
        },
        {
            title: 'Deleted By',
            field: 'deletedOperator',
        },  
        {
            title: 'Deleted notes',
            field: 'deletedNotes',
        },  
        {
            title: 'Deleted at',
            field: 'deletedAt',
        },                 
    ]
}
