export default class BrandManagementColumnModel {
	static BrandManagementColumn = [
	  {
	    title: 'Brand name',
	    align: 'left',
	    field: 'brandName'
	  }
	]
}

