import productStickersPdfGenerate from './../../pages/static/pdf/product-stickers/product-stickers-pdf-generate'
import {JadCupImg, emptyImg} from '../../services/others/assets'
import {ApiRequest} from '../../services/api/api'
import {IStickerPdf} from '../../services/model/IStickerPdf'
import moment from 'moment'

// interface Data {
//     barcode: any;
//     productName: any;
//     logoType: any;
//     logoUrl: any;
//     logoBase64: string;
//     productCode: any;
//     packaging: string;
//     madeInNz: string;
//     testLogo: string;
//     manufactureDate: null;
//   }
  
const ProductDetailInPdf =  (boxes:any[],logoBase64:any) => {
  const productDetail =boxes.map( (res) => {
    let data= res;
  const barCode = data.barCode
  const product = data.product.productName
  const logoType = data.product.logoType
  const logoUrl = data.product.logoUrl
  const productCode = data.product.productCode
  const packaging = 'QTY:' + data.product?.baseProduct?.packagingType?.sleeveQty + 'PCS X '
                  + data.product?.baseProduct?.packagingType?.sleevePkt + 'PK'
  const madeInNz = (data.product.logoType != 3) ? 'Made In New Zealand' : ''
  // const manufactureDate = data.createdAt
  const manufactureDate:any = moment.utc(data.createdAt).local()
    return {
      barcode: barCode,
      productName: product,
      logoType: logoType,
      logoUrl: logoUrl,
      logoBase64: logoBase64,
      productCode: productCode,
      packaging: packaging,
      madeInNz: madeInNz,
      testLogo: JadCupImg,
      manufactureDate: manufactureDate
    }
  })
  return productDetail
}

const PdfStickService = (boxes:any[],logoBase64:any) => {
    // let barcode: any[] = [];
    // const productId="";
    const  ProductDetail = ProductDetailInPdf(boxes,logoBase64)
    productStickersPdfGenerate(ProductDetail, 'print')

}

export default PdfStickService
