export default class RecordTypeManagementColumnModel {
	static RecordTypeManagementColumn = [
	  {
	    title: 'Name',
	    align: 'left',
	    field: 'recordTypeName'
	  }
	]
}

