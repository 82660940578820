import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'
import { getRandomKey } from '../../../services/lib/utils/helpers'

const { Option } = Select

interface CommonEditFixedSelectComponentPropsInterface {
    options:[]
	label?: string
	required?: boolean
	rowDataKeyword?: any
	disabled?: boolean
	isOverrideSelectionOptions?: boolean
	width?: string
	exceptionOptionKey?: any
	propsFn: any
}

const CommonEditFixedSelectComponent: React.FC<CommonEditFixedSelectComponentPropsInterface> = (props: CommonEditFixedSelectComponentPropsInterface) => {
  const [data, setData] = useState<any[]>([]);

  const rowDataKeyword: string = props.rowDataKeyword 


  useEffect(() => {
    console.log(props.rowDataKeyword )
    console.log(props.propsFn.rowData)
    console.log(props.propsFn.rowData[rowDataKeyword])
    setData(props.options);
  }, [])

  const OptionComponent = (row: any) => (
    <Option value={row.id} key={row.id}>
      {
          row.name
      }
    </Option>
  )
/*
  const getOptionFromData = () => {
    const optionArr: any = []
    data?.map((row: any) => {
      if (props.exceptionOptionKey) {
        if (row[props.exceptionOptionKey]) {
          optionArr.push(OptionComponent(row))
		  console.log(row)
        }
      } else {
        optionArr.push(OptionComponent(row))
      }
    })
   
    return optionArr
  }*/

  return (
	  <Form.Item
		  key={"select" + getRandomKey()}
		  label={props.label}
		  name={"select"}
		  initialValue={props.propsFn.rowData[rowDataKeyword]}
		  rules={[{ required: props.required, message: props.label + ' is required!' }]}
	  >
		  <Select
			  allowClear
			  disabled={props.disabled}
			  showSearch
			  style={{ width: props.width || '12rem' }}
			  placeholder=""
			  value={props.propsFn.rowData[rowDataKeyword] || undefined}
			  optionFilterProp="children"
			  onChange={(value: any) => {
                    const row: any = data.filter(item => item.id === value)[0]
					  props.propsFn.onRowDataChange({
						  ...props.propsFn.rowData,
						  [rowDataKeyword]: value 
					  })
				  }
			  }
			  filterOption={(input: any, option: any) => {
	            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
			  }}
			  getPopupContainer={node => node.parentNode} // Block for one hour
			  onInputKeyDown={(e: any) => {
				  if (e.key === 'Enter') {
					  e.preventDefault()
					  return
				  }
			  }}
		  >
            {data?.map((option) => (
                <Option key={option.id} value={option.id}>
                {option.id}{option.name}
                </Option>
            ))}
		  </Select>
	  </Form.Item>
  )
}

export default CommonEditFixedSelectComponent
