import React, { useEffect, useState } from 'react'
import CommonTablePage from '../../../../../components/common/common-table/common-table-page'
import {urlKey, urlType} from '../../../../../services/api/api-urls'
import { ApiRequest } from '../../../../../services/api/api'
import CommonDialog from '../../../../../components/common/others/common-dialog'
import DispatchLocationTable from './dispatch-location-table/dispatch-location-table'
import {Button, Col, Input, Row ,Select , Form, InputNumber, Checkbox} from 'antd'
import SweetAlertService from '../../../../../services/lib/utils/sweet-alert-service'
import CommonForm, {ItemElementPropsInterface} from '../../../../../components/common/common-form/common-form'
import {commonFormSelect, getSelectOptions} from '../../../../../components/common/common-form/common-form-select'
import {DispatchOrderDetailsColumnModel} from './dispatch-order-details-column-model'
import { getCookie } from 'react-use-cookie'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'


const DispatchOrderDetailsTable = (props: {orderId: any, dispatchData?: any, isDispatchUpdate?: boolean, onDialogClose: any}):any => {
  const [form] = Form.useForm() 
  const [orderId, setOrderId] = useState<any>()
  const [tableData, setTableData] = useState<any>()
  const [requestData, setRequestData] = useState<any>([])
  const [open, setOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState<string>()
  const [orderProductData, setOrderProductData] = useState()
  const [selectedRows, setSelectedRows] = useState<any>()
  // const [selectAsDeliveredRows, setSelectAsDeliveredRows] = useState<any>()
  // const [selectAsCompleteRows, setSelectAsCompleteRows] = useState<any>()
  const [courierOptions, setCourierOptions] = useState([])
  const [formRef, setFormRef] = useState<any>()
  const [orderProductWithDetails, setOrderProductWithDetails] = useState<any>([])
  const [triggerResetData, setTriggerResetData] = useState<any>(false)
  const [palletTypes, setPalletTypes] = useState<any>([]);
  const [needPalletInfo, setNeedPalletInfo] = useState<boolean>(true);  
  const [palletType, setPalletType] = useState<number>();
  const [quantity, setQuantity] = useState<number>();  

  const onDialogClose = (selectedDataInfo: any) => {
    setOpen(false)
    if (selectedDataInfo?.details) {
      console.log(selectedDataInfo)
      setRequestDataFormSelectedRows(selectedDataInfo)
      setOrderProductWithDetails(getCurrentOrderProductWithDetails(selectedDataInfo))
      setTriggerResetData(!triggerResetData)
    }
  }

  const getCurrentOrderProductWithDetails = (selectedDataInfo: any) => {
    const restedArr = orderProductWithDetails.filter((row: any) => row.orderProductId !== selectedDataInfo.orderProductId)
    return [...restedArr, selectedDataInfo]
  }

  const dispatchLocationTable = <DispatchLocationTable onDialogClose={onDialogClose} isUpdate={props.isDispatchUpdate} dispatchData={props.dispatchData} orderProductData={orderProductData} orderId={orderId} orderProductWithDetails={orderProductWithDetails} />

  useEffect(() => {
    getSelectOptions(urlKey.Courier).then(res => setCourierOptions(res))
    getSelectOptions(urlKey.PlateType).then(res => {
      let pp:any=[];
      res.map((ele:any)=>{
        pp.push({
          value:ele.plateTypeId,
          label:ele.plateTypeName
        })
      })
      setPalletTypes(pp)
    })
  }, [])

  useEffect(() => {
    if (!orderId && props.orderId) {
      setOrderId(props.orderId)
    }
  }, [orderId, props.orderId])

  const setRequestDataFormSelectedRows = (selectedDataInfo: any) => {
    const filteredRequestData: any = requestData.filter((item: any) => item.orderProductId !== selectedDataInfo.orderProductId)
    setRequestData([...filteredRequestData, ...selectedDataInfo.details])
  }

  const onCreateDispatch = () => {

    // return;
    if (needPalletInfo && (palletType==null || quantity==null)){
      SweetAlertService.errorMessage('Please fill the pallet form.')
      return;
    }
    if (!requestData ||requestData?.length==0){
      SweetAlertService.errorMessage('Please select products for this order.')
      return;      
    }
    if (requestData?.length) {
      const requestDataLightfy = requestData.map((e:any)=>{
        return {
          boxId:e.boxId,
          productId:e.productId,
          quantity:e.quantity
        }
      })
      ApiRequest({
        url: 'Dispatching/AddDispatching',
        data: {
          orderId: orderId,
          dispatchingDetails: requestDataLightfy,
          employeeId:parseInt(getCookie('id')),
          palletInfo:needPalletInfo?{
            plateTypeId:palletType,
            quantity:quantity
          }:null
        },
        method: 'post'
      }).then((res: any) => {
        console.log(res)
        props.onDialogClose(true, res.data.data)
      })
    }
  }

  const onUpdateDispatch = () => {
    // console.log(requestData)
    if (requestData?.length) {
      ApiRequest({
        url: 'Dispatching/UpdateDispatching',
        data: {
          dispatchId: props.dispatchData.dispatchId,
          dispatchingDetails: requestData,
          palletType:needPalletInfo?palletType:null,
          palletQuantity:needPalletInfo?quantity:null          
        },
        method: 'put'
      }).then((res: any) => {
        props.onDialogClose(true, props.dispatchData.dispatchId)
      })
    }
  }

  const onCompleteDispatch = async () => {
    if (formRef) {
      formRef.submit()
      const formValues = await formRef.validateFields()
      if (formValues) {
        console.log(formValues)
        console.log(props.dispatchData)
        console.log(tableData)
        const requestValues: any = {
          ...formValues,
          dispatchId: props.dispatchData.dispatchId,
          orderProducts: tableData.map((row: any) => ({
            orderProductId: row.orderProductId,
            deliveredQuantity: props.dispatchData.dispatchingDetails.reduce(((a: any, c: any) => a + (c.productId === row.productId ? c.quantity : 0)), 0),
            delivered: row.selectedQty >= ((props.dispatchData.dispatchingDetails.filter((d:any) => d.productId === row.productId)).quantity) ? 1 : 0
          }))
        }
        ApiRequest({
          url: 'Dispatching/CompleteDispatching',
          method: 'put',
          data: requestValues
        }).then(_ => {
          props.onDialogClose(true)
        })
      }
    } else {
      SweetAlertService.errorMessage('Please fill the form.')
    }
  }

  const getRenderData = async () => {
    console.log(orderProductWithDetails)
    const result = await ApiRequest({
      url: 'SalesOrder/GetOrderById?id=' + orderId,
      method: 'get',
    })
    console.log(result.data.data.orderProduct)
    const orderProduct = result.data.data.orderProduct;
    // await orderProduct.map( async (row: any) => {
    //   // console.log(row)
    //   const result2 = await ApiRequest({
    //     url: 'StockMonitor/GetStockInfoByProductId?productId=' + row.productId,
    //     method: 'get',
    //   })
    //   // row.inStock = result.data.data.productInventoryInfo.productInStock;
    //   // row.pendingOrderQuantity = result.data.data.productInventoryInfo.pendingOrderQuantity;
    //   // row.pendingWorkOrderQuantity = result.data.data.productInventoryInfo.pendingWorkOrderQuantity;
    //   // row.pendingWarehouseQuantity = result.data.data.productInventoryInfo.pendingWarehouseQuantity;
    //   row.productInventoryInfo = result2.data.data.productInventoryInfo;
    // })
    for (let row of orderProduct)
    {
      const result2 = await ApiRequest({
        url: 'StockMonitor/GetStockInfoByProductId?productId=' + row.productId,
        method: 'get',
      })
      row.productInventoryInfo = result2.data.data.productInventoryInfo;
    }
    console.log(orderProduct)
    orderProduct.map( (row: any) => {
      console.log(row)
      row.quantity = row.quantity * row.product.baseProduct.packagingType.quantity;
      row.delivered = row.delivered ? 1 : 0;
      row.selectedQty = 
      orderProductWithDetails.filter((item: any) => 
        item.orderProductId === row.orderProductId)[0]?.details?.reduce((a: any, c: any) => a + c.quantity, 0) 
        ||
        props.dispatchData?.dispatchingDetails.filter((item: any) => 
        item.productId === row.productId).reduce((a: any, c: any) => a + c.quantity, 0);
    }) ;
    const renderData: any = orderProduct?orderProduct:[];
    console.log(renderData)
    setTableData(renderData)
    return renderData
  }

  const actionButtons: any = props.dispatchData && !props.isDispatchUpdate ?
    [
      // {
      //   icon: 'ghost', //Button attr of Ant design (danger, ghost)
      //   tooltip: 'Select as delivered',
      //   isFreeAction: false,
      //   onClick: (event: any, rowData: any) => {
      //     if (selectedRows?.length) {
      //       setSelectAsDeliveredRows(selectedRows)
      //       SweetAlertService.successMessage()
      //     }
      //   }
      // },
      // {
      //   icon: 'ghost', //Button attr of Ant design (danger, ghost)
      //   tooltip: 'Select as complete',
      //   isFreeAction: false,
      //   onClick: (event: any, rowData: any) => {
      //     if (selectedRows?.length) {
      //       setSelectAsCompleteRows(selectedRows)
      //       SweetAlertService.successMessage()
      //     }
      //   }
      // }
    ] :
    [
      {
        icon: 'ghost', //Button attr of Ant design (danger, ghost)
        tooltip: 'Locations',
        isFreeAction: false,
        onClick: async (event: any, rowData: any) => {
          if (rowData.deliveredQuantity>=rowData.quantity){
            SweetAlertService.errorMessage("Aleardy deliveried");
            return
          }
            
          if (props.isDispatchUpdate) {
            const result = await ApiRequest({
              urlInfoKey: urlKey.OrderProduct,
              type: urlType.GetById,
              dataId: rowData.orderProductId
            })
            if (result) {
              setOpen(true)
              setOrderProductData(result.data.data)
            }
          } else {
            setOpen(true)
            setOrderProductData(rowData)
          }
          setDialogTitle(props.isDispatchUpdate 
              ? 'Locations - Update:'+ rowData.product.productCode
              : 'Locations - Create:'+ rowData.product.productCode)
        }
      }
    ]

  const onSelectionChange = (rows: any) => {
    setSelectedRows(rows)
  }

  const formItems: ItemElementPropsInterface[] | any = [
    {name: 'courierId', label: 'Courier', rules: [{required: true}], inputElement: commonFormSelect(urlKey.Courier, courierOptions)},
    {name: 'trackingNo', span:10, label: 'Tracking No', rules: [{required: true}], inputElement: <Input />},
  ]

  const onFormChange = (changedValues: any, newValues: any, form: any) => {
    if (!formRef) {
      setFormRef(form)
    }
  }

  const onFormBlur = (form: any) => {
    if (!formRef) {
      setFormRef(form)
    }
  }
  const onChangeNeedPalletInfo= (e: CheckboxChangeEvent) => {
    setNeedPalletInfo(e.target.checked)
  };  
  const onChangePalletType= (value: number) => {
    console.log(value)
    setPalletType(value)
  }; 
  const onChangeQuantity= (value: any) => {
    console.log(value)    
    setQuantity(value)
  }; 
  return orderId ? (
    
    <div>
      <Form form={form} style={{background:"LightGray"}}>
        <Row>
          <Col span={6}>
            <Form.Item name="quantity" label="" >
              <Checkbox defaultChecked style={{color:"Blue"}} onChange={onChangeNeedPalletInfo}>Pallet Information</Checkbox>
            </Form.Item>
          </Col>           
          {needPalletInfo&&<Col span={6}>
            <Form.Item name="palletType" label="Pallet Type" rules={[{ required: true, message: 'You need to input' }]}>
              <Select
                // defaultValue="lucy"
                style={{ width: 120 }}
                // onChange={handleChange}
                options={palletTypes}  onChange={onChangePalletType}
              />
            </Form.Item>
          </Col>}
          {needPalletInfo&&<Col span={6}>
            <Form.Item name="quantity" label="Quantity" rules={[{ required: true, message: 'You need to input' }]}>
              <InputNumber style={{ width: 120 }} id="quantity" onChange={onChangeQuantity}></InputNumber >
            </Form.Item>
          </Col>}
         
        </Row>
      </Form>  
      <CommonTablePage
        urlInfoKey={urlKey.City}
        title="Order Details"
        column={DispatchOrderDetailsColumnModel()}
        mappingRenderData={(data: any) =>getRenderData()}
        actionButtons={actionButtons}
        isNotEditable={true}
        isNotDeletable={true}
        isNotAddable={true}
        isEnableSelect={false}
        triggerResetData={triggerResetData}
        onSelectionChange={onSelectionChange}
      />

      {props.dispatchData && !props.isDispatchUpdate ? <CommonForm items={formItems} onFormChange={onFormChange} onFormBlur={onFormBlur} /> : null}
      <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '1rem'}}>
        <Button
          onClick={props.dispatchData ? (props.isDispatchUpdate ? onUpdateDispatch : onCompleteDispatch) : onCreateDispatch }
          type="primary"
        >{props.dispatchData ? (props.isDispatchUpdate ? 'Update dispatch' : 'Complete') : 'Create dispatch'}</Button>
      </div>
      <CommonDialog
        title={dialogTitle}
        open={open}
        onDialogClose={onDialogClose}
        dialogContent={dispatchLocationTable}
      />
    </div>
  ) : null
}

export default DispatchOrderDetailsTable
