import React, { useState, useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete'; // import a different icon from material-ui library
import CommonTablePage from '../../../components/common/common-table/common-table-page';
import { urlKey } from '../../../services/api/api-urls';
import MonitorProductColumnModel from './monitor-product-column-model'
import CommonDialog from '../../../components/common/others/common-dialog';
import ProductSelectPage from './product-select-page';

import { getRandomKey } from '../../../services/lib/utils/helpers';
import { Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import './tab.css';
import SweetAlertService from '../../../services/lib/utils/sweet-alert-service';
import { ApiRequest } from '../../../services/api/api';
import { getUserId } from '../../../services/lib/utils/auth.utils';




const MonitorProductPage = (props: { }): any => {
  const [triggerResetData, setTriggerResetData] = useState<any>(false);
  const [open, setOpen] = useState<any>(false);
  const [productType, setProductType] = useState<any>(null);
  const [typeArray, setTypeArray] = useState<any[]>([]); // State to hold the array of types
  // Fetch types when component mounts

  // const typeArray = [
  //   { type: 1, color: 'white', name: "B/W Cups" }, // light red
  //   { type: 2, color: '#white', name: "Colour Cups" }, // light green
  //   { type: 3, color: '#white', name: "Custom Cups" }, // light blue
  //   { type: 4, color: '#white', name: "Other Cups" }, // light orange
  //   { type: 5, color: '#white', name: "GSP Paper" }, // light purple
  //   { type: 6, color: '#white', name: "Local Purchase" }, // light red
  //   { type: 7, color: '#white', name: "Biopak" }, // light green
  //   { type: 8, color: '#white', name: "Bonson" }, // light blue
  //   { type: 9, color: '#white', name: "Jane's Trading" }, // light orange
  //   { type: 10, color: '#white', name: "Fortune" }, // light purple
  //   // { type: 11, color: '#white', name: "Confoil / Pacific Carton" }, // light purple
  //   { type: 11, color: '#white', name: "J&H" }, // light purple
  //   { type: 12, color: '#white', name: "Offshore Purchase" }, // light purple
  //   { type: 13, color: '#white', name: "EcoH" }, // light purple
  //   { type: 14, color: '#white', name: "Eco-Forward" }, // light purple
  //   { type: 15, color: '#white', name: "Qian Lin" }, // light purple
  //   { type: 16, color: '#white', name: "Rong Feng" }, // light purple
  // ];    //MonitorProductGroup

  const [tabIndex, setTabIndex] = useState(1);
  const fetchTypes = async () => { ApiRequest({
    url: `/MonitorProductGroup/GetAllMonitorProductGroup`,
    method: 'get',
    // data: [{ productId: productId, type: 0 }] // Example of sending data in request body
  }).then(response => {
    // Handle success response (optional)
    debugger
    let data = response.data.data;
    data = data.filter((typeObj: any) => typeObj.active);
    data = data.sort((a: any, b: any) => a.type - b.type);
    console.log('API call successful:', data);
    setTypeArray(data);
    setTabIndex(data[0].type);
  }).catch(error => {
    // Handle error (optional)
    console.error('API call failed:', error);
    // Implement error handling as per your application's requirements
  });
  };
  
  const handleTabChange = (key: any) => {
    setTabIndex(Number(key));
  };  
  useEffect(() => {
    document.title = "Inventory Monitoring";
    fetchTypes();
  }, []);

  const handleClick = (event: any, rowData: any, typeObj: any) => {
    // Do something with the buttonType argument
    setProductType(typeObj)
    setOpen(true)
    // console.log(`Button ${buttonType} clicked`);
  };
  const onDialogClose = (isModified: boolean) => {
    setOpen(false)
    if (isModified) {
      setTriggerResetData(getRandomKey())
    }
  }
  const customTooltip =(typeObj:any)=>(
    // <div style={{  color: typeObj.color }}>
    <div>
      Product {typeObj.name} Setting
    </div>
  );


  // const actionButtons = typeArray.map((typeObj) => ({
  //   icon: 'ghost',
  //   tooltip:customTooltip(typeObj),
  //   isFreeAction: true,
  //   onClick: (event: any, rowData: any) => handleClick(event, rowData, typeObj),
  // }));

  const actionButtons2 = [
    {
      icon: 'ghost',
        tooltip:customTooltip("Product Setting"),
        isFreeAction: true,
        onClick: (event: any, rowData: any) => handleClick(event, rowData, typeArray[tabIndex - 1]),
    },
    {
      icon: 'ghost',
      tooltip: 'Change MSL',
      onClick: async (event:any, rowData:any) => {
        // Do save operation
        // const res = await SweetAlertService.inputConfirm ("You want to delete?")
        const res = await SweetAlertService.inputConfirm({
          type: 'number',
          title: 'Change MSL',
          textLabel: 'MSL: ',
          placeholder: 'Quantity',
          defaultValue: rowData.productInventoryInfo.productMsl
      });
      
        if (!res) return;

        let qty = parseInt(res);
        if (qty == rowData.productInventoryInfo.productMsl){
          await SweetAlertService.errorMessage ("New MSL  (Minimum Stock Level) must be different from the current one.");
          return
        }
        // [{"productId":78,"type":2},{"productId":79,"type":2}]
        // `https://example.com/api/changeMsl?productId=${productId}&employeeId=${employeeId}&msl=${msl}`;
        ApiRequest({
          url: `Product/ChangeMsl?productId=${rowData.productId}&employeeId=${getUserId()}&msl=${qty}`,
          method: 'PUT',
          // data: [{productId: rowData.productId, type:0}]
        }).then(_ => {
          // setTriggerResetData(!triggerResetData)
          setTriggerResetData(getRandomKey())
        })          
      }
    },    
    {
      icon: 'Danger',
      tooltip: 'Delete',
      onClick: async (event:any, rowData:any) => {
        // Do save operation
        const res = await SweetAlertService.confirmMessage("You want to delete?")
        if (!res) return;
        // [{"productId":78,"type":2},{"productId":79,"type":2}]
        ApiRequest({
          url: 'ProductType/AddMonitorProducts',
          method: 'POST',
          data: [{productId: rowData.productId, type:0}]
        }).then(_ => {
          // setTriggerResetData(!triggerResetData)
          setTriggerResetData(getRandomKey())
        })          
      }
    }
  ];
  const rateToPercentChange=(rate:number) => {
    return ((rate ) * 100).toFixed(2) + '%';
  }

  const commonTablePageProps = {
    // urlInfoKey: urlKey.Product,
    getAllUrl: 'StockMonitor/GetMonitor',
    title: 'Inventory Monitoring',
    triggerResetData: triggerResetData,
    column: MonitorProductColumnModel().filter((e: any) => e.field !== "isLowStock"),
    actionButtons: actionButtons2,
    isNotAddable: true,
    isNotDeletable: true,
    isNotEditable: true,
    mappingRenderData: (data: any) => {
      let newData = data.map((row: any) => ({
        ...row,
        pendingOrderQuantity: row.productInventoryInfo.pendingOrderQuantity,
        pendingWorkOrderQuantity: row.productInventoryInfo.pendingWorkOrderQuantity,
        productInStock: row.productInventoryInfo.productInStock,
        productMsl: row.productInventoryInfo.productMsl,
        suggestedQuantity: row.productInventoryInfo.suggestedQuantity,
        pendingOrder: row.productInventoryInfo.pendingOrderQuantity,
        rate: rateToPercentChange(row.changeRate),
      }));
      newData = newData.filter((rowData:any) => rowData.type === tabIndex);
      //add trim  to product name
      newData = newData.map((rowData:any) => {
        return {
          ...rowData,
          productName: rowData.productName.trim(),
        };
      })
      //sort by product name
      newData = newData.sort((a:any, b:any) => a.productName.localeCompare(b.productName));
      return newData;
    },
    rowStyle: (rowData: any) => {
      // if (rowData.type) {
      //   return { backgroundColor: getTypeColor(rowData.type) };
      // } else {
      //   return {}; // return empty object for all other rows (no background color)
      // }
      const productInStock = rowData.productInStock ?? 0;
      const poQty = rowData.poQty ?? 0;
      const pendingWorkOrderQuantity = rowData.pendingWorkOrderQuantity ?? 0;
      const pendingOrder = rowData.pendingOrder ?? 0;
      const productMsl = rowData.productMsl;
    
      console.log('Debug: rowData', rowData);
      console.log('Debug: Calculated Value', productInStock + poQty + pendingWorkOrderQuantity - pendingOrder);
    
      return {
        backgroundColor:
        productInStock + poQty + pendingWorkOrderQuantity - pendingOrder < productMsl
          ? '#FFEBEE' 
          : 'transparent',
      };
            
    },
    Options:{
      headerStyle: {
        backgroundColor: '#01579b',
        color: '#FFF'
      }
    }   
  };

  
  return (
    <div >
      <Tabs className="custom-tabs" activeKey={String(tabIndex)} onChange={handleTabChange}>
        {typeArray.map((typeObj) => (
          <TabPane tab={typeObj.type === 0 ? "Tab" : typeObj.name.toUpperCase()} key={typeObj.type}>
            <CommonTablePage
              {...commonTablePageProps}
            />
          </TabPane>
        ))}
      </Tabs>
      <CommonDialog
        title={'Choose products'}
        open={open}
        onDialogClose={onDialogClose}
        dialogContent={<ProductSelectPage onDialogClose={onDialogClose} typeObj={productType} typeArray={typeArray}/>}
      />
    </div>
  );
};

export default MonitorProductPage;
