import React, { useState,useEffect } from 'react'
import CommonTablePage from '../../../components/common/common-table/common-table-page'
import { urlKey } from '../../../services/api/api-urls'
import ProductSelectColumnModel from './product-select-column'

import SweetAlertService from '../../../services/lib/utils/sweet-alert-service'
import { ApiRequest } from '../../../services/api/api'

const ProductSelectPage = (props: { onDialogClose: any, typeObj: any ,typeArray:any}): any => {
  const [triggerResetData, setTriggerResetData] = useState<any>(false)
  useEffect(() => {
    // document.title = "Product Management";
    console.log(props.typeObj)
   }, [])
   const onSelectionChange = (rows: any) => {
    console.log(rows)
    // setSelectedRows(rows)
  } 
  // const typeArray = [
  //   { type: 1, color: 'red' },
  //   { type: 2, color: 'green' },
  //   { type: 3, color: 'blue' },
  //   { type: 0, color: 'black' },
  // ];   
  const getTypeColor = (type:number) => {
    const typeObj = props.typeArray?.find((obj:any) => obj.type === type);
    return typeObj ? typeObj.color : '';
  };  
  const getTypeName = (type:number) => {
    const foundType = props.typeArray?.find((item:any) => item.type === type);
    if (foundType) {
      return foundType.name;
    } else {
      return "";
    }
  }  
  const actionButtons: any = [
    {
      icon: '',
      tooltip: 'Confirm',
      isFreeAction: false,
      onClick: async (event: any, rowData: any) => {
        const confirmResult = await SweetAlertService.confirmMessage()
        if (confirmResult) {
          console.log(rowData)
          const request = rowData.map((product:any) => {
            return {
              productId: product.productId,
              type: props.typeObj.type // Set the type to whatever value is needed for your use case
            };
          });
          ApiRequest({
            url: 'ProductType/AddMonitorProducts',
            method: 'POST',
            data: request
          }).then(_ => {
            // setTriggerResetData(!triggerResetData)
            props.onDialogClose(true)
          })            
          return
        }
      }
    }
  ];
  return (
    <>
      <CommonTablePage
        urlInfoKey={ urlKey.Product }
        getAllUrl={'Product/GetMonitorProduct'}
        title="Products"
        isEnableSelect={true}
        isNotDeletable={true}
        isNotEditable={true}
        isNotAddable={true}
        triggerResetData={triggerResetData}
        onSelectionChange={onSelectionChange}
        actionButtons={actionButtons}
        mappingRenderData={(data: any) => {
            const newData = data.map((row: any) => ({
              ...row,
              typeName:getTypeName(row.type)
            }))
            return newData;
          }}
          // add console log in selectionProps
          selectionProps = {(rowData:any) => {
            return {
              disabled: (rowData?.type!=null && rowData?.type!=0),
              color: 'primary'
            };
          }}
        rowStyle={(rowData:any) => {
          if (rowData.type) {
            return { color: getTypeColor(rowData.type) };
          } else {
            return {}; // return empty object for all other rows (no background color)
          }
        }}
        column={ ProductSelectColumnModel() }
      />

    </>
  )
}

export default ProductSelectPage
