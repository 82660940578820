import React, { useState } from 'react'
import { Image, Popover } from 'antd'
import { ApiRequest } from '../../../../services/api/api'
import getImage from '../../common-form/common-image'
import { nbsStr } from '../../../../services/lib/utils/helpers'
import { Card } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment'
import './i-tip-product.css'

const { Meta } = Card;

interface Iprops{
    id:number,
    label:string,
    isNotCutting?: boolean,
    baseProductId?:number
}

const ItipsForProduct: React.FC<Iprops> = (props) => {
  const [visible, setVisible] = useState(false)
  const [getData, setGetData] = useState<any>({})
  const [stockData, setStockData] = useState<any>(null)
  const [orderData, setOrderData] = useState<any>(null)
  const [workOrderData, setWorkOrderData] = useState<any>([])
  const [purchaseOrder, setPurchaseOrder] = useState<any>([])  
  // const [productId, setProductId] = useState<any>([]) 
  const handleVisibleChange = async (visible: boolean) => {
    let productId;
    if (props.baseProductId){
      await ApiRequest({
        url: 'Product/GetProductByBaseProduct?id=' + props.baseProductId,
        method: 'get',
        isShowSpinner: false
      }).then(res => {
        if (res.data.data[0].productId && res.data.data[0].manufactured == 1){
          // setProductId(res.data.data.productId)
          productId = res.data.data[0].productId
        }
        else{
          setVisible(false)
        }
          
      }).catch(reason => {
        // setGetData({productName: 'Inactive product'})
        setVisible(false)
        return        
      })
    }
    if (!productId && !props.id) return;
    if (!productId) productId =props.id;

    setVisible(visible)
    productId && ApiRequest({
      url: 'Product/GetProductById?id=' + productId,
      method: 'get',
      isShowSpinner: false
    }).then(res => {
      setGetData(res.data.data)

    }).catch(reason => {
      setGetData({productName: 'Inactive product'})
    })
    productId && ApiRequest({
      url: 'StockMonitor/GetStockInfoByProductId?productId=' + productId,
      method: 'get',
      isShowSpinner: false
    }).then(res => {
      setStockData(res.data.data)

    }).catch(reason => {
      // setStockData({productName: 'Inactive product'})
    })    
    productId && ApiRequest({
      url: 'Product/GetRecentOrderByProd?id=' + productId,
      method: 'get',
      isShowSpinner: false
    }).then(res => {
      setOrderData(res.data.data)

    }).catch(reason => {
      // setStockData({productName: 'Inactive product'})
    }) 
    productId && ApiRequest({
      url: 'WorkOrder/GetWorkOrderyProductId?productId=' + productId,
      method: 'get',
      isShowSpinner: false
    }).then(res => {
      setWorkOrderData(res.data.data)
    }).catch(reason => {
      // setStockData({productName: 'Inactive product'})
    })        
    productId && ApiRequest({
      url: 'StockMonitor/GetPoQtyByProductId?productId=' + productId,
      method: 'get',
      isShowSpinner: false
    }).then(res => {
      setPurchaseOrder(res.data.data)
    }).catch(reason => {
      // setStockData({productName: 'Inactive product'})
    })             
  }

  const getRowRender = (name: any, rowData: any) => (
    <div style={{wordWrap: 'break-word', display: 'flex'}}>
      <div style={{minWidth: '50px'}}>
        {name&&<strong>{name}:</strong>}
      </div>
      <div>
        {stockData?
        <span style={{wordBreak: 'break-all'}}>&nbsp;&nbsp;{rowData}</span>
        :<LoadingOutlined />}
      </div>
    </div>
  )

  const content = (
    <Card title={getData?.productCode || 'Product'} bordered={true} style={{ width: 650 }}
    >
      
    <div style={{minWidth: '530px'}}>
      {getImage(getData?.images)}
      {getRowRender('FullName', getData?.productName)}
      {getRowRender('Customer', getData?.customer && getData.customer.company)}
      <hr></hr>
      {getRowRender('Units Per Pack/Box', getData?.baseProduct?.packagingType?.packagingTypeName)}
      {getRowRender('In Stock', stockData?.productInventoryInfo?.productInStock )}
      {workOrderData.length > 0 && <hr />}
      {getRowRender('Approved Work Order', stockData?.productInventoryInfo?.pendingWorkOrderQuantity )}
        <div>
          {workOrderData.map((item: any, index: number) => (
            <div key={item.workOrderId} >
              {getRowRender(
                `Work Order ${index + 1}`,
                `QTY: ${item.quantity} 
                  ${item.workOrderStatus?.workOrderStatusName === 'Progressing'|| item.workOrderStatus?.workOrderStatusName === 'Approved' ? 
                    `, ${item.actionDto?.actionName}` : ''}
                    ${item.workOrderStatus?.workOrderStatusName !== 'Completed' &&
                      item.workOrderStatus?.workOrderStatusName !== 'Progressing'&& 
                      item.workOrderStatus?.workOrderStatusName !== 'Approved'  ? `, ${item.workOrderStatus?.workOrderStatusName}` : ''}`
              )}
            </div>
          ))}
        </div> 
      {workOrderData.length > 0 && <hr />}   
      {getRowRender('In Packaging', stockData?.productInventoryInfo?.pendingWarehouseQuantity )}
      {getRowRender('Customer Order', stockData?.productInventoryInfo?.pendingOrderQuantity )}
      {/* {getRowRender('Description', getData?.description)} */}
      {/* {getRowRender('MinOrderQty', getData?.minOrderQuantity)}
      {getRowRender('MarginOfError', getData?.marginOfError)}
      {getRowRender('SemiMsl', getData?.semiMsl)} */}
      {getRowRender('Msl', getData?.productMsl)} 
      <hr></hr>
      {getRowRender('Latest Order',orderData?.recentDate?moment.utc(orderData?.recentDate).local().format('DD/MM/YYYY'):"None")}      
      {getRowRender('Last 6 months Sold', orderData?.recentOrderQty)} 
      {purchaseOrder?.length > 0 && <hr />}   
      {purchaseOrder?.length > 0 && getRowRender('Purchase Details', "")}         
      <div>
          {purchaseOrder?.map((item: any, index: number) => (
            <div key={item.workOrderId} >
              {getRowRender(
                `${item.poNo}`,
                item.quantity+(item.eta ? " (ETA: " + moment(item.eta).format('DD/MM/YYYY') + ")" : "")+(item.notes ? " (Notes: " + item.notes + ")" : "")
              )}
            </div>
          ))}
        </div>   
    </div>
    </Card>
  )

  return (
    <Popover
      content={content}
      placement="left"
      // title={<h4 style={{textAlign: 'center'}}>{getData?.productCode || 'Product'}</h4>}
      trigger="hover"
      open={visible}
      onOpenChange={handleVisibleChange}
      // style={{ zIndex: 100 }}
    >
      {nbsStr(props.label, props.isNotCutting)}
    </Popover>
  )
}

export default ItipsForProduct
