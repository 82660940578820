import React from 'react'

import AwaitingDispatchList from './awaiting-dispatch-list'

const PickupList = () => {

  return (
    <div>
      <AwaitingDispatchList pickupFlag={1} />
    </div>
  )
}

export default PickupList
