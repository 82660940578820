import React, { useState } from 'react'
import CommonTablePage from '../../../../components/common/common-table/common-table-page'
import RawMaterialManagementColumnModel from './raw-material-management-column-model'
import { urlKey } from '../../../../services/api/api-urls'
import './rm.css'
import { concat } from 'lodash'
import SweetAlertService from '../../../../services/lib/utils/sweet-alert-service'
import { ApiRequest } from '../../../../services/api/api'
import { getRandomKey } from '../../../../services/lib/utils/helpers'
import { getUserId } from '../../../../services/lib/utils/auth.utils'


const RawMaterialManagementPage = (): any => {
  const [triggerResetData, setTriggerResetData] = useState<any>(false)  
  
  return (
    <div className="rm">
    <CommonTablePage
      urlInfoKey={urlKey.RawMaterial}
      title="Raw Material Management"
      triggerResetData={triggerResetData}
      isNotDeletable={true}
      actionButtons={[
        {
          icon: 'ghost', //Button attr of Ant design (danger, ghost)
          tooltip: 'Delete',
          isFreeAction: false,
          onClick: async (event: any, rowData: any) => {
            const resultInput = await SweetAlertService.deleteConfirm();
            if (resultInput.dismissed) return;
            const [name, reason] = Array.isArray(resultInput.value) ? resultInput.value : [];         
            ApiRequest({ url: `RawMaterial/DeleteRawMaterial?id=${rowData.rawMaterialId}&userId=${getUserId()}&userName=${name}&Notes=${reason}`, method: 'delete'})               
            .then((res: any) => {
              SweetAlertService.successMessage()
              setTriggerResetData(getRandomKey())
            })
          }
        }
      ]}      
      column={RawMaterialManagementColumnModel.RawMaterialManagementColumn}
      // mappingRenderData={(data: any) => getRenderData(data)}
    />
    </div>
  )
}

export default RawMaterialManagementPage
