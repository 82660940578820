import React, { useEffect, useState } from 'react'
import MonitorProductGroupManagementColumnModel from './MonitorProductGroupManagementColumnModel'
import CommonTablePage, { CommonTablePagePropsInterface } from '../../../components/common/common-table/common-table-page'
// import MonitorProductGroupManagementDialog from './MonitorProductGroupManagementDialog'
import { urlKey } from '../../../services/api/api-urls'

const MonitorProductGroupManagementPage: React.FC = () => {
  const [triggerResetData, setTriggerResetData] = useState(false)
  const [open, setOpen] = useState(false)
  const [type, setType] = useState<number | undefined>(undefined)
  const [groupName, setGroupName] = useState('')

  // const monitorProductGroupManagementDialog = <MonitorProductGroupManagementDialog type={type} />

  useEffect(() => {
    document.title = 'Monitor Product Group Management';
  }, []);

  const onDialogClose = () => {
    setOpen(false)
    setTriggerResetData(!triggerResetData)
  }

  // const actionButtons: any = [
  //   {
  //     icon: 'ghost',
  //     tooltip: 'Modify Monitor Product Group',
  //     isFreeAction: false,
  //     onClick: (event: any, rowData: any) => {
  //       setOpen(true)
  //       setType(rowData.type)
  //       setGroupName(rowData.name)
  //     }
  //   }
  // ]

  const commonTablePageProps: CommonTablePagePropsInterface = {
    urlInfoKey: urlKey.MonitorProductGroup,
    title: 'Monitor Product Group Management',
    column: MonitorProductGroupManagementColumnModel.MonitorProductGroupManagementColumn,
    mappingUpdateData: (dataDetail: any) => {
      dataDetail.type = parseInt(dataDetail.type)
      return dataDetail
    },
    triggerResetData: triggerResetData,
    // actionButtons: actionButtons
  }

  return (
    <div>
      <CommonTablePage {...commonTablePageProps} />
      {/* <CommonDialog title={'Monitor Product Group Management --- ' + groupName} open={open} onDialogClose={onDialogClose} dialogContent={monitorProductGroupManagementDialog} /> */}
    </div>
  )
}

export default MonitorProductGroupManagementPage