import React, {useEffect, useState} from 'react'
import {Popover} from 'antd'
import {ItipsForCustomerRequest} from '../../../../services/others/i-tips-services'
import { ApiRequest } from '../../../../services/api/api'
import getImage from '../../common-form/common-image'
import ItipsForProduct from '../product'

interface Iprops{
    id:number,
    label:string,
}

const IProdtipsForCustomer:React.FC<Iprops> = (props) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [getData, setGetData] = useState<any>()
  const hide = () => {
    setVisible(false)
  }

  const handleVisibleChange = (visible:boolean) => {
    setVisible(visible)
    props.id && ApiRequest({
      url: 'Product/GetCustomizeProdByCustId?id=' + props.id,
      method: 'get',
      isShowSpinner: false
    }).then(res => {
      setGetData(res.data.data)
      console.log(res.data.data)
    }).catch(reason => {
      // setStockData({productName: 'Inactive product'})
    })      
  }
  const getImgSrc = (item:any) => {
    let imgObj:any;
    imgObj = item?.images?.split('---');
    // console.log(imgObj)
    if (imgObj?.length > 0 && imgObj[0].trim() !== "") {
      // console.log(JSON.parse(imgObj[0])?.url)
      return JSON.parse(imgObj[0])?.url;
    }
    return null;
  }
  const UserContent = (
    <div style={{width: '600px'}}>
      <div style={{wordWrap: 'break-word', display: 'flex'}}>
        {!getData
          && <div>
            <div style={{ minWidth: '200px' }}>
              <strong>Loading... ...</strong>
            </div>
          </div>
        }
        <ul>
          {getData?.map((item: any,index:number) => 
             
              <li key={index} style={{display:'flex'}}>
                <img style={{width:'20px',marginRight:'5px'}} src={getImgSrc(item)}></img>
                {/* <div>{item.productCode}</div> */}
                <div><ItipsForProduct id={item.productId} isNotCutting={true} label={item.productName}></ItipsForProduct></div>
              </li>
            )}
        </ul>        
      </div>

    </div>
  )
  const content = UserContent
  return (
    <Popover
      content={content}
      title={props.label}
      trigger="hover"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <b>{props.label}</b>
    </Popover>
  )
}

export default IProdtipsForCustomer
