import React from 'react'
import { commonFilterFn, getRandomKey } from '../../../../../services/lib/utils/helpers'
import { Button, Popover } from 'antd'
import ItipsForProduct from '../../../../../components/common/i-tips/product'
import moment from 'moment'
import { AlertOutlined } from '@ant-design/icons'

export const myfiler=(
  filterValue:any,
  rowData:any
) => {
  const filter = filterValue.replace(/-/g, '\u2011').trim().slice(0,-1);
  for (let ele of rowData){
    const prodName = ele.product?.productName.replace(/-/g, '\u2011').trim();
    if (prodName.indexOf(filter) >=0) return true;
  }
  return false;
}  
const WorkOrderManagementColumnModel = (props: any) => {
  const barcodeFilterFn = (inputValue: any, rowData: any) => {
    const filter = inputValue.trim().toLowerCase();
    return rowData.boxInfo?.some((box: any) => box.barCode.toLowerCase().includes(filter)) || false;
  };  
  return [
    {
      title: 'Work Order No.',
      field: 'serialNumber',
      // defaultSort: 'asc'
    },      
    {
      title: 'Product Code',
      field: 'productCode',
      // defaultSort: 'asc'
      customFilterAndSearch: (inputValue: any, rowData: any) => commonFilterFn(inputValue.replace(/^\s*|\s*$/g,""), rowData.productCode.replace(/^\s*|\s*$/g,""))
    },
    {
      title: 'Product',
      field: 'productFilter',
      sorting: false,
      customFilterAndSearch: (inputValue: any, rowData: any) => commonFilterFn(inputValue.replace(/^\s*|\s*$/g,""), rowData.product.productName.replace(/^\s*|\s*$/g,"")),
      // customFilterAndSearch:(        filterValue:any,
      //   rowData:any) =>myfiler(
      //   filterValue,
      //   rowData
      // ),
      render: (rowData: any) => rowData.product ? <ItipsForProduct id={rowData.productId} label={rowData.product.productName} /> : null
    },
    {
      title: 'Qty',
      field: 'quantity',
      render: (rowData: any) => {
        return (
          <div>
            {rowData.quantity}
            {
              rowData.workOrderStatusId === -1 ? (
                <div key={getRandomKey()}>
                  <Button
                    type={'primary'}
                    onClick={() => {
                      props.updateQuantity(rowData)
                    }}
                  >Edit quantity</Button>
                </div>
              ) : null
            }
          </div>
        )
      }
    },
    // {
    //   title: 'Order Type',
    //   field: 'orderType',
    //   sorting: false,
    //   lookup: { 1: 'Normal order', 2: 'Raw to semi', 3: 'Semi to products' },
    //   customFilterAndSearch: (term: any, rowData: any) => term && term.length ? term.includes(rowData.orderTypeId?.toString()) : true,
    //   render: (rowData: any) => rowData.orderType?.orderTypeName
    // },
    {
      title: 'Source',
      field: 'workOrderSource',
      sorting: false,
      lookup: { 1: 'Sales Order', 2: 'Manual', 3: 'Inventory', 4: 'Redo', 5: 'Makeup' },
      customFilterAndSearch: (term: any, rowData: any) => term && term.length ? term.includes(rowData.workOrderSourceId?.toString()) : true,
      render: (rowData: any) => rowData.workOrderSource?.workOrderSourceName
    },
    {
      title: 'Created By',
      field: 'createdEmployeeName',
    },
    {
      title: 'Status',
      field: 'workOrderStatusId1',
      sorting: false,
      defaultFilter: ['-1', '1', '2'],
      lookup: { '-1': 'New', 1: 'Appr', 10: 'Comp', 0: 'Canc' },
      customFilterAndSearch: (term: any, rowData: any) => term && term.length ? term.includes(rowData.workOrderStatusId1?.toString()) : true,
      render: (rowData: any) => {
        console.log(rowData)
        let name = rowData.workOrderStatus?.workOrderStatusName
        if (rowData.workOrderStatusId==2) 
          name = "Approved"
        return (
          <div>
            {name}
            {
              rowData.workOrderStatusId === -1 ? (
                <div key={getRandomKey()}>
                  <Button danger
                    type="primary"
                    onClick={() => {
                      props.updateApproveStatus(rowData)
                    }}
                  >Approve</Button>
                </div>
              ) : null
            }
          </div>
        )
      }
    },
    {
      title: 'Change Comments',
      field: 'workOrderStatus',
      sorting: false,
      // defaultFilter: ['-1', '1', '2'],
      // lookup: { '-1': 'New', 1: 'Appr', 2: 'Prog', 10: 'Comp', 0: 'Canc' },
      customFilterAndSearch: (term: any, rowData: any) => term && term.length ? term.includes(rowData.workOrderStatusId?.toString()) : true,
      render: (rowData: any) => {
        return (
          <div>
            {
              <div key={getRandomKey()}>
                <Button
                  type="primary"
                  onClick={() => {
                    props.updateStatusComments(rowData)
                    console.log(rowData)
                  }}
                >Change</Button>
              </div>
            }
          </div>
        )
      }
    },
    {
      title: 'Action',
      field: 'actionId',
      sorting: false,
      lookup: { 1: 'Printing', 2: 'Die-Cutting', 3: 'Production', 4: 'Packaging' },
      render: (rowData: any) => rowData.actionDto?.actionName
    },
    {
      title: 'Created At',
      field: 'createdAt1',
      render: (rowData: any) => moment.utc(rowData.createdAt1).local().format('DD/MM/YYYY'),
    },
    {
      title: 'REQ Date',
      field: 'requiredDate1',
      render: (rowData: any) => moment.utc(rowData.requiredDate1).local().format('DD/MM/YYYY'),
    },
    {
      title: 'Urgent',
      field: 'urgent',
      initialEditValue: 0,
      align: 'left',
      sorting: false,
      type: 'numeric',
      lookup: {0: 'No', 1: 'Yes'},
      render: (rowData: any) => {
        return (
          <div>
            {
              rowData.urgent == 1 && 
                <div>
                <Popover
                  content={rowData.urgentNote}
                  title={<div style={{ textAlign: 'center' }}>Urgent</div>}>
                  <AlertOutlined style={{ textAlign: 'center' ,fontSize: '24px', color: 'red' }} />
                </Popover>
                </div>
            }            
            {
              ![10, 0].includes(rowData.workOrderStatusId) ? (
                <div key={getRandomKey()}>
                  <Button 
                    danger={rowData.urgent}
                    onClick={() => {
                      props.updateUrgent(rowData)
                    }}
                  >Change</Button>
                </div>
              ) : null
            }
          </div>
        )
      }
    },
    {
      title: 'Comments',
      field: 'comments',
    },
    {
      title: 'Raw Material Batch No',
      field: 'rawMaterialNote',
    },    
  ]
}


const TraceabilityColumnModel = (props: any) => {
  // Custom filter and search function for barcode
  const barcodeFilterFn = (inputValue: any, rowData: any) => {
    const filter = inputValue.trim().toLowerCase();
    return rowData.boxInfo?.some((box: any) => box.barCode.toLowerCase().includes(filter)) || false;
  };  
  return [
    {
      title: 'Work Order No.',
      field: 'serialNumber',
      // defaultSort: 'asc'
    },    
    {
      title: 'Product Code',
      field: 'productCode',
      // defaultSort: 'asc'
      customFilterAndSearch: (inputValue: any, rowData: any) => commonFilterFn(inputValue.replace(/^\s*|\s*$/g,""), rowData.productCode.replace(/^\s*|\s*$/g,""))
    },
    {
      title: 'Product',
      field: 'productName',
      sorting: false,
      customFilterAndSearch: (inputValue: any, rowData: any) => commonFilterFn(inputValue.replace(/^\s*|\s*$/g,""), rowData.product.productName.replace(/^\s*|\s*$/g,"")),
      // customFilterAndSearch:(        filterValue:any,
      //   rowData:any) =>myfiler(
      //   filterValue,
      //   rowData
      // ),
      render: (rowData: any) => rowData.productName ? <ItipsForProduct id={rowData.productId} label={rowData.productName} /> : null
    },
    {
      title: 'Order Status',
      field: 'status',
    },    
    {
      title: 'Order Qty',
      field: 'quantityOrder',
    },
    {
      title: 'QTY InStock',
      field: 'quantityInStock',
    },
    {
      title: 'QTY Manufactured',
      field: 'quantityMade',
    },
    {
      title: 'QTY Dispatched',
      field: 'quantityDispatched',
    },      
    {
      title: 'QTY Hold',
      field: 'quantityHold',
    },        
    {
      title: 'Box Info',
      field: 'boxInfo',
      customFilterAndSearch: barcodeFilterFn,

      // render: (rowData: any) =><ItipsForProduct id={rowData.productId} label={rowData.productName} />
      render: (rowData: any) => (
        <div>
          <Popover
            content={
              rowData.boxInfo?.map((row: any, index: any) => (
                <div key={index}>
                  <b>Barcode:</b>&nbsp;{row?.barCode}&nbsp;
                  <b>Quantity:</b>&nbsp;{row?.quantity}&nbsp;
                  <b>Localtion:</b>&nbsp;{row?.status}&nbsp;
                </div>
              ))
            }
            title="Details"
          >
            <Button type="primary" ghost>Quantity: {rowData?.boxInfo?.length}</Button>
          </Popover>
          {}
        </div>
      )      
    },

    {
      title: 'Order Created At',
      field: 'createdAt1',
      render: (rowData: any) => moment.utc(rowData.createdAt1).local().format('DD/MM/YYYY'),
    },
    {
      title: 'Manufacture Date',
      field: 'madedAt',
      render: (rowData: any) => {
        return rowData.madedAt ? moment.utc(rowData.madedAt).local().format('DD/MM/YYYY') : '';
      },
    },    
    {
      title: 'Raw Material Batch No',
      field: 'rawMaterialNote',
      render: (rowData: any) => {
        return (
          <div>
            <div>{rowData.rawMaterialNote}</div>
            <div>
            {
              <Button
                type={'primary'}
                onClick={() => {
                  props.updateRMNote(rowData)
                }}
              >Update Notes</Button>
            }              
            </div>
          </div>
        )
      }      
    },    
  ]
}

export default WorkOrderManagementColumnModel
export { TraceabilityColumnModel };
