import { TextField } from "@material-ui/core"
import React from "react"
import { convertToNZTime } from "../../../services/lib/utils/helpers"

export const renderRelatedBaseProduct = (rowData: any) => {
	console.log(rowData)
	return (rowData?.baseProductRawMaterial.length > 0 && (
		<div style={{ display: 'flex', flexWrap: 'wrap' }}>
			{rowData?.baseProductRawMaterial.map((row: any, index: number) => (
				<div style={{ flexBasis: '50%', padding: '5px' }} key={index}>
					<span
						title={`Total Products: ${row.product.length} - ${row.product.map((p: any) => p.productCode).join(', ')}`}
					>
						{row.productCode}&nbsp;&nbsp;
					</span>
				</div>
			))}
		</div>
	))
}

export const renderOrderProduct = (rowData: any) => {
	console.log(rowData)
	return (rowData.suplierRawMaterial.length>0 && (
		<div>
			{
				rowData.suplierRawMaterial.map((row: any) => (
					<div>
						<b>Supplier:</b>{row.suplier.suplierName}&nbsp;&nbsp;<b>Supplier Code:</b>{row.suplierProductCode}&nbsp;&nbsp;<b>UnitPrice:</b>{row.unitPrice}
					</div>
				))
			}
		</div>
	))
}
const isCellEditable = (cellData:any,rowData:any) => {
	if (!rowData) return true;	
	if (rowData.manufactured === 1) {
	  return false;
	}
	return true;
  };
const myfiler=(
	filterValue:any,
	rowData:any
  ) => {
	// console.log(rowData)
	const filter = filterValue.replace(/-/g, '\u2011').trim();
	const rawMaterialCode = (rowData.rawMaterialCode?.replace(/-/g, '\u2011')?.trim())?.toUpperCase();
	
	if (rawMaterialCode?.indexOf(filter.toUpperCase()) >=0) return true;
	const rawMaterialName = (rowData.rawMaterialName?.replace(/-/g, '\u2011')?.trim())?.toUpperCase();
	// console.log(rawMaterialName)
	if (rawMaterialName?.indexOf(filter.toUpperCase()) >=0) return true;  
	return false;
  }
export default class DeletedRawMaterialColumn {
	static RawMaterialManagementColumn = [
	  {
	    title: 'Raw material code',
	    align: 'left',
	    field: 'rawMaterialCode',
		editable: isCellEditable,     
		editComponent: (props:any) => (
			<TextField
				value={props.value}
			fullWidth={true}
			multiline={true}
			onChange={e => props.onChange(e.target.value)}
			/>)	,
		customFilterAndSearch:(        filterValue:any,
			rowData:any) =>myfiler(
			filterValue,
			rowData
			)   			
	  },
	  {
		  title: 'Raw material name',
		  align: 'left',
		  field: 'rawMaterialName',
		  editable: isCellEditable,     
		  editComponent: (props:any) => (
			<TextField
				value={props.value}
			fullWidth={true}
			multiline={true}
			onChange={e => props.onChange(e.target.value)}
			/>),
		customFilterAndSearch:(        filterValue:any,
			rowData:any) =>myfiler(
			filterValue,
			rowData
			)  			
	  },
	  // {
	  //   title: 'Alarm Limit',
	  //   align: 'left',
		//   filtering: false,
	  //   type: 'numeric',
		// editable: isCellEditable,     
	  //   field: 'alarmLimit',
		// width: "10%" 	  
	  // },
	  {
	    title: 'OutSourced Product',
	    align: 'left',
	    field: 'manufactured',
		editable: 'never',
		type: 'numeric',
		lookup: {0: 'No', null: 'No', 1: 'Yes'}		
	  },	  
	  {
	    title: 'Supplier Info',
	    align: 'left',
	    field: 'supllierInfo',
		editable: false,     
		render: (rowData:any) => renderOrderProduct(rowData),
	  },
    {
      title: 'Deleted At',
      field: 'deletedAt',
      render: (rowData:any) => convertToNZTime(rowData.deletedAt),
    }, 
    {
      title: 'Deleted By',
      field: 'deletePerson',
      render: (rowData:any) => rowData.deletePerson+":"+rowData.deleteEmployee?.firstName,
    }    
    , 
    {
      title: 'Deleted Note',
      field: 'deleteNote',
    }      
	  // {
	  //   title: 'Base Product',
	  //   align: 'left',
	  //   field: 'BaseProduct',
		// editable: false,     
		// render: (rowData:any) => renderRelatedBaseProduct(rowData),
	  // }	  
	]
}
