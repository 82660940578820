import React, {useEffect, useState} from 'react'
import {getAllZoneRequest} from "../../../services/others/relocate-boxex-services";
import { Button } from 'antd';
import { PlusOutlined, MinusOutlined, EditOutlined } from '@ant-design/icons';
import { ApiRequest } from '../../../services/api/api'
import SweetAlertService from '../../../services/lib/utils/sweet-alert-service'
import Swal from 'sweetalert2';
import { PrintOutlined } from '@material-ui/icons';
interface Shelf {
    shelfId: string;
    shelfCode: string; 
    cell: [];
}

const ShelfSelectorEdit = (props:{productValue?:number,materialValue?:number, data:any,getProductsDetailByShelf?:any,getMaterialsDetailByShelf?:any,getPlatesDetailByShelf?:any,filterString?:any,fetchShelfData?:any}) =>{
    const [selectedCell, setSelectedCell] =useState()

    const backgroundStyleChang = (count:number,k?:string) =>{
        // debugger
        const styleOfShelf = {height:30, width:50,border:'1px solid black',marginBottom:10, backgroundColor: 'white'}
        if (k && k!='') {
            styleOfShelf.backgroundColor = 'red'; // Red color override if k is provided
            return styleOfShelf;
        }
        if (count >= 1) {
            styleOfShelf.backgroundColor = '#CCFF99'; // Light green
        } else if (count >= 0.8) {
            styleOfShelf.backgroundColor = '#FFFF99'; // Yellow
        } else if (count >= 0.3) {
            styleOfShelf.backgroundColor = '#FFCC99'; // Light orange
        } else {
            styleOfShelf.backgroundColor = '#FFCCCC'; // Light red
        }
        return styleOfShelf;
    }

    const letterToNumber = (shelfCodeX:string) => { 
        const letter = shelfCodeX[0]; 
        var numberAAA = letter.charCodeAt(0) - 'A'.charCodeAt(0) + 1; 
        if(numberAAA >= 5){
            numberAAA = numberAAA + 1;
        }
        return numberAAA;
    }

    const NumberToletter = (shelfCodeX:string) => { 
        const numberPart = shelfCodeX.substring(1); 
        return parseInt(numberPart, 10);
    }   

    const incrementString = (shelfCodeX:string) => { 
        //console.log(shelfCodeX);
        const prefix = shelfCodeX.substring(0, 1);  
        const numberPart = shelfCodeX.substring(1);
      
        // 将数字部分转换为数字，加1，然后转换回字符串
        let incrementedNumber = parseInt(numberPart, 10) + 1;
        let incrementedNumberStr = incrementedNumber.toString();
       
        if (incrementedNumber < 10) {
          incrementedNumberStr = '0' + incrementedNumberStr;
        }
       
        return prefix + incrementedNumberStr;
    }

    const getNextLetter = (char:string) => {
        const charCode = char.charCodeAt(0);
        if (char === 'z' || char === 'Z') {
            return String.fromCharCode(charCode - 25);
        } else {
            return String.fromCharCode(charCode + 1);
        }
    };
    const handleClick = async (shelfCodeX:string) => {
        let resultInput = await Swal.fire({
            iconHtml: '<i class="fas fa-edit" style="color: #4285F4;"></i>',
            title: 'Input Shelf Details',
            html: `
                <div style="margin-bottom: 10px;">
                    <label for="rows" style="color: #4285F4; font-weight: 500;">Number of Rows:</label>
                    <input id="rows" type="number" value="3" style="border: 1px solid #D9D9D9; border-radius: 4px; padding: 6px 12px; font-size: 14px; width: 60px;" required>
                </div>
                <div>
                    <label for="cols" style="color: #4285F4; font-weight: 500;">Number of Columns:</label>
                    <input id="cols" type="number" value="2" style="border: 1px solid #D9D9D9; border-radius: 4px; padding: 6px 12px; font-size: 14px; width: 60px;" required>
                </div>
            `,
            preConfirm: () => {
                const numRows = (document.getElementById('rows') as HTMLInputElement)?.value;
                const numCols = (document.getElementById('cols') as HTMLInputElement)?.value;
                return { numRows, numCols };
            },
            showCancelButton: true,
            focusConfirm: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
                container: 'custom-swal-container',
                confirmButton: 'custom-swal-confirm-button',
                cancelButton: 'custom-swal-cancel-button'
            },
            confirmButtonColor: '#007bff', // You can customize the confirm button color
            cancelButtonColor: '#dc3545', // You can customize the cancel button color
            confirmButtonText: 'Confirm', // You can customize the confirm button text
            cancelButtonText: 'Cancel', // You can customize the cancel button text
        });
    
        // console.log(resultInput);
        // debugger
        if (!resultInput.isConfirmed) return;
    
        if (shelfCodeX) {
            const resPlateBox = await ApiRequest({
                url: 'Shelf/AddOrUpdate',
                method: 'put',
                data: {
                    shelfCode: shelfCodeX,
                    position: NumberToletter(shelfCodeX),
                    totalRows: Number(resultInput?.value?.numRows),
                    totalCols: Number(resultInput?.value?.numCols),
                    zoneId: letterToNumber(shelfCodeX)
                }
            }).then((res: any) => {
                // window.location.reload()
                // debugger
                props.fetchShelfData()
            })
        }
    };
    
    const handleClickEdit = async (ZoneCode:string) => {
        let resultInput = await Swal.fire({
            iconHtml: '<i class="fas fa-edit" style="color: #4285F4;"></i>',
            title: 'Input Shelf Details',
            html: `
                <div style="margin-bottom: 10px;">
                    <label for="shelf" style="color: #4285F4; font-weight: 500;">Number of Shelves:</label>
                    <input id="shelf" type="number" value="20" style="border: 1px solid #D9D9D9; border-radius: 4px; padding: 6px 12px; font-size: 14px; width: 60px;" required>
                </div>
                <div style="margin-bottom: 10px;">
                    <label for="rows" style="color: #4285F4; font-weight: 500;">Number of Rows:</label>
                    <input id="rows" type="number" value="7" style="border: 1px solid #D9D9D9; border-radius: 4px; padding: 6px 12px; font-size: 14px; width: 60px;" required>
                </div>
                <div>
                    <label for="cols" style="color: #4285F4; font-weight: 500;">Number of Columns:</label>
                    <input id="cols" type="number" value="2" style="border: 1px solid #D9D9D9; border-radius: 4px; padding: 6px 12px; font-size: 14px; width: 60px;" required>
                </div>
            `,
            preConfirm: () => {
                const numShelves = (document.getElementById('shelf') as HTMLInputElement)?.value;
                const numRows = (document.getElementById('rows') as HTMLInputElement)?.value;
                const numCols = (document.getElementById('cols') as HTMLInputElement)?.value;
                return {numShelves, numRows, numCols };
            },
            showCancelButton: true,
            focusConfirm: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
                container: 'custom-swal-container',
                confirmButton: 'custom-swal-confirm-button',
                cancelButton: 'custom-swal-cancel-button'
            },
            confirmButtonColor: '#007bff', // You can customize the confirm button color
            cancelButtonColor: '#dc3545', // You can customize the cancel button color
            confirmButtonText: 'Confirm', // You can customize the confirm button text
            cancelButtonText: 'Cancel', // You can customize the cancel button text
        });
    
        // console.log(resultInput);
        // debugger
        if (!resultInput.isConfirmed) return;
    
        if (ZoneCode) {
            const resPlateBox = await ApiRequest({
                url: 'Shelf/UpdateShelfZone',
                method: 'put',
                data: {
                    // shelfCode: ZoneCode,
                    ZoneCode: ZoneCode,
                    NumOfShelves:Number(resultInput?.value?.numShelves),
                    totalRows: Number(resultInput?.value?.numRows),
                    totalCols: Number(resultInput?.value?.numCols),
                }
            }).then((res: any) => {
                // window.location.reload()
                // debugger
                props.fetchShelfData()
            })
        }
    };
    
    const handleClickDel = async (shelfId:string) => {
        const result = await SweetAlertService.confirmMessage('Sure to delete the last shelf?')
        if (result) {
            const getAllShelf = await ApiRequest({
                url: 'Shelf/GetAllShelf',
                method: 'get'
            }).then(async (res: any) => {
                // console.log(res.data.data)
                const match = res.data.data.find((item:Shelf) => item.shelfId === shelfId);
                if(match){
                    const getShelfX = await ApiRequest({
                        url: 'Shelf/GetShelfByCodeOrById?shelfId=' + shelfId,
                        method: 'get'
                    }).then(async (res2: any) => {
                        console.log(res2.data.data)
                        if(res2.data.data.cell.length == 0){
                            const resPlateBox = await ApiRequest({
                                url: 'Shelf/DeleteShelf?id=' + shelfId ,
                                method: 'delete'
                            }).then((res3: any) => {
                                console.log(res3.data)
                                // window.location.reload()
                                props.fetchShelfData()
                            })
                        }else{
                            SweetAlertService.errorMessage("Shelves with Plates cannot be deleted."); 
                        } 
                    })  
                }
            })     
        }
    };

    const onClick = (code:string,isProduct:boolean,isMaterial:boolean) =>{
        if (isProduct || isMaterial) {
            if(isProduct) {
                props.getProductsDetailByShelf(code)
            }
            if(isMaterial){
                props.getMaterialsDetailByShelf(code)
            }
        } else {
            props.getPlatesDetailByShelf(code)
        }
    }

    const onClickHandler = (shelfCode:string,Id:any) =>{
        props.filterString(shelfCode)
        setSelectedCell(Id)
     }
    return(
        <div>
            {
                props.data.map((zone:any,index:number) => {
                    return(
                        <div style={{display:'flex', flexDirection:'row'}} key={index}>
                            <div style={{height:30, width:50,border:'1px solid black',marginBottom:10,textAlign:'center'}}>
                                <b>{zone.zoneCode}</b>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', textAlign: 'center'}}>
                                {
                                    zone.shelf.map((shelf:any,index2:number) =>{
                                        let productFromInner
                                        let isProductShow = false
                                        let materialFromInner
                                        let isMaterialShow = false
                                        let numsOfSelectedProduct = 0
                                        let numsOfSelectedMaterial = 0
                                        // debugger
                                        // console.log(shelf,shelf.availableCellCount/(shelf.totalCols*shelf.totalRows))
                                        return(
                                            // <div style={selectedCell === shelf.shelfId? backgroundStyleChang(shelf.availableCellCount/(shelf.totalCols*shelf.totalRows),'selected'):backgroundStyleChang(shelf.availableCellCount/(shelf.totalCols*shelf.totalRows))} key={index2} onClick={props.filterString? ()=>onClickHandler(shelf.shelfCode,shelf.shelfId):()=>onClick(shelf.shelfCode,isProductShow,isMaterialShow)}>
                                            <div
                                                style={
                                                    selectedCell === shelf.shelfId
                                                        ? backgroundStyleChang(shelf.availableCellCount / (shelf.totalCols * shelf.totalRows), 'selected')
                                                        : backgroundStyleChang(shelf.availableCellCount / (shelf.totalCols * shelf.totalRows),(isProductShow || isMaterialShow) ? 'show' : '' )
                                                }
                                                key={index2}
                                                onClick={
                                                    props.filterString
                                                        ? () => onClickHandler(shelf.shelfCode, shelf.shelfId)
                                                        : () => onClick(shelf.shelfCode, isProductShow, isMaterialShow)
                                                }
                                            >
                                      
                                                {shelf.shelfCode}
                                                {shelf.cell.filter((res:any)=> res.plate !== null).map((cell:any,index:number) => {
                                                    return(
                                                        <React.Fragment key={index} >
                                                            {
                                                                cell.plate?.box.map((box:any) =>{
                                                                    // console.log(box.product,zone.zoneCode,'box')
                                                                    // console.log(box)
                                                                    if(box.product.productId === props.productValue){
                                                                        productFromInner = box.product.productName
                                                                        isProductShow = true
                                                                        numsOfSelectedProduct +=1
                                                                    }
                                                                    return
                                                                })
                                                            }
                                                            {
                                                                cell.plate?.rawMaterialBox.map((material:any,index:number) =>{
                                                                    // console.log(material, zone,index)
                                                                    if(material.rawMaterial.rawMaterialId === props.materialValue){
                                                                        materialFromInner = material.rawMaterial.rawMaterialCode
                                                                        isMaterialShow = true
                                                                        numsOfSelectedMaterial +=1
                                                                    }
                                                                    return
                                                                })
                                                            }
                                                        </React.Fragment>
                                                    )
                                                })}
                                                 {/* <b>{zone.zoneCode}</b> */}
                                                {isProductShow && <b style={{ cursor: 'pointer', backgroundColor: 'red', borderRadius: '50%', padding: '0.2rem' }}>{numsOfSelectedProduct}</b>}
                                                {isMaterialShow &&<b style={{ cursor: 'pointer', backgroundColor: 'red', borderRadius: '50%', padding: '0.2rem' }}>{numsOfSelectedMaterial}</b> }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={async () => handleClick(incrementString(zone.shelf[zone.shelf.length - 1].shelfCode))}
                                style={{ margin: '0px 0px 0px 10px' }} // 自定义padding
                            > 
                            </Button>
                            <Button
                                type="primary"
                                icon={<MinusOutlined />}
                                onClick={() => handleClickDel(zone.shelf[zone.shelf.length - 1].shelfId)}
                                style={{ margin: '0px 0px 0px 10px' }} // 自定义padding
                            > 
                            </Button>
                            <Button
                                type="primary"
                                icon={<EditOutlined />} // Changed to EditOutlined
                                onClick={() => handleClickEdit(zone.zoneCode)}
                                style={{ margin: '0px 0px 0px 10px' }} // 自定义padding
                            > 
                            </Button>                        
                        </div>
                    )
                })
            }
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                    // debugger
                    const nextLetter = getNextLetter(props.data.at(-1).zoneCode);
                    console.log("Next letter:", nextLetter); // Log the next letter
                    // debugger; // Pause execution and open debugger
                    handleClickEdit(nextLetter);
                }}
                style={{ margin: '0px 0px 0px 10px' }} // 自定义padding
            > 
            </Button>
        </div>
    )
}

export default ShelfSelectorEdit
