import { Button, Input, Modal, Form, Row, Col } from 'antd'
import React, { useState, useEffect } from 'react'
import { LoginRequest } from '../../../services/others/login-services'
import { getCookie, setCookie } from 'react-use-cookie'
import { LockOutlined, ReloadOutlined } from '@ant-design/icons'

const OfflineAlertModal = (props: {
  modalVisible: boolean;
  closeModal: any;
}) => {
  // const [modalVisible, setModalVisible] = useState(true)

  const onOk = (values: any) => {
    console.log('Received values of form: ', values)
    values.type = '1' //staff
    values.username = getCookie('userName')
    LoginRequest(values)
      .then((res) => {
        console.log(res)
        localStorage.setItem('CHECK_USER_OFFLINE', JSON.stringify(false))
        props.closeModal()
        setCookie('lockedFlag', '0')
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleLogout = () =>{
    // Clear any session data and redirect the user to the login page
    sessionStorage.clear();
    localStorage.clear();
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    window.location.href = "/";
  }
  return (
    <Modal
      width={400}
      destroyOnClose={true}
      visible={props.modalVisible}
      closable={false}
      footer={null}
      style={{ backgroundColor: '#f0f2f5', borderRadius: '8px', color: '',textShadow: ''}}
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <LockOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
        <h2 style={{ marginLeft: '16px',textShadow: 'none' }}>You are locked</h2>
      </div>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onOk}
      >
        <Form.Item
          label="Enter your password to unlock"
          style={{ color: '',textShadow: 'none'}}
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Button block type="primary" htmlType="submit">
                Ok
              </Button>
            </Col>
            <Col span={12}>
              <Button type="ghost"  onClick={handleLogout} icon={<ReloadOutlined/>} htmlType="button">
                Relogin
              </Button>
            </Col>
          </Row>
      </Form>
    </Modal>
  )
}

export default OfflineAlertModal
