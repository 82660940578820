import React, { useEffect } from 'react'
import CommonTablePage from '../../../../components/common/common-table/common-table-page'
import QuoteItemsManagementColumnModel from './quote-items-management-column-model'
import { urlKey } from '../../../../services/api/api-urls'

export const QuoteItemsManagementPage = () => {
  useEffect(() => {
    document.title = "Quote Notes Management";
    },[]);  
  return (
    <CommonTablePage
      urlInfoKey={urlKey.QuotationOptionItem}
      title="Quote Notes Management"
      column={QuoteItemsManagementColumnModel.Column}
    />
  )
}

export default QuoteItemsManagementPage
