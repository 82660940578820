export default class ContractTypeManagementColumnModel {
	static ContractTypeManagementColumn = [
	  {
	    title: 'Name',
	    align: 'left',
	    field: 'contractTypeName'
	  }
	]
}

