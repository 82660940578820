import { Button, Popover } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { ApiRequest } from '../../../../services/api/api'

interface IProps {
  id: number;
  label?: string;
  isNotCutting?: boolean;
}

const ItipsForRevDetails: React.FC<IProps> = (props) => {
  const [visible, setVisible] = useState(false)
  const [getRevData, setGetRevData] = useState<any>({})

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible)
    props.id &&
      ApiRequest({
        url: 'UnloadingInspection/GetAllUnloadingInspection?poId=' + props.id,
        method: 'get',
        isShowSpinner: false,
      }).then(async (res) => {
        console.log(res.data.data)
        let rev = res.data.data
        await fetchQty(rev)
        console.log(rev)
        setTimeout(() => setGetRevData(rev), 500);
      })
  }

  const fetchQty = async (inspections:any) => {
    for (const inspectionObject of inspections) {
      await fetchDataForInspectionId(inspectionObject);
    }
  }
  const fetchDataForInspectionId = async (inspectionObject:any) => {
    ApiRequest({
      url: 'UnloadingInspection/GetUnloadingInspectionById?id=' + inspectionObject.inspectionId,
      method: 'get',
      isShowSpinner: false,
    }).then((res) => {
      console.log(res.data.data);
      // debugger
      let details = res.data.data.unloadingDetail;
      for (const detail of details) {
        // let myRevData = getRevData;
        // let foundInspection = myRevData.filter((e: any) => e.inspectionId == detail.inspectionId);
        let founddetail = inspectionObject.po.poDetail.filter((e: any) => e.rawMaterialId == detail.rawMaterialId);

        if (founddetail){
          founddetail[0].totalRevQty = detail.revQuantity;
          founddetail[0].totalHandledQty =  detail.handledQuantity;
          // founddetail[0].totalHandledQty = detail.palletQuantity + detail.handledQuantity + detail.dispatchedQuantity;
        }
        // console.log(myRevData)
        // setGetRevData(myRevData);
      }
    })
  }
  const getRowRender = (name: string, data?: string, warningData?: string, successData?: string) => (
    <div style={{wordWrap: 'break-word', display: 'flex', marginBottom: '0.4rem'}}>
      <div style={{ minWidth: '50px' }}>
        <strong>{name}:</strong>
      </div>
      <div>
        <span style={{ wordBreak: 'break-all' }}>&nbsp;&nbsp;{data}</span>
        <span style={{ wordBreak: 'break-all', color: 'red' }}>
          <strong>&nbsp;&nbsp;{warningData}</strong>
        </span>
        <span style={{ wordBreak: 'break-all', color: 'green' }}>
          <strong>&nbsp;&nbsp;{successData}</strong>
        </span>
      </div>
    </div>
  )


  const content = (
    <div style={{ minWidth: '500px' }}>
      {/* <h4>Receiving Details</h4> */}
      {getRevData[0] ? (
        getRevData.map((item: any) => (
          <div style={{ margin: '0.4rem' }} >
            <h4>{item.no}</h4>
            {getRowRender(
              "Reference No ",
              item.containerNo
            )}
            {getRowRender(
              "Receiver ",item.unloadingPeople
            )}
            {getRowRender(
              "Receiving Time ",moment.utc(item.arrivalTime).local().format('DD/MM/YYYY')
            )} 
            {getRowRender(
              "Condition Of Product ",item.conditionOfProduct??""
            )} 
            {getRowRender(
              "Note ",item.notes??""
            )} 
                      
            {item.po.poDetail.map((itemDetail: any) => {
            //   console.log(itemDetail?.totalRevQty)
            //   console.log(itemDetail?.totalHandledQty)
            if ((itemDetail?.totalRevQty == null )&&
              (itemDetail?.totalHandledQty == null )) {
                return null; // Skip rendering the div
              }

            return (
              <div style={{ margin: '0.4rem' }} >
                {getRowRender(
                  itemDetail?.rawMaterial?.rawMaterialCode,
                  `Received: ${itemDetail?.totalRevQty ?? '0'}, Handled: ${itemDetail?.totalHandledQty ?? '0'}`
                )}
              </div>
            );
          })}

          </div>
        ))
      ) : (
        <strong style={{ margin: '0.4rem', color: 'grey' }}>
          No Receiving Details for this order.
        </strong>
      )}
    </div>
  )

  return (
    <Popover
      content={content}
      placement='right'
      title={<h4 style={{ textAlign: 'center' }}>Receiving Details</h4>}
      trigger='hover'
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <Button type='primary' ghost>
        Details
      </Button>
    </Popover>
  )
}

export default ItipsForRevDetails
