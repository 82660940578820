import React from 'react'
import moment from 'moment'
import ItipsForPo from '../../../../components/common/i-tips/purchase-info/index'

export const ApproveDelColumnModel = () => {
  return [
    {
      title: 'Bar Code',
      align: 'left',
      field: 'barCode'
    },
    {
      title: 'Product',
      align: 'left',
      field: 'productName'
    },
    {
      title: 'Customer',
      align: 'left',
      field: 'customerName'
    },    
    {
      title: 'Created At',
      align: 'left',
      field: 'createdAt',
      render: (rowData: any) => moment.utc(rowData.createdAt).local().format('DD/MM/YYYY'),
    },
    {
      title: 'Deleted At',
      align: 'left',
      field: 'updatedAt',
      render: (rowData: any) => moment.utc(rowData.updatedAt).local().format('DD/MM/YYYY HH:mm:ss'),
    },    
    {
      title: 'Notes',
      align: 'left',
      field: 'notes'
    },   
    // {
    //   title: 'Position',
    //   align: 'left',
    //   field: 'position'
    // },
    // {
    //   title: 'Pallet',
    //   align: 'left',
    //   field: 'palletCode'
    // },
  ]
}
