export const invoicePdfMock = {
    name: 'QQ Coffee2',
    phone: '0000000000',
    country:null,
    tableContent:[
        {
            qty: 1000,
            description: 'good',
            price: 10,
            amount: 1000
        },
        {
            qty: 12,
            description: 'ok',
            price: 100,
            amount: 1200
        },
        {
            qty: '1232',
            description: 'bad',
            price: 102,
            amount: 1000,
        },{
            qty: 1000,
            description: 'good',
            price: 10,
            amount: 1000
        },
        {
            qty: 12,
            description: 'ok',
            price: 100,
            amount: 1200
        },
        {
            qty: '1232',
            description: 'bad',
            price: 102,
            amount: 1000,
        },{
            qty: 1000,
            description: 'good',
            price: 10,
            amount: 1000
        },
        {
            qty: 12,
            description: 'ok',
            price: 100,
            amount: 1200
        },
        {
            qty: '1232',
            description: 'bad',
            price: 102,
            amount: 1000,
        },{
            qty: 1000,
            description: 'good',
            price: 10,
            amount: 1000
        },
        {
            qty: 12,
            description: 'ok',
            price: 100,
            amount: 1200
        },
        {
            qty: '1232',
            description: 'bad',
            price: 102,
            amount: 1000,
        },{
            qty: 1000,
            description: 'good',
            price: 10,
            amount: 1000
        },
        // {
        //     qty: 12,
        //     description: 'ok',
        //     price: 100,
        //     amount: 1200
        // },
        // {
        //     qty: '1232',
        //     description: 'bad',
        //     price: 102,
        //     amount: 1000,
        // }
        // ,{
        //     qty: 1000,
        //     description: 'good',
        //     price: 10,
        //     amount: 1000
        // },
        // {
        //     qty: 12,
        //     description: 'ok',
        //     price: 100,
        //     amount: 1200
        // },
        // {
        //     qty: '1232',
        //     description: 'bad',
        //     price: 102,
        //     amount: 1000,
        // },{
        //     qty: 1000,
        //     description: 'good',
        //     price: 10,
        //     amount: 1000
        // },
        // {
        //     qty: 12,
        //     description: 'ok',
        //     price: 100,
        //     amount: 1200
        // },
        // {
        //     qty: '1232',
        //     description: 'bad123',
        //     price: 102,
        //     amount: 1000,
        // },
        // {
        //     qty: 1000,
        //     description: 'good',
        //     price: 10,
        //     amount: 1000
        // },
        // {
        //     qty: 12,
        //     description: 'ok',
        //     price: 100,
        //     amount: 1200
        // },
        // {
        //     qty: '1232',
        //     description: 'bad',
        //     price: 102,
        //     amount: 1000,
        // },
    ]
}
//
// export const pdfMock =()=>{
//     return data.filter(g=>g)
// }