import { getColModelItem } from '../../../services/lib/utils/helpers'
import { urlKey } from '../../../services/api/api-urls'


const ProductSelectColumnModel = (): any => {
    return [
        {
            title: 'ProductName',
            align: 'left',
            field: 'productName'
        },
        {
            title: 'ProductCode',
            align: 'left',
            field: 'productCode',
        },
        {
            title: 'Customer',
            align: 'left',
            field: 'company',
        },
        {
            title: 'Base Product',
            align: 'left',
            field: 'baseProductCode',
        },
        {
            title: 'Type ',
            align: 'left',
            field: 'typeName',
        }        
    ]
}

export default ProductSelectColumnModel
