import React from 'react'
import { nbsStr } from '../../../../services/lib/utils/helpers'
import moment from 'moment'
import ItipsForRevDetails from '../../../../components/common/i-tips/receiving-details/index'

export const optionsArray = [{value: 1, label: 'Delivery Fee'}, {value: 2, label: 'Urgent Fee'}, {value: 3, label: 'Setup Fee'}]

const myfiler=(
  filterValue:any,
  rowData:any
  ) => {
  const filter = filterValue.replace(/-/g, '\u2011').trim().slice(0,-1).toUpperCase();
  for (let ele of rowData.poDetail){
    const prodName = ele?.rawMaterial.rawMaterialCode?.toUpperCase().replace(/-/g, '\u2011') + ele?.rawMaterial.rawMaterialName?.toUpperCase().replace(/-/g, '\u2011')
    if (prodName.indexOf(filter) >=0) return true;
  }
  return false
  }  
  const renderStatus = (statusId:any) => {
    switch (statusId) {
      case 1:
        return 'Awaiting Approval';
      case 2:
        return 'Approved';
      case 3:
        return 'Completed';
      case 0:
        return 'Cancelled';
      case 10:
        return 'Received';
      default:
        // Handle the case where rowData doesn't match any key in the lookup object.
        return 'Unknown'; // You can return a default value or handle it as needed.
    }
  };
const rowColor = (row: any) => {
  if (row.totalHandledQty == row.quantity) {
    return 'red';
  }
  if (row.totalHandledQty > 0 && row.totalHandledQty < row.quantity) {
    return 'blue';
  } //row.deliveredQuantity==0?(row.stockQuantity>0?
  return 'black';
};
export const PurchaseOrderColumnModel = (type:any): any => {
  return [
    {
      title: 'PO Number',
      field: 'poNo'
    },
    {
      title: 'Details',
      field: 'poDetail',
      filtering: true,
      render: (rowData: any) => {
        return (
          <ul>
            {rowData.poDetail?.map((row: any, index: number) => {
              return <li style={{display:"flex",color: rowColor(row)}} key={index.toString()}>
                <div style={{width:"70ch"}}>{nbsStr(row.rawMaterial?.rawMaterialCode + ' / ' + row.rawMaterial?.rawMaterialName,false,65)} <b>U.P:$</b>{row.unitPrice}</div>
                <div><b style={{fontSize: '1rem'}}></b>PUR:{row.quantity},REV:{row.totalRevQty},HDL:{row.totalHandledQty}<b style={{fontSize: '1rem'}}></b></div>
                </li>
            })}
            {rowData.poOption?.map((row: any, index: number) => {
              return <li key={index.toString()}>{optionsArray.filter((fee: any) => fee.value === row.optionId)[0].label}: ${row.price}</li>
            })}
          </ul>
        )
      },
      customFilterAndSearch:(        filterValue:any,
        rowData:any) =>myfiler(
        filterValue,
        rowData
        ) 	      
    },
      
    {
      title: 'Price',
      field: 'price',
      // type: 'currency',
      render: (rowData: any) => {
        return (
          <div>
            <b>
              {rowData.suplier.currencyId === 1 ? 'NZD' :
                rowData.suplier.currencyId === 2 ? 'RMB' :
                  rowData.suplier.currencyId === 3 ? 'USD' :
                    'Unknown Currency'}
            </b>
            <div>
              {rowData.suplier.currencyId === 1 ? 'Excl GST $' : '$'}
              {rowData.price + (rowData.poOption?.reduce((a: number, o: any) => a + o?.price, 0) || 0)}
            </div>
            {rowData.suplier.currencyId === 1 && (
              <div>
                Incl GST $
                {rowData.inclGstPrice + (rowData.poOption?.reduce((a: number, o: any) => a + o?.price, 0) || 0) * 1.15}
              </div>
            )}
          </div>
        );
      }
    },

    {
      title: 'Supplier',
      field: 'supplierName'
    },
    {
      title: 'Created By',
      field: 'createdEmployeeName',
      render: (rowData: any) => (
        <div>
          <div>{rowData.createdEmployeeName}</div>
          <div>{moment.utc(rowData.createdAt).local().format('DD/MM/YYYY')}</div>
        </div>
      )
    },
    // {
    //   title: 'Delivery Addr',
    //   field: 'deliveryAddr'
    // },
    // {
    //   title: 'Delivery Date',
    //   field: 'deliveryDate1',
    // },
    // {
    //   title: 'Created At',
    //   field: 'createdAt',
    //   render: (rowData: any) => moment.utc(rowData.createdAt).local().format('DD/MM/YYYY')
    // },   
    {
      title: 'Details',
      field: 'poId',
      render: (rowData: any) => (
        <ItipsForRevDetails id={rowData.poId}/>
      )
    },      
    {
      title: 'Notes',
      field: 'notes',
    },     
    {
      title: 'Status',
      field: 'poStatusId',
      ...(type !== 3 && { defaultFilter: ['1', '2', '10'] }),
      lookup: { 1: 'AwAppr', 2: 'Appr', 3: 'Comp', 0: 'Canc', 10: 'Rev' },
      render: (rowData: any) => renderStatus(rowData.poStatusId)
    },
    ...(type === 3
      ? [
          {
            title: 'Payment',
            field: 'paid',
            defaultFilter: ['0'],
            lookup: { 0: 'Unpaid', 1: 'Paid' },
            render: (rowData: any) => (
              <div>{rowData.paid === 1 ? 'Paid' : 'Unpaid'}</div>
            ),
          },
          {
            title: 'Invoice Note',
            field: 'invoiceNote',
          },
        ]
      : []), // Conditionally add columns for type === 3    
  ]
}
