import React, {useEffect, useState} from 'react'
import CommonTable from '../../../components/common/common-table/common-table'
import {Button, DatePicker} from 'antd'
import { ApiRequest } from '../../../services/api/api'
import moment from 'moment'
import CommonForm, { ItemElementPropsInterface } from '../../../components/common/common-form/common-form'
import { commonFormSelect, getSelectOptions } from '../../../components/common/common-form/common-form-select'
import AddPalletLogDialog from './AddPalletLogDialog/addPalletLogDialog'
import { urlKey } from '../../../services/api/api-urls'
import CommonDialog from '../../../components/common/others/common-dialog'
const { RangePicker } = DatePicker

interface IFormValues {
  customerId?: any
  productId?: any
  plateTypeId?:any
  startDate?: any
  endDate?: any  
}
const PalletLog = (props: {customerId: number, plateTypeId: number}) => {
  const tableColumn = [
    // { title: 'Report Id', field: 'reportId' },
    { title: 'Customer', field: 'customerName' },
    { title: 'PalletType', field: 'palletTypeName' },
    { title: 'Transaction',  field: 'TranTypeName' },
    { title: 'Quanity',field: 'quantity' },
    { title: 'Original Qty',field: 'balance' },
    { title: 'Notes',field: 'notes' },    
    { title: 'PackingSlip',field: 'packingSlip' },    
    { title: 'Date',field: 'createdAt1' }   ,
    { title: 'Operator',field: 'Operator' }     
  ]

  const [dataInTable, setDataInTable] = useState<any>([])
  const [isValid, setIsValid] = useState<boolean>(false)
  const [customerOptions, setCustomerOptions] = useState([])  
  const [palletOptions, setPalletOptions] = useState([])    
  const [palletQty, setPalletQty] = useState(0)    
  const [initFormValues, setInitFormValues] = useState<IFormValues>()    
  const [formValues, setFormValues] = useState<IFormValues>()    
  const [formRef, setFormRef] = useState<any>()
  const [open, setOpen] = useState(false)  
  const formRefCurrent: any = React.useRef()  

  useEffect(() => {
    formRefCurrent.current = formRef
  }, [formRef])

  useEffect(() => {
    document.title = "Pallet Management";
    getSelectOptions('', 'Customer/GetAllSimpleCustomer').then(res => setCustomerOptions(res))       
    getSelectOptions(urlKey.PlateType).then(res => setPalletOptions(res))   
    setInitFormValues(
      {
        customerId: props.customerId,
        plateTypeId: props.plateTypeId,
        startDate: moment().add(-12,'months'),
        endDate:  moment()
      })
    getPalletData(props.customerId, props.plateTypeId)
    // setFormValues({
    //   customerId:props.customerId,
    //   plateTypeId: props.plateTypeId,
    //   startDate: moment().add(-12,'months'),
    //   endDate:  moment()      
    // })
    console.log( moment("2015-01-01", 'YYYY-MM-DD'))
    console.log( moment().add(-12,'months'))
    console.log( moment())
  }, [])

  const formItems: ItemElementPropsInterface[] | any = [ 
    {name: 'customerId', label: <label style={{fontSize: '20px'}}>Customer</label>, span: 9, rules: [{required: true}],
    inputElement: commonFormSelect(urlKey.Customer, customerOptions,['company', 'customerCode'],true)},     
     {name: 'plateTypeId', label: <label style={{fontSize: '20px'}}>Pallet</label>, span: 6, rules: [{required: true}],
      inputElement: commonFormSelect(urlKey.PlateType, palletOptions,['plateTypeName'],true),},     
    {name: 'customerId', label: <label style={{fontSize: '20px'}}>Customer</label>, span: 6, rules: [{required: true}],
      inputElement: commonFormSelect(urlKey.Customer, customerOptions,['company', 'customerCode']), otherProps: { hidden: true}},       
    {name: 'startDate', label: 'Start Date', rules: [{required: true}], otherProps: {hidden:'true'},inputElement: <DatePicker format={'DD/MM/YYYY'} />},
    {name: 'endDate', label: 'End Date', rules: [{required: true}],otherProps: {hidden:'true'},inputElement: <DatePicker format={'DD/MM/YYYY'} />},
  ]




  const onFormChange = (changedValues: any, newValues: any, form: any)=>{
    setFormValues(newValues)
    setIsValid(newValues?.customerId && newValues?.plateTypeId 
      && newValues?.startDate && newValues?.endDate )
  }
  const onConfirm = async () => {
  getPalletData(props.customerId, props.plateTypeId)    
  }

  const getPalletData = (customerId: number, plateTypeId: number) => {
    ApiRequest({
      url: 'PalletStockLog/GetByCustomerId?customerId='+customerId+'&plateTypeId='+plateTypeId,
      method: 'get',
      isShowSpinner: true
    }).then((res:any)=>{
      let reqData = res.data.data
      reqData.map((e:any)=>{
        e.customerName=e.customer?.customerCode
        e.palletTypeName = e.plateType?.plateTypeName
        e.packingSlip = e.dispatch?.packingSlipNo
        e.TranTypeName = e.tranType?.tranTypeName
        e.createdAt1 = moment().utc(e.createdAt).local().format("DD/MM/YYYY")
        e.Operator = e.employee?.firstName
      })
      setDataInTable(reqData)        
    })
    ApiRequest({
      url: 'PalletStockLog/GetBalanceByCustomerId?customerId='+customerId+'&plateTypeId='+plateTypeId,
      method: 'get',
      isShowSpinner: true
    }).then((res:any)=>{
      let reqData = res.data.data
      setPalletQty(reqData)        
    })
  }

  const onDialogClose = (isModified: boolean) => {
    // setIsShowSpinner(false)
    setOpen(false)
    if (isModified) {
      onConfirm()
    }
  }  
  const addPalletLogDialog = <AddPalletLogDialog onDialogClose={onDialogClose} customerId={props.customerId} plateTypeId={props.plateTypeId}/>  
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '75%' }}>
          <CommonForm items={formItems} initFormValues={initFormValues}   onFormChange={onFormChange}/>
        </div>

        <div style={{ width: '25%' , display: 'none' }}><Button type="primary" disabled={!isValid}
          onClick={onConfirm}
          style={{ marginLeft: '10px' }}>
          Search</Button>
        </div>
        <div style={{ width: '25%' }}><Button type="primary" //disabled={!isValid}
          onClick={()=>setOpen(true)}
          style={{ marginLeft: '10px' }}>
          New</Button>
        </div>        
      </div>
      <div style={{fontSize: '20px'}}> Pallet quanity is :{palletQty} </div>      
      <CommonTable title={'Pallets List'} column={tableColumn} initData={dataInTable} />
      
      <CommonDialog title={'New '} width={"58%"} open={open} onDialogClose={onDialogClose} dialogContent={addPalletLogDialog} />
    </div>
  )
}

export default PalletLog
