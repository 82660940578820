const data= [
    {
        jobType:'worker',
        originalQty:1,
        actualQty:2,
        material:'sas',
        operatorDate: 'dad',
        work: 'ssa',

    }
]

export const subOrder = ( )=>{
    return data.filter(g =>g)
}