import { getColModelItem } from '../../../services/lib/utils/helpers'
import { urlKey } from '../../../services/api/api-urls'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Swal from 'sweetalert2'



const NotificationManagementColumnModel = () => {

	 return [
    {
      ...getColModelItem({
			  title: 'Creator',
			  field: 'creator',
			  keywords: [
          urlKey.Employee
			  ]
		  }, () => ([{key: urlKey.Employee, label: '', otherOptions: {type: 'select', isOverrideSelectionOptions: true, valueJoinArray: ['firstName', 'lastName']}}]))
	  },
    {
      ...getColModelItem({
        title: 'Role',
        field: 'role',
        keywords: [
          urlKey.Role
        ]
      }, () => ([{key: urlKey.Role, label: '', otherOptions: {type: 'select'}}]))
	  },
    {
		  title: 'Start Date',
		  align: 'left',
		  field: 'startDate1',
      type: 'date',
	    sorting: false
	  },
    {
		  title: 'End Date',
		  align: 'left',
		  field: 'endDate1',
      type: 'date',
	    sorting: false
    },
    {
      title: 'Active',
      field: 'isActive1',
      lookup: {0: 'No', 1: 'Yes'},
    },
    {
      title: 'Content',
      field: 'notificationContext',
      editComponent: (props:any) => (
        <TextField
          value={props.value}
          fullWidth={true}
          multiline={true}
          onChange={e => props.onChange(e.target.value)}
        />
      )	
    }
  ]
}

export default NotificationManagementColumnModel
