import CommonTablePage, { CommonTablePagePropsInterface } from '../../../../../components/common/common-table/common-table-page'
import React, { useRef, useState,useEffect } from 'react'
import  {TraceabilityColumnModel} from '../work-order-management-page/work-order-management-column-model'
import { urlKey } from '../../../../../services/api/api-urls'
import CommonDialog from '../../../../../components/common/others/common-dialog'
import { ApiRequest } from '../../../../../services/api/api'
import SweetAlertService from '../../../../../services/lib/utils/sweet-alert-service'
import { getRandomKey, nsStr } from '../../../../../services/lib/utils/helpers'

const refreshTime=5;
const WorkOrderTrackingPage = (props: {stockMonitorProductId?: any}) => {
  const [triggerResetData, setTriggerResetData] = useState<any>(false)
  const [isShowSpinner, setIsShowSpinner] = useState(true)
  const [open, setOpen] = useState(false)



  useEffect(() => {
    return () => { };
  });

   useEffect(() => {
    document.title = "Product Traceability";
  }, []);

  const actionButtons: any = [
  ]

  const getRenderData = async (data: any) => {
    let res = await ApiRequest({
      url: 'Product/GetAllProductName',
      method: 'get'
    })
    const products = res.data.data;
    const findProductById = (id:number) =>{
      const product = products.find((product:any) => product.productId === id);
      if (product) {
        return product;
      } else {
        return null; // 或者根据需求返回其他值，如 { error: 'Product not found' }
      }
    }    
    // debugger
    // console.log(data)
    const renderData: any = []
    data.map((row: any) => {
      if (row.workOrderStatusId !== 0) {
        const productCode = findProductById(row.productId)?.productCode;
        const productName = findProductById(row.productId)?.productName;
        row.product = findProductById(row.productId);
        const newData = {
          ...row,
          actionId: row.actionDto?.actionId,
          urgent: row.urgent ? 1 : 0,
          productCode: productCode,
          productFilter: productName + nsStr(productName),// + ' ' + row.product?.description,
          createdEmployeeName: (row.createdEmployee?.firstName || '') + ' ' + (row.createdEmployee?.lastName || ''),
          createdAt1: row.createdAt,
          requiredDate1: row.requiredDate,
          createdAt: row.createdAt && (new Date(row.createdAt + '.000Z')).toLocaleString(),
          requiredDate: row.requiredDate && (new Date(row.requiredDate + '.000Z')).toLocaleDateString(),
          workOrderNo: row.workOrderNo && parseInt(row.workOrderNo),
          workOrderStatusId1: row.workOrderStatusId==2?1:row.workOrderStatusId
        }
        if (props.stockMonitorProductId) {
          if (row.productId === props.stockMonitorProductId) {
            renderData.push(newData)
          }
        } else {
          renderData.push(newData)
        }
      }
    })
    // console.log(renderData)
    return renderData
  }
  const getUpdateData = (dataDetail: any) => {
    if ([10, 0].includes(dataDetail.workOrderStatusId)) {
      SweetAlertService.errorMessage('This order is cancelled or completed, so not deletable.')
      return ''
    }
    return dataDetail
  }
  const updateRMNote = async (rowData: any) => {
    const result = await SweetAlertService.inputConfirm({type: 'text', title: 'Update Notes', placeholder: 'Notes'})
    if (result !== null) {
      if (!result) {
        await SweetAlertService.errorMessage('Please type in Notes before submitting')
        return
      } else {
        // console.log(result)
        ApiRequest({
          url: 'WorkOrder/UpdateRawMaterialNote?id=' + rowData.workOrderId + '&notes=' + result,
          method: 'put'
        }).then(_ => {
          setTriggerResetData(getRandomKey())
        })
      }
    }
  }
  const commonTablePageProps: CommonTablePagePropsInterface = {
    urlInfoKey: urlKey.WorkOrder,
    getAllUrl: `/WorkOrder/GetTrackingInfo`,
    title: 'Product Traceability',
    column: TraceabilityColumnModel({updateRMNote: updateRMNote}),
    mappingRenderData: (data: any) => getRenderData(data),
    mappingUpdateData: (dataDetail: any) => getUpdateData(dataDetail),
    triggerResetData: triggerResetData,
    actionButtons: props.stockMonitorProductId ? [] : actionButtons,
    isNotDeletable: true,
    isNotEditable: true,
    isNotAddable: true,
    isShowSpinnerOnInit: isShowSpinner
  }

  return (
    <div>
      <CommonTablePage {...commonTablePageProps} />
    </div>
  )
}

export default WorkOrderTrackingPage
