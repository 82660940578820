import React,{useEffect} from 'react'
import DeletedRawMaterialColumn from './deleted-raw-material-column'
import CommonTablePage, { CommonTablePagePropsInterface } from '../../../components/common/common-table/common-table-page'
import { urlKey } from '../../../services/api/api-urls'

const DeletedRawMaterialPage: any = (props: any) => {

  const commonTablePageProps: CommonTablePagePropsInterface = {
    urlInfoKey: urlKey.BaseProduct,
    getAllUrl: 'RawMaterial/GetAllDeletedRawMaterial',
    title: props.title || 'Deleted Raw Materials',
    column: DeletedRawMaterialColumn.RawMaterialManagementColumn,
    isNotEditable: true,
    isNotDeletable: true,
    isNotAddable: true
  }
  useEffect(() => {   
    document.title="Deleted Raw Materials"
  }, [])
  return (
    <>
      <CommonTablePage
        {...commonTablePageProps}
      />
    </>
  )
}

export default DeletedRawMaterialPage
