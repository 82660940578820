import { urlKey } from "../../../../services/api/api-urls";
import { getColModelItem } from "../../../../services/lib/utils/helpers";

export default class ProductBarcodeManagementColumnModel {
	static Column = [
		{
			...getColModelItem({
				title: 'Product',
				field: 'productId',
				keywords: [
					urlKey.Product
				]
			}, () => ([{ key: urlKey.Product, label: '', 
						otherOptions: 
						{ type: 'select', isOverrideSelectionOptions: true, selectionGetUrl: '/Product/GetAllProductName', width: '14rem', valueJoinArray: ['productName', 'productCode'] } }]))
		},
		{
			title: 'Barcode',
			align: 'left',
			field: 'barcode'
		},

	]
}
