import ItipsForProduct from '../../../../../components/common/i-tips/product'
import React from 'react'

export const DispatchOrderDetailsColumnModel = () => {
  return [
	  {
	    title: 'Product',
	    field: 'product',
		  render: (rowData: any) => rowData.product ? <ItipsForProduct id={rowData.productId} label={rowData.product.productName} /> : null
	  },
	  {
	    title: 'Quantity',
	    field: 'quantity'
		// field: 'productQty'
	  },
	//   {
	//     title: 'Delivered',
	//     field: 'delivered',
	//     defaultFilter: ['0'],
	//     lookup: {0: 'No', 1: 'Yes'}
	//   },
	{
	    title: 'Stock',
	    field: 'Stock',
		render: (rowData: any) => {
			console.log(rowData.productInventoryInfo);
			console.log(rowData?.productInventoryInfo?.productInStock);
			return rowData.productInventoryInfo ? (
			<div>
			  <div>
				<b>InStock:&nbsp;</b>{rowData.productInventoryInfo.productInStock}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

			  </div>
			  <div>
				<b >Appr WO:&nbsp;</b>{rowData.productInventoryInfo.pendingWorkOrderQuantity}&nbsp;&nbsp;            
				<b >Order:&nbsp;</b>{rowData.productInventoryInfo.pendingOrderQuantity}&nbsp;&nbsp;
			  </div>
			  <div>
				<b >In Packaging:&nbsp;</b>{rowData.productInventoryInfo.pendingWarehouseQuantity}&nbsp;&nbsp;
			  </div>
			</div>
		  ) : null
		// return <div>InStock:{rowData?.productInventoryInfo?.productInStock}</div>
		}
	  },	
	  {
	    title: 'Delivered Qty',
	    field: 'deliveredQuantity'
	  },
	  {
		  title: 'Selected Qty',
		  field: 'selectedQty'
	  }
  ]
}
