import React, {useEffect, useState} from 'react'
import {Select} from 'antd'
// import {iSelectRequest} from '../../../services/i-select-services'

const {Option} = Select

interface Iprops {
    placeholder?:string
    onChange: (data:any) => void
    width?:number
    data:any
    value?:any
}

const Iselect:React.FC<Iprops> = (props) => {

  const onChange = (value:string) => {
    props.onChange(value)
  }

  const onBlur = () => {
    // console.log('blur');
  }

  const onFocus = () => {
    // console.log('focus');
  }

  const onSearch = (val:string) => {
    // console.log('search:', val);
  }

  const selects = props.data.map((res: any, index: number) => {
    const parts = res.label.split('###');
    const productCode = parts[0];
    const productName = parts[1] ?? '';
  
    return (
      <Option key={index} value={res.value}>
        <span>
          <span style={{ fontWeight: 'bold', color: 'blue' }}>{productCode}</span>
          {productName && (
            <>
              <span> - </span>
              <span style={{ color: 'green' }}>{productName}</span>
            </>
          )}
        </span>
      </Option>
    );
  });

  const otherProps = () => {
    if (props.value) {
      return {value: props.value}
    }
    return {}
  }
  const getTextContent = (element:any) :string => {
    if (typeof element === 'string') {
      return element;
    }
    if (Array.isArray(element)) {
      return element.map(getTextContent).join('');
    }
    if (element && typeof element === 'object' && element.props && element.props.children) {
      return getTextContent(element.props.children);
    }
    return '';
  };
  return (
    <Select
      allowClear={true}
      showSearch
      style={{ width: props.width ? props.width : 140 }}
      placeholder={props.placeholder ? props.placeholder : 'select one'}
      optionFilterProp="children"
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onSearch={onSearch}
      filterOption={ (input: any, option: any) => {
        const text = getTextContent(option.children.props.children);
        return text.toLowerCase().indexOf(input?.replace('\u2011', '\u002d').toLowerCase()) >= 0
      } }
      {...otherProps()}
    >
      {selects}
    </Select>

  )
}

export default Iselect
