import React, { useEffect, useState } from 'react'
import CommonTable from '../../../../components/common/common-table/common-table'
import { Button, DatePicker } from 'antd'
import { ApiRequest } from '../../../../services/api/api'
import CommonForm, {
  ItemElementPropsInterface,
} from '../../../../components/common/common-form/common-form'
import {
  commonFormSelect,
  getSelectOptions,
} from '../../../../components/common/common-form/common-form-select'
import CommonDialog from '../../../../components/common/others/common-dialog'
import PalletLog from '..'
import { urlKey } from '../../../../services/api/api-urls'

const CustomerPalletList: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [customerId, setCustomerId] = useState<number>(0)
  const [plateTypeId, setPlateTypeId] = useState<number>(0)
  const [customerOptions, setCustomerOptions] = useState<any>([])
  const [palletOptions, setPalletOptions] = useState<any>([])
  const [dataInTable, setDataInTable] = useState<any>([])

  const tableColumn = [
    // { title: 'Report Id', field: 'reportId' },
    { title: 'Customer', field: 'customerName' },
    { title: 'PalletType', field: 'palletType' },
    { title: 'Quanity', field: 'balance' },
  ]

  const actionButtons: any = [
    {
      icon: 'ghost', //Button attr of Ant design (danger, ghost)
      tooltip: 'Details',
      isFreeAction: false,
      onClick: (event: any, rowData: any) => {
        setCustomerId(customerOptions.filter((item: any) => item.customerCode === rowData.customerName)[0]['customerId'])
        setPlateTypeId(palletOptions.filter((item: any) => item.plateTypeName === rowData.palletType)[0]['plateTypeId'])
        setOpen(true)
      }
    }
  ]

  useEffect(() => {
    document.title = 'Customer Pallet Management'
    getSelectOptions('', 'Customer/GetAllSimpleCustomer').then((res: any) => setCustomerOptions(res))
    getSelectOptions(urlKey.PlateType).then((res: any) => setPalletOptions(res))
    fetchPalletStockLogData().then((res: any) => {
      const reqData = res.data.data
      setDataInTable(reqData)
    })
  }, [])

  const fetchPalletStockLogData = () => {
    return ApiRequest({
      url: 'PalletStockLog/GetAllBalance',
      method: 'get',
      isShowSpinner: true,
    })
  }
  const onDialogClose = () => {
    setOpen(false)
    fetchPalletStockLogData().then((res: any) => {
      const reqData = res.data.data
      setDataInTable(reqData)
    })    
  }

  const palletContent = (
    <PalletLog customerId={customerId} plateTypeId={plateTypeId} />
  )

  return (
    <div>
      <CommonTable
        title={'Pallets List'}
        column={tableColumn}
        actionButtons={actionButtons}
        initData={dataInTable}
      />
      <CommonDialog
        title={'Pallets List'}
        open={open}
        onDialogClose={onDialogClose}
        dialogContent={palletContent}
      />
    </div>
  )
}

export default CustomerPalletList
