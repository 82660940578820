import { getJoinValueFromArray, padRightWithSpaces } from '../../../services/lib/utils/helpers'
import { allUrls, urlKey } from '../../../services/api/api-urls'
import { ApiRequest } from '../../../services/api/api'
import { Select } from 'antd'
import React, { useState } from 'react'
import ItipsForProduct from '../i-tips/product'

const { Option } = Select

export const getSelectOptions = async (urlInfoKey: string, selectionGetUrl?: string) => {
  const result = await ApiRequest({
    url: selectionGetUrl || allUrls[urlInfoKey].get,
    method: 'get',
    isShowSpinner: [urlKey.City, urlKey.QuotationOptionItem].includes(urlInfoKey)
  })
  return result.data.data
}

  export const commonFormSelect = (urlInfoKey: string, data: any, valueJoinArray?: any[], disabled?: boolean, onChangeFn?: any, valueKeyString?: any, placeholder?: any, value?: any, showDefault?: boolean, showTips?:boolean) => {
  const id: string = urlInfoKey + 'Id'
  const name: string = urlInfoKey + 'Name'

  const renderOptionContent = (
    urlInfoKey: string,
    showTips: boolean,
    row: any,
    valueJoinArray: any[] | undefined,
    name: string
  ) => {
    // console.log(urlKey.BaseProduct)
    // console.log(urlInfoKey)
    // console.log(showTips)
 
    if (urlKey.Product === urlInfoKey && showTips) {
      return (
        <ItipsForProduct
          id={row.productId}
          isNotCutting={true}
          label={valueJoinArray && valueJoinArray.length ? getJoinValueFromArray(row, valueJoinArray) : row[name]}
        />
      );
    } 
    else if (urlKey.BaseProduct === urlInfoKey && showTips) {

      return (
        <ItipsForProduct
          baseProductId={row.baseProductId}
          isNotCutting={true}
          label={valueJoinArray && valueJoinArray.length ? getJoinValueFromArray(row, valueJoinArray) : row[name]} id={0}        />
      );
    } else {
      return valueJoinArray && valueJoinArray.length ? getJoinValueFromArray(row, valueJoinArray) : row[name];
    }
  };
  return (
    <Select
        placeholder={placeholder || ''}
      allowClear
      showSearch
      style={{width: '100%'}}
      defaultValue={showDefault && data[0][id]}
      disabled={disabled}
      onChange={onChangeFn}
      filterOption={ (input: any, option: any) => {
        // return option.children.toLowerCase().indexOf(input?.trim().replace('\u2011', '\u002d').toLowerCase()) >= 0
        const sanitizedInput = input?.trim().replace(/\u2011|\u002d/g, ' ').toLowerCase();
        if (!sanitizedInput) return true;
        const wordsToMatch = sanitizedInput.split(' ').map((word:string)  => word.trim());
        let optionText = option?.children?.props?.label?.toLowerCase();
        if (!optionText)
          optionText = option?.children?.toLowerCase();
        return wordsToMatch.every((word:string) => optionText.indexOf(word) >= 0);        
      } }
      value={value}
      onInputKeyDown={ (e: any) => {
        if (e.key === 'Enter') {
          e.preventDefault()
          return
        }
      } }
    >
      {data.map((row: any, index: number) => (
        <Option value={valueKeyString ? row[valueKeyString] : row[id]} key={index.toString()}>
          {renderOptionContent(urlInfoKey, showTips || false, row, valueJoinArray, name)}
        </Option>
      ))}
    </Select>
  )
}
