import TextField from '@material-ui/core/TextField'
import React from 'react'

export default class ContactManagementColumnModel {
    static ContactManagementColumn = [
       {
        title: 'Name',
        align: 'left',
        field: 'firstName'
      },
      // {
      //   title: 'Last Name',
      //   align: 'left',
      //   field: 'lastName'
      // },
      {
        title: 'Phone',
        align: 'left',
        field: 'phone',
        editComponent: (props:any) => (
          <TextField
            value={props.value}
          fullWidth={true}
          multiline={true}
          onChange={e => props.onChange(e.target.value)}
          />)	          
      },   
      {
        title: 'Mobile',
        align: 'left',
        field: 'mobile',
        editComponent: (props:any) => (
          <TextField
            value={props.value}
          fullWidth={true}
          multiline={true}
          onChange={e => props.onChange(e.target.value)}
          />)	         
      }, 
      {
        title: 'Email',
        align: 'left',
        field: 'email',
        editComponent: (props:any) => (
          <TextField
            value={props.value}
          fullWidth={true}
          multiline={true}
          onChange={e => props.onChange(e.target.value)}
          />)	         
      },    
      {
        title: 'Notes',
        align: 'left',
        field: 'notes',
        editComponent: (props:any) => (
          <TextField
            value={props.value}
          fullWidth={true}
          multiline={true}
          onChange={e => props.onChange(e.target.value)}
          />)	         
      }
    ]
}
