import React from 'react'
import BaseProductInfosPage from './base-product-infos-page'

const SalesProductsList: any = () => {
  return (
    <>
      <BaseProductInfosPage title={'Sales Products List'} />
    </>
  )
}

export default SalesProductsList
