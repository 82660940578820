import React, { useEffect } from 'react'
import CommonTablePage from '../../../../components/common/common-table/common-table-page'
import ProductBarcodeManagementColumnModel from './product-barcode-management-column-model'
import { urlKey } from '../../../../services/api/api-urls'

const ProductBarcodeManagementPage = (): any => {
  useEffect(() => {
    document.title ="Product Barcde";
    },[]);

  return (
    <CommonTablePage
      urlInfoKey={urlKey.ProductBarcode}
      title="Product Barcde"
      mappingUpdateData={(dataDetail: any) => ({...dataDetail})}
      column={ProductBarcodeManagementColumnModel.Column}
    />
  )
}

export default ProductBarcodeManagementPage
