import React, { useEffect, useState } from 'react'
import { urlKey, urlType } from '../../../../services/api/api-urls'
import CommonForm, { ItemElementPropsInterface } from '../../../../components/common/common-form/common-form'
import { Button, DatePicker, Divider, Input, InputNumber, Switch } from 'antd'
import { commonFormSelect, getSelectOptions } from '../../../../components/common/common-form/common-form-select'
import moment from 'moment'
import { ApiRequest } from '../../../../services/api/api'
import SweetAlertService from '../../../../services/lib/utils/sweet-alert-service'

import {getCookie} from 'react-use-cookie'



interface IFormValues {
  tranTypeId?: number
  quanity?: number
  notes?: any

}

const AddPalletLogDialog = (props: { onDialogClose: any, customerId?:number, plateTypeId?: number}) => {
  const [formRef, setFormRef] = useState<any>()
  const [tranOptions, setTranOptions] = useState<any>([])
  const [initFormValues, setInitFormValues] = useState<IFormValues>()
  // const [formValues, setFormValues] = useState<IFormValues>()      


  useEffect(() => {
    getSelectOptions(urlKey.PalletTranType).then(res => setTranOptions(res))   
  },[])

  useEffect(() => {
    console.log(props)

    // setInitFormValues({
    //   orderStatusId: 1,
    //   ...props.orderData,
    //   requiredDate: props.orderData.requiredDate && moment(props.orderData.requiredDate + '.000Z') || moment().add(14, 'days'),
    //   deliveryDate: props.orderData.deliveryDate && moment(props.orderData.deliveryDate + '.000Z'),
    //   orderDate: (props.orderData.orderDate && moment(props.orderData.orderDate + '.000Z')) || moment(),
    //   customerId: props.customerId ? props.customerId : props.orderData.customerId
    // })
    
    // getSelectOptions(urlKey.PalletTranType).then(res => tranOptions(res))   

  }, [props.customerId,props.plateTypeId])

  useEffect(() => {
    if (formRef && props.customerId ) {
      // setCustomerInfosToForm(props.customerId, {...initFormValues, customerId: props.customerId}, formRef)
    }
  }, [formRef])


  const formItems: ItemElementPropsInterface[] | any = [
    {name: 'tranTypeId', label: 'Action', span: 12, rules: [{required: true}], inputElement: commonFormSelect(urlKey.PalletTranType, tranOptions, ['tranTypeId','tranTypeName'])},
    {name: 'quantity', label: 'Quantity', span: 12,rules: [{required: true, type: 'number', min: 0}], inputElement: <InputNumber /> },
    {name: 'notes', label: 'Notes', span: 24,inputElement: <Input />},
  ]

  
  const onFormBlur = (form: any) => {
    if (!formRef) {
      setFormRef(form)
    }
  }
  
  const onConfirm = async (form: any) => {
    // await saveOrder(0)
    const formValues: IFormValues = await formRef.validateFields()
    const operEmployeeId = getCookie('customerUserId') ? 0 : parseInt(getCookie('id'))
    if (operEmployeeId == null) {
      SweetAlertService.errorMessage('Please check your login status.')
      return;
    }
    let requestValues = {
      ...formValues,
      plateTypeId: props.plateTypeId,
      customerId: props.customerId,
      employeeId: operEmployeeId,
      tranTypeId: formValues.tranTypeId
    }
    // console.log(requestValues)
    let result
    result = await ApiRequest({
      url: 'PalletStockLog/Add',
      method: 'post',
      data: requestValues
    })
    if (result) {
      // console.log(result)
      await SweetAlertService.successMessage('Submit successfully')
      props.onDialogClose(true)
    }
  } 
     
  return (
    <div>
      <CommonForm items={formItems}  onFormBlur={onFormBlur}/>

      <div>
        <Button
          onClick={() => {
            props.onDialogClose(false)
          }}
          style={{ marginRight: '2rem' }}
        >Cancel</Button>
        <Button
          // disabled={!formRef}
          onClick={onConfirm}
          type="primary"
          style={{ marginRight: '2rem' }}
        >Confirm</Button>
      </div>
    </div>
  )
}

export default AddPalletLogDialog
