import React, { useState, useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete'; // import a different icon from material-ui library
import CommonTablePage from '../../../components/common/common-table/common-table-page';
import { urlKey } from '../../../services/api/api-urls';
import MonitorProductColumnModel from './monitor-product-column-model'
import CommonDialog from '../../../components/common/others/common-dialog';
import ProductSelectPage from './product-select-page';

import { getRandomKey } from '../../../services/lib/utils/helpers';
import { Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import './tab.css';
import SweetAlertService from '../../../services/lib/utils/sweet-alert-service';
import { ApiRequest } from '../../../services/api/api';
import { getUserId } from '../../../services/lib/utils/auth.utils';


const MonitorPOProductPage = (props: { }): any => {
  const [triggerResetData, setTriggerResetData] = useState<any>(false);
  const [open, setOpen] = useState<any>(false);


  useEffect(() => {
    document.title = "Purchase Product Inventory Monitoring";
  }, []);



  const customizeColumn = () => {
    const hiddenColArray = ['rate', 'last6monthsOrderCount', 'lastlastlast6monthsOrderCount', 'lastlast6monthsOrderCount'];
    let columnModel = MonitorProductColumnModel();
    columnModel = MonitorProductColumnModel().filter((e: any) => e.field !== "pendingWorkOrderQuantity");
    const updatedColumnModel = columnModel.map(column => {
      // Check if the field is in hiddenColArray
      if (hiddenColArray.includes(column.field)) {
        // If yes, add hidden: true property， hiddenByColumnsButton: true,      
        return { ...column, hidden: true ,hiddenByColumnsButton: true};
      }
      // If not, return the original element
      return column;
    });
    
    return updatedColumnModel;
  };

  const actionButtons2 = [
    {
      icon: 'ghost',
      tooltip: 'Change MSL',
      onClick: async (event:any, rowData:any) => {
        // Do save operation
        // const res = await SweetAlertService.inputConfirm ("You want to delete?")
        const res = await SweetAlertService.inputConfirm({
          type: 'number',
          title: 'Change MSL',
          textLabel: 'MSL: ',
          placeholder: 'Quantity',
          defaultValue: rowData.productInventoryInfo.productMsl
      });
      
        if (!res) return;

        let qty = parseInt(res);
        if (qty == rowData.productInventoryInfo.productMsl){
          await SweetAlertService.errorMessage ("New MSL  (Minimum Stock Level) must be different from the current one.");
          return
        }
        ApiRequest({
          url: `Product/ChangeMsl?productId=${rowData.productId}&employeeId=${getUserId()}&msl=${qty}`,
          method: 'PUT',
          // data: [{productId: rowData.productId, type:0}]
        }).then(_ => {
          // setTriggerResetData(!triggerResetData)
          setTriggerResetData(getRandomKey())
        })          
      }
    }
  ];
  const rateToPercentChange=(rate:number) => {
    return ((rate ) * 100).toFixed(2) + '%';
  }
  const isLowStock = (rowData:any) => {
    const productInStock = rowData.productInStock ?? 0;
    const poQty = rowData.poQty ?? 0;
    const pendingWorkOrderQuantity = rowData.pendingWorkOrderQuantity ?? 0;
    const pendingOrder = rowData.pendingOrder ?? 0;
    const productMsl = rowData.productMsl?? 0;    
    const totalStock = productInStock + poQty + pendingWorkOrderQuantity - pendingOrder;
    // console.log("totalStock:"+totalStock+","+productMsl)
    // debugger
    let res = ((totalStock < productMsl)? 1 : 0);
    // console.log("isLowStock"+res)
    return res
  };   
  const commonTablePageProps = {
    // urlInfoKey: urlKey.Product,
    getAllUrl: 'StockMonitor/GetPOMonitor',
    title: 'Purchase Product Inventory Monitoring',
    triggerResetData: triggerResetData,
    column: customizeColumn(),
    actionButtons: actionButtons2,
    isNotAddable: true,
    isNotDeletable: true,
    isNotEditable: true,
    columnsButton:true,
    mappingRenderData: (data: any) => {
      let newData = data.map((row: any) => ({
        ...row,
        pendingOrderQuantity: row.productInventoryInfo.pendingOrderQuantity,
        pendingWorkOrderQuantity: row.productInventoryInfo.pendingWorkOrderQuantity,
        productInStock: row.productInventoryInfo.productInStock,
        productMsl: row.productInventoryInfo.productMsl,
        suggestedQuantity: row.productInventoryInfo.suggestedQuantity,
        pendingOrder: row.productInventoryInfo.pendingOrderQuantity,
        rate: rateToPercentChange(row.changeRate),
        isLowStock:isLowStock(row.productInventoryInfo)
      }));
      return newData;
    },
    rowStyle: (rowData: any) => {
      return {
        backgroundColor:
        rowData.isLowStock
          ? '#FFEBEE' 
          : 'transparent',
      };
            
    },
    Options:{
      headerStyle: {
        backgroundColor: '#01579b',
        color: '#FFF'
      }
    },   
  };

  
  return (
    <div >
        <CommonTablePage
          {...commonTablePageProps}
        />
    </div>
  );
};

export default MonitorPOProductPage;
