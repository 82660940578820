import React from 'react'
import { Input } from '@material-ui/core'
import { Switch } from 'antd'

export default class MonitorProductGroupManagementColumnModel {
    static MonitorProductGroupManagementColumn = [
      {
        title: 'Type',
        align: 'left',
        field: 'type',
        validate: (rowData: any) => rowData.type === '' ? { isValid: false, helperText: 'Required' } : true
      },
      // {
      //   title: 'Color',
      //   align: 'left',
      //   field: 'color',
      //   validate: (rowData: any) => rowData.color === '' ? { isValid: false, helperText: 'Required' } : true
      // },
      {
        title: 'Name',
        align: 'left',
        field: 'name',
        validate: (rowData: any) => rowData.name === '' ? { isValid: false, helperText: 'Required' } : true
      },
      {
        title: 'Active',
        align: 'left',
        field: 'active',
        render: (rowData: any) => (
          <Switch checked={rowData.active} disabled />
        ),
        editComponent: (props: any) => {
          return (
            <Switch checked={props.value} onChange={(checked: boolean) => props.onChange(checked)} />
          )
        }
      }
    ]
}