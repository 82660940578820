import React, { useState } from 'react'
import { imgStringToArray } from './common-edit-image-component'
import { Upload ,UploadFile , Modal} from 'antd'
import { nbsStr } from '../../../services/lib/utils/helpers'
import SweetAlertService from '../../../services/lib/utils/sweet-alert-service'
import Swal from 'sweetalert2'

interface CommonRenderComponentPropsInterface {
  formItems: any
  restFormItems?: any
}

const CommonRenderComponent = (props: CommonRenderComponentPropsInterface):any => {

  const handlePreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as File);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    void imgWindow?.document.write(`<img src="${src}" />`);
  };  
  return (
    <div>
      {
        props.formItems.map((row: any, index: number) => {
          return (
            row.type === 'image' ?
              <div key={index}>
                <Upload
                  listType="picture-card"
                  fileList={[...imgStringToArray(row.value)]}
                  disabled
                  onPreview={handlePreview}
                >
                </Upload>
              </div>
              :
              (row?.value === 0 || row?.value) && (
                <div key={index}>
                  {row.label.replace(/\s/, '\u00a0') ? (
                    <span>
                      <b>
                        {nbsStr(row.label.replace(/\s/, '\u00a0'), row.isNotCutting)}:
                      </b>
                        &nbsp;
                    </span>
                  ) : null}
                  {nbsStr(row.value, row.isNotCutting)}
                </div>
              )
          )
        })
      }
      { props.restFormItems?.map((row: any) => row)}
    </div>
  )
}

export default CommonRenderComponent
