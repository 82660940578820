import React,{useEffect} from 'react'
import QuotationItemDeletedColumn from './quotation-item-deleted-column' 
import CommonTablePage, { CommonTablePagePropsInterface } from '../../../../components/common/common-table/common-table-page'
import moment from 'moment'


const QuotationItemDeletedPage: any = (props: any) => {
const getRenderData = (data: any) => {
    console.log(data)
    const renderData: any = []
    data.map((row: any) => {
        renderData.push({
        ...row,
        customer: row.customer.customerCode,
        product: row.product.productCode,
        deletedOperator:row.deletedOperator.firstName,
        deletedAt:moment(row.deletedDate).format("DD/MM/YYYY"),
        })
    })
    console.log(renderData)
    return renderData
    }
    
  const commonTablePageProps: CommonTablePagePropsInterface = {
    getAllUrl: 'QuotationItem/GetAllDeleted',
    title: props.title || 'Deleted Contract Price',
    column: QuotationItemDeletedColumn.Column,
    mappingRenderData: (data: any) => getRenderData(data),
    isNotEditable: true,
    isNotDeletable: true,
    isNotAddable: true
  }
  useEffect(() => {   
    document.title="Deleted Contract Price"
  }, [])
  return (
    <>
      <CommonTablePage
        {...commonTablePageProps}
      />
    </>
  )
}

export default QuotationItemDeletedPage
