import React, { useState,useEffect } from 'react'
import CommonTablePage from '../../../../components/common/common-table/common-table-page'
import { urlKey, urlType } from '../../../../services/api/api-urls'
import { ApiRequest } from '../../../../services/api/api'
import { toLocalDateTime } from '../../../../services/lib/utils/helpers'
import SweetAlertService from '../../../../services/lib/utils/sweet-alert-service'
import { BoxManagementColumnModel } from './box-management-column-model'
import { Button } from 'antd'
import  PdfStickService from '../../../../services/others/pdf-sticker-services'
import {JadCupImg, emptyImg} from '../../../../services/others/assets'
import { getUserId } from '../../../../services/lib/utils/auth.utils'

const BoxManagementPage : React.FC<any> = (props) => {
  const [triggerResetData, setTriggerResetData] = useState(false)
  const [selectedRows, setSelectedRows] = useState<any>()

  useEffect(() => {
    document.title = "Obsolete Box";
   }, [])
  const getRenderData = (data: any) => {
    console.log(data)
    const renderData: any = []
    data.map((row: any) => {
      renderData.push({
        ...row,
        productName: row.product?.productName,
        createdAt1: row.createdAt,
        createdAt: toLocalDateTime(row.createdAt),
        position: row.position,
        palletCode: row.palletCode,
      })
    })
    return renderData
  }
  const getUpdateData = async (dataDetail: any, type: any) => {
    if (type === urlType.Delete) {

      const { boxId } = dataDetail
      
      const resultInput = await SweetAlertService.deleteConfirm();
      if (resultInput.dismissed) return;
      const [name, reason] = Array.isArray(resultInput.value) ? resultInput.value : [];
      console.log(`Name: ${name}, Reason: ${reason}`);

      const result = 
        await ApiRequest({
          url: 'Box/ObsoleteBox?id=' + boxId+"&Notes="+name+"&empNotes="+reason,
          method: urlType.Delete,
          isShowSpinner: false
        })
      //   :
        // await ApiRequest({
        //   url: 'Box/DeleteBox?id=' + boxId,
        //   method: urlType.Delete,
        //   isShowSpinner: false
        // })
       
      if (result) {
        setTriggerResetData(!triggerResetData)
      }
      return 'resolve'
    }
    return dataDetail
  }

  const checkBoxInStock = async (rowData: any) => {
    const { productId } = rowData
    if (productId) {
      const result = await ApiRequest({
        url: 'Box/GetBoxLocationByProductId?productId=' + productId,
        method: urlType.Get,
        isShowSpinner: true
      })
      const inStock = result.data.data.find((e:any) => e.boxId === rowData.boxId)
      return !!inStock
    }
  }

  const qtyButton = (rowData: any) => {
    return (
      <Button
        onClick={async () => {
          const newQuantity = await SweetAlertService.inputConfirm({
            type: 'number',
            title: 'Edit Quantity',
            placeholder: 'quantity',
            defaultValue: rowData.quantity
          })
          console.log(newQuantity)
          if (newQuantity) {
            let inStock = await checkBoxInStock(rowData)
            inStock = true
            const result = inStock ?
              await ApiRequest({
                url: 'Box/UpdateStockBoxQuantity?boxId=' + rowData.boxId + '&quantity=' + newQuantity,
                method: 'PUT',
                isShowSpinner: false
              })
              :
              await ApiRequest({
                url: 'Box/UpdateBoxQuantity?boxId=' + rowData.boxId + '&quantity=' + newQuantity,
                method: 'PUT',
                isShowSpinner: false
              })
            if (result) {
              await SweetAlertService.successMessage('Submit successfully')
              setTriggerResetData(!triggerResetData)
            }
          }
        }}
      >Edit</Button>
    )
  }

  const onSelectionChange = (rows: any) => {
    console.log(rows)
    setSelectedRows(rows)
  }

  const actionButtons: any = [
    {
      icon: 'danger',
      tooltip: 'Batch delete',
      isFreeAction: false,
      onClick: async (event: any, rowData: any) => {
      const ids = selectedRows.map((row: any) => row.boxId)
      const resultInput = await SweetAlertService.deleteConfirm();
      if (resultInput.dismissed) return;
      const [name, reason] = Array.isArray(resultInput.value) ? resultInput.value : [];
      console.log(`Name: ${name}, Reason: ${reason}`);


      ApiRequest({
        url: "Box/ObsoleteBoxs?Notes="+reason+"&empNotes="+name,
        method: 'Put',
        data: ids
      }).then(_ => {
        setTriggerResetData(!triggerResetData)
      })
      // const result = 
      //   await ApiRequest({
      //     url: 'Box/ObsoleteBox?id=' + boxId+"&Notes="+name+"&empNotes="+reason,
      //     method: urlType.Delete,
      //     isShowSpinner: false
      //   })     
        // ApiRequest({
        //   url: 'Box/ObsoleteBoxs',
        //   method: 'put',
        //   data: ids
        // }).then(_ => {
        //   setTriggerResetData(!triggerResetData)
        // })
      }
    },
    {
      icon: 'danger',
      tooltip: 'Hold Product',
      isFreeAction: false,
      onClick: async (event: any, rowData: any) => {
        const ids = selectedRows.map((row: any) => row.boxId)
        const resultInput = await SweetAlertService.deleteConfirm();
        if (resultInput.dismissed) return;
        const [name, reason] = Array.isArray(resultInput.value) ? resultInput.value : [];
        console.log(`Name: ${name}, Reason: ${reason}`);
        ApiRequest({
          url: "Box/MarkDefects?Notes="+reason+"&empNotes="+name,
          method: 'Put',
          data: ids
        }).then(_ => {
          setTriggerResetData(!triggerResetData)
        })
      }
    },
    {
      icon: '',
      tooltip: 'Batch Print',
      isFreeAction: false,
      onClick: async (event: any, rowData: any) => {
        let ids:any = [];
        let base64:any;
        
        const productDetail =rowData.map( (ele:any) => {
          ids.push(ele.boxId)
        })
        
        const firstProductID = rowData[0].productId;
        if (!(rowData.every((row:any) => row.productId === firstProductID))) {
          SweetAlertService.errorMessage("All items within the selected box must be the same product.")
          return
        }
        if (rowData[0].product.logoType == 0) {
          base64 = JadCupImg
        } else if (rowData[0].product.logoType == 1) {
          const imgUrl = JSON.parse(rowData[0].product.logoUrl.split('---')[0]).url
          await ApiRequest({
            url: 'Common/UrltoBase64?imageFile=' + imgUrl,
            method: 'get'
          }).then(res => {
            const responseData = res.data
            base64 = 'data:image/png;base64,' + responseData;
          })
        } else {
          base64 = emptyImg;
        }
        
        ApiRequest({
          url: 'Box/GetBoxByIds',
          method: 'post',
          data: ids
        }).then(res => {
          const resData = res.data.data;

          PdfStickService(resData,base64);
        })
        
      }
    },    
  ]

  return (
    <div>
      <CommonTablePage
        urlInfoKey={urlKey.Box}
        title="Obsolete Box"
        isNotAddable={true}
        isNotEditable={true}
        isNotDeletable={true}
        actionButtons={actionButtons}
        // mappingUpdateData={(dataDetail: any, type: any) => getUpdateData(dataDetail, type)}
        mappingRenderData={(data: any) => getRenderData(data)}
        column={BoxManagementColumnModel({qtyButton})}
        triggerResetData={triggerResetData}
        isEnableSelect={true}
        onSelectionChange={onSelectionChange}
      />
    </div>
  )
}

export default BoxManagementPage
